const calculateRecScore = (majorRecsData, userRecsData, course, surveyAnswers) => {

    if (!majorRecsData){
        return 50;
    }

    const hollandCodesMapping = {
        R: 'realistic',
        I: 'investigative',
        S: 'social',
        C: 'conventional',
        E: 'enterprising',
        A: 'artistic'
    };

    const getTopThreeValues = (data) => {
        const values = Object.values(data);
        const sortedValues = values.sort((a, b) => b - a);
        return sortedValues.slice(0, 3);
    };

    const topThreeValues = getTopThreeValues(userRecsData);

    const constant = 100/(0.5*topThreeValues[0] + 0.3*topThreeValues[1] + 0.2*topThreeValues[2]);
    
    const recScoreMajorComponent = constant*(0.5*userRecsData[hollandCodesMapping[majorRecsData['Category1']]] + 0.3*userRecsData[hollandCodesMapping[majorRecsData['Category2']]] + 0.2*userRecsData[hollandCodesMapping[majorRecsData['Category3']]]);

    // const getRandomNumberBetween = (min, max) => Math.random() * (max - min) + min;

    // const recScoreFCQComponent = getRandomNumberBetween(-10, 10);

    const recFactorSTEMHSS = surveyAnswers.surveyNumberSTEMHSS || 0;
    const recFactorProjectTest = surveyAnswers.surveyNumberProjectTest || 0;
    const recFactorInPersonOnline = surveyAnswers.surveyNumberInPersonOnline || 0;
    const recFactorStructuredFlexible = surveyAnswers.surveyNumberStructuredFlexible || 0;
    const recFactorSmallLargeClass = surveyAnswers.surveyNumberSmallLargeClass || 0;
    const recFactorAppliedTheoretical = surveyAnswers.surveyNumberAppliedTheoretical || 0;
    const recFactorCollaborativeIndividual = surveyAnswers.surveyNumberCollaborativeIndividual || 0;

    const weightSTEMHSS = 0;
    const weightProjectTest = 0.03;
    const weightInPersonOnline = 0.03;
    const weightStructuredFlexible = 0.03;
    const weightSmallLargeClass = 0.03;
    const weightAppliedTheoretical = 0.03;
    const weightCollaborativeIndividual = 0.03;

    const totalWeight = weightSTEMHSS + weightProjectTest + weightInPersonOnline + weightStructuredFlexible + weightSmallLargeClass + weightAppliedTheoretical + weightCollaborativeIndividual;
    const portionSTEMHSS = weightSTEMHSS * course['RecSTEM-HSS'] * recFactorSTEMHSS || 0;
    const portionProjectTest = weightProjectTest * course['RecProject-Test'] * recFactorProjectTest || 0;
    const portionInPersonOnline = weightInPersonOnline * course['RecInPerson-Online'] * recFactorInPersonOnline || 0;
    const portionStructuredFlexible = weightStructuredFlexible * course['RecStructured-Flexible'] * recFactorStructuredFlexible || 0;
    const portionSmallLargeClass = weightSmallLargeClass * course['RecSmall-LargeClass'] * recFactorSmallLargeClass || 0;
    const portionAppliedTheoretical = weightAppliedTheoretical * course['RecApplied-Theoretical'] * recFactorAppliedTheoretical || 0;
    const portionCollaborativeIndividual = weightCollaborativeIndividual * course['RecCollaborative-Individual'] * recFactorCollaborativeIndividual || 0;

    const totalRecScore = portionSTEMHSS + portionProjectTest + portionInPersonOnline + portionStructuredFlexible + portionSmallLargeClass + portionAppliedTheoretical + portionCollaborativeIndividual;
    const recScoreFCQComponent = 100*((Math.sign((totalRecScore + 25 * totalWeight) / (50 * totalWeight) - 0.5) * Math.sqrt(Math.abs((totalRecScore + 25 * totalWeight) / (50 * totalWeight) - 0.5)) + Math.sqrt(0.5)) / Math.sqrt(2));

    //
    //
    //

    const recMajorWeight = 0.7

    const recFCQweight = 0.3

    const rawRecScore = recMajorWeight * recScoreMajorComponent + recFCQweight * recScoreFCQComponent

    // const scaledRecScore = ((Math.sign((rawRecScore + 25 * totalWeight) / (50 * totalWeight) - 0.5) * Math.sqrt(Math.abs((rawRecScore + 25 * totalWeight) / (50 * totalWeight) - 0.5)) + Math.sqrt(0.5)) / Math.sqrt(2));

    const roundedRecScore = parseFloat(rawRecScore.toFixed(2));

    return roundedRecScore;
};

module.exports = calculateRecScore;
