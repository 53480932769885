import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../NavBar';
import FinalizedCourses from './FinalizedCourses';
import FinalizedTasks from './FinalizedTasks';
import CreditBreakdown from './CreditBreakdown';
import { db, auth } from '../firebase';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { getDatabase, ref, get } from 'firebase/database';

const Credits = () => {
  const [finalizedCourses, setFinalizedCourses] = useState([]);
  const [finalizedTasks, setFinalizedTasks] = useState([]);
  const [middleschoolClasses, setmiddleschoolClasses] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [, setSelectedYear] = useState(null);
  const captureRef = useRef(null);
  const [showAddAllCoursesButton, setShowAddAllCoursesButton] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);
  
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
  
          if (userDoc.exists) {
            const userData = userDoc.data();
            const courseIds = userData.finalizedScheduleHS || [];
            const taskIds = userData.finalizedTasks || [];
            const middleschoolcourseIds = userData.middleschoolClasses || [];

            setmiddleschoolClasses(middleschoolcourseIds);

            const coursesFromFirestore = await fetchCoursesFromDatabase(courseIds);
            const tasksFromFirestore = await fetchTasksFromDatabase(taskIds);
  
            // Get the existing courses from local storage
            const existingCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
            const trueCourse = await fetchCoursesFromDatabase2(existingCourses);
  
            // Merge courses from local storage and Firestore
            const mergedCourses = mergeCourses(trueCourse, coursesFromFirestore);

            console.log('here', mergedCourses, trueCourse, coursesFromFirestore);
  
            // Update the state with merged courses
            setFinalizedCourses(mergedCourses);

            setFinalizedTasks(tasksFromFirestore);
  
            // Fetch courses from local storage
            const autofilledCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
  
            // Set the state to show the "Add All Courses" button
            setShowAddAllCoursesButton(autofilledCourses.length > 0);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  },);

  const fetchDataAndUpdateState = async () => {
    try {
      const user = auth.currentUser;
      setCurrentUser(user);
  
      if (user) {
        const userDocRef = db.collection('users').doc(user.uid);
        const userDoc = await userDocRef.get();
  
        if (userDoc.exists) {
          const userData = userDoc.data();
          const courseIds = userData.finalizedScheduleHS || [];
          const coursesFromFirestore = await fetchCoursesFromDatabase(courseIds);
  
          // Get the existing courses from local storage
          const existingCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
          const trueCourse = await fetchCoursesFromDatabase2(existingCourses)
  
          // Merge courses from local storage and Firestore
          const mergedCourses = mergeCourses(trueCourse, coursesFromFirestore);
  
          // Update the state with merged courses
          setFinalizedCourses(mergedCourses);
  
          // Fetch courses from local storage
          const autofilledCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
  
          // Set the state to show the "Add All Courses" button
          setShowAddAllCoursesButton(autofilledCourses.length > 0);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchCoursesFromDatabase = async (courseIds) => {
    try {
      // Array to store courses from both sheets
      const allCourses = [];
  
      // Function to fetch courses from a given project and collection ID
      const fetchCoursesFromSheet = async (projectAndCollectionId) => {
        const dbRef = ref(getDatabase(), projectAndCollectionId);
        const coursesSnapshot = await get(dbRef);
  
        coursesSnapshot.forEach((courseSnapshot) => {
          const courseData = courseSnapshot.val();
          allCourses.push({
            CourseDifficulty: courseData.CourseDifficulty,
            CourseSem: courseData.CourseSem,
            Credits: courseData.Credits,
            cal_name: courseData.cal_name,
            courseCat: courseData.courseCat,
            courseDif: courseData.courseDif,
            courseInfo_courseName: courseData.courseInfo_courseName,
            courseInfo_courseNumber: courseData.courseInfo_courseNumber,
            courseYear: courseData.courseYear,
            max_capacity: courseData.max_capacity,
            sch_name: courseData.sch_name,
            sectionInfo_sectionNumber: courseData.sectionInfo_sectionNumber,
            sectionInfo_teacherDisplay: courseData.sectionInfo_teacherDisplay,
            isAutoFill: courseData.isAutoFill,
            isPrereqMet: courseData.isPrereqMet,
            Instructor: courseData.Instructor,
            Major: courseData.Major,
          });
        });
      };
  
      // Fetch courses from the first sheet
      await fetchCoursesFromSheet('1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/CUB_SY2122_2223');
      // Fetch courses from the second sheet
      await fetchCoursesFromSheet('1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/RCHS_SY2122_2223');
  
      console.log('Fetched all courses from the Realtime Database:', allCourses);
  
      // Filter courses based on course IDs
      const fetchedCourses = allCourses.filter(course => courseIds.includes(course.courseInfo_courseNumber));
  
      console.log('Filtered courses based on course IDs:', fetchedCourses);
  
      return fetchedCourses;
    } catch (error) {
      console.error('Error fetching courses from the Realtime Database:', error);
      return [];
    }
  };  

  const fetchCoursesFromDatabase2 = async (courseIds) => {
    try {
      const projectAndCollectionId = '1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/RCHS_SY2122_2223';
      const dbRef = ref(getDatabase(), projectAndCollectionId);
      const coursesSnapshot = await get(dbRef);

      const allCourses = [];
      coursesSnapshot.forEach((courseSnapshot) => {
        const courseData = courseSnapshot.val();
        allCourses.push({
          CourseDifficulty: courseData.CourseDifficulty,
          CourseSem: courseData.CourseSem,
          Credits: courseData.Credits,
          cal_name: courseData.cal_name,
          courseCat: courseData.courseCat,
          courseDif: courseData.courseDif,
          courseInfo_courseName: courseData.courseInfo_courseName,
          courseInfo_courseNumber: courseData.courseInfo_courseNumber,
          courseYear: courseData.courseYear,
          max_capacity: courseData.max_capacity,
          sch_name: courseData.sch_name,
          sectionInfo_sectionNumber: courseData.sectionInfo_sectionNumber,
          sectionInfo_teacherDisplay: courseData.sectionInfo_teacherDisplay,
          isAutoFill: true,
          isPrereqMet: true,
        });
      });
    

      console.log('Fetched all courses from the Realtime Database:', allCourses);

      // Filter courses based on course IDs
      const fetchedCourses = allCourses.filter(course => courseIds.includes(course.courseInfo_courseNumber));

      console.log('Filtered courses based on course IDs:', fetchedCourses);

      return fetchedCourses;
    } catch (error) {
      console.error('Error fetching courses from the Realtime Database:', error);
      return [];
    }
  };

  
  const fetchTasksFromDatabase = async (taskIds) => {
    try {
      const projectAndCollectionId = '1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/Tasks';
      const dbRef = ref(getDatabase(), projectAndCollectionId);
      const tasksSnapshot = await get(dbRef);

      const allTasks = [];
      tasksSnapshot.forEach((taskSnapshot) => {
        const taskData = taskSnapshot.val();
        allTasks.push({
          Task_ID: taskData.Task_ID,
          Task_Name: taskData.Task_Name,
          Task_Year: taskData.taskYear,
          Task_Sem: taskData.taskSem,
          Task_Major: taskData.taskMajor,
          Task_Description: taskData.taskDescription,
        });
      });
    

      console.log('Fetched all tasks from the Realtime Database:', allTasks);

      // Filter tasks based on task IDs
      const fetchedTasks = allTasks.filter(task => taskIds.includes(task.Task_ID));

      console.log('Filtered tasks based on task IDs:', fetchedTasks);

      return fetchedTasks;
    } catch (error) {
      console.error('Error fetching tasks from the Realtime Database:', error);
      return [];
    }
  };



  const removeFromClassScheduleAndState = async (userId, courseInfo_courseNumber) => {
    try {
      const userRef = db.collection('users').doc(userId);

      await db.runTransaction(async (transaction) => {
        const userData = await transaction.get(userRef);

        if (userData.exists) {
          transaction.update(userRef, {
            finalizedScheduleHS: firebase.firestore.FieldValue.arrayRemove(courseInfo_courseNumber),
          });

          console.log('Transaction update successful.');
        } else {
          console.error('User data not found for the given userId:', userId);
          throw new Error('User data not found');
        }
      });

      // Update the state after successfully updating Firestore
      const updatedFinalizedCourses = finalizedCourses.filter(
        (course) => course.courseInfo_courseNumber !== courseInfo_courseNumber
      );
      setFinalizedCourses(updatedFinalizedCourses);

    } catch (error) {
      console.error('Error removing course from the database:', error);
      throw error;
    }
  };

  const handleRemove = async (classToRemove) => {
    const removedCourseNumber = classToRemove.courseInfo_courseNumber;
  
    try {
      if (currentUser && currentUser.uid) {
        await removeFromClassScheduleAndState(currentUser.uid, removedCourseNumber);
  
        // Remove the course from local storage (finalizedCourses)
        const existingCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
        const filteredCourses = existingCourses.filter(course =>
          course.courseInfo_courseNumber !== removedCourseNumber
        );
        localStorage.setItem('finalizedCourses', JSON.stringify(filteredCourses));
  
        // Remove the course from local storage (autofilledCourses)
        const autofilledCourses = JSON.parse(localStorage.getItem('autofilledCourses')) || [];
        const filteredAutofilledCourses = autofilledCourses.filter(course =>
          course.courseInfo_courseNumber !== removedCourseNumber
        );
        localStorage.setItem('autofilledCourses', JSON.stringify(filteredAutofilledCourses));
  
        // Update the state without the removed course
        const updatedFinalizedCourses = finalizedCourses.filter(
          (course) => course.courseInfo_courseNumber !== removedCourseNumber
        );
        setFinalizedCourses(updatedFinalizedCourses);
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error handling removal:', error);
    }
  };  

  const handleUpdateCourse = async (updatedCourse) => {
    try {
      // Update Firestore with the updated course data
      const userRef = db.collection('users').doc(currentUser.uid);
      await userRef.update({
        finalizedScheduleHS: firebase.firestore.FieldValue.arrayUnion(updatedCourse.courseInfo_courseNumber),
      });
  
      // Update the state with the new course
      const updatedCourses = finalizedCourses.map(course =>
        course.courseInfo_courseNumber === updatedCourse.courseInfo_courseNumber ? updatedCourse : course
      );
      setFinalizedCourses(updatedCourses);
    } catch (error) {
      console.error('Error updating course in Firestore:', error);
    }
  };

  const removeAllCourses = async () => {
    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);

        await db.runTransaction(async (transaction) => {
          const userData = await transaction.get(userRef);

          if (userData.exists) {
            const finalizedSchedule = userData.data().finalizedScheduleHS || [];

            transaction.update(userRef, {
              finalizedScheduleHS: firebase.firestore.FieldValue.arrayRemove(...finalizedSchedule),
            });

            console.log('All courses removed successfully.');
          } else {
            console.error('User data not found for the given userId:', currentUser.uid);
            throw new Error('User data not found');
          }
        });

        setFinalizedCourses([]);
        localStorage.clear();
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error removing all courses:', error);
    }
  };

  const organizeCoursesByYear = () => {
    const coursesByYear = {};

    finalizedCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';

      if (coursesByYear[year]) {
        coursesByYear[year].push(course);
      } else {
        coursesByYear[year] = [course];
      }
    });

    return coursesByYear;
  };

  const coursesByYear = organizeCoursesByYear();


  const organizeTasksByYear = () => {
    console.log('hihihi', finalizedTasks)
    const tasksByYear = {};

    finalizedTasks.forEach((task) => {
      const year = task.Task_Year || 'Uncategorized';

      if (tasksByYear[year]) {
        tasksByYear[year].push(task);
      } else {
        tasksByYear[year] = [task];
      }
    });
    console.log('hihihihi',tasksByYear[3]);
    return tasksByYear;
  };

  const tasksByYear = organizeTasksByYear();

  const organizePrereqCourses = () => {
    const prereqCourses = {};
    for (let i = 1; i <= 4; i++) {
      // Check if the key exists in prereqCourses
      if (!prereqCourses[i]) {
        prereqCourses[i] = []; // Initialize the array if it doesn't exist
      }
      prereqCourses[i].push(...middleschoolClasses); // Merge middleschoolClasses into the array

      finalizedCourses.forEach((course) => {
        const year = course.courseYear || 'Uncategorized';
  
        if (year < i) {
          prereqCourses[i].push(course.courseInfo_courseNumber);
        } 
      });
    }

    return prereqCourses;
  };

  const prereqClasses = organizePrereqCourses();

  const handleCaptureSnapshot = () => {
    if (captureRef.current) {
      html2canvas(captureRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('snapshot.pdf');
      });
    }
  };

  const handleAutofillClasses = async () => {
    try {
      if (currentUser && currentUser.uid) {
        const apiUrl = `http://localhost:4000/api/autofill-courses/${currentUser.uid}`;
        const response = await fetch(apiUrl);
  
        if (response.ok) {
          const idArray = await response.json();
          console.log('IDs:', idArray);
  
          // Fetch courses from local storage
          const existingCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
          
          // Update each fetched course to set isAutoFill to true
          // const updatedCourses = idArray.data
          const updatedCourses = ["60752S1", "60650S2", 69227, 69034, "80365S1", "80400S1", "80370S1", "80655S1", "50310S2", "50340S1", "50360S2", "50400S2", 85835, 85550, 85800, 85302, "95600S1", "95610S1", "95620S1", "95631S2", 15470, 15425, 70780, "70460S1", "70461S2", 69317, "30506S1", "ISCM2"];
  
          // Merge existing courses with autofilled courses
          const mergedCourses = mergeCourses(existingCourses, updatedCourses);
  
          // Update local storage
          await localStorage.setItem('finalizedCourses', JSON.stringify(mergedCourses));
  
          // Update the state with merged courses
          setFinalizedCourses(mergedCourses);
  
          console.log('Autofilled courses added to local storage:', fetchCoursesFromDatabase2(mergedCourses));
          fetchDataAndUpdateState();
        } else {
          console.error(`Error: ${response.statusText}`);
        }
      } else {
        console.error('Error: currentUser or currentUser.uid is undefined.');
      }
    } catch (error) {
      console.error('Error fetching and updating autofill courses:', error);
    }
  };

  const mergeCourses = (localCourses, firestoreCourses) => {
    // Ensure firestoreCourses is an array
    if (!Array.isArray(firestoreCourses)) {
      return localCourses;
    }
    return [...localCourses, ...firestoreCourses];
  };

  const handleAddAllCourses = async () => {
    try {
      if (currentUser && currentUser.uid) {
        // Fetch courses from local storage
        const autofilledCourses = JSON.parse(localStorage.getItem('finalizedCourses')) || [];
        console.log("Autofilled Courses: ", autofilledCourses);
  
        // Update Firestore with all the autofilled courses
        const userRef = db.collection('users').doc(currentUser.uid);
        await userRef.update({
          finalizedScheduleHS: firebase.firestore.FieldValue.arrayUnion(...autofilledCourses),
        });
        
        localStorage.clear();
        setFinalizedCourses([]);
        // Hide the "Add All Courses" button
        setShowAddAllCoursesButton(false);
  
        console.log('All autofilled courses added:', autofilledCourses);
        fetchDataAndUpdateState();
      } else {
        console.error('Error: currentUser or currentUser.uid is undefined.');
      }
    } catch (error) {
      console.error('Error adding all autofilled courses:', error);
    }
  };
  

  const getYearClassName = (yearNumber) => {
    const yearNames = ['Freshman Year', 'Sophomore Year', 'Junior Year', 'Senior Year'];
    return yearNames[yearNumber - 1] || `Year ${yearNumber}`;
  };

  return (
    <div style={{ marginTop: '2cm' }}>
      <NavBar />
      <div ref={captureRef}>
        <div style={{ display: 'flex' }}>
          {[1, 2, 3, 4].map((yearNumber, index, array) => (
            <div
              key={yearNumber}
              style={{
                flex: 1,
                marginRight: index === array.length - 1 ? '10px' : '0px',
                marginLeft: index === 0 ? '10px' : '0px',
                borderRadius: '1rem',
                boxShadow: '0px 0px 8px #999',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                backgroundColor: 'whitesmoke',
                height: 'fit-content',
                overflow: 'auto',
                padding: '1rem',
                width: '265px',
              }}
              onClick={() => setSelectedYear(yearNumber)}
            >
              <h3 style={{ fontSize: '1.5rem', margin: '0.5rem 5%', textAlign: 'center', padding: '0', color: 'inherit', textDecoration: 'none' }}>
                {getYearClassName(yearNumber)}
              </h3>
              <FinalizedCourses finalizedCourses={coursesByYear[yearNumber]} onRemove={handleRemove} onUpdateCourse={handleUpdateCourse} prereqClasses={prereqClasses} yearNumber={yearNumber} />
              {coursesByYear[yearNumber] && coursesByYear[yearNumber].length > 0 && (
                <div style={{ marginTop: 'auto', textAlign: 'center' }}>
                  <Link to={`/Credits/${yearNumber}`} state={{ year: yearNumber, courses: coursesByYear[yearNumber] }} style={{ textDecoration: 'none' }}>
                    <button className="btn btn-primary">More Details</button>
                  </Link>
                </div>
              )}
            </div>
          ))}
          <div
            style={{
              flex: 1,
              marginRight: '10px',
              borderRadius: '1rem',
              boxShadow: '0px 0px 8px #999',
              display: 'flex',
              flexDirection: 'column',
              margin: '1rem',
              backgroundColor: 'whitesmoke',
              height: 'fit-content',
              overflow: 'auto',
              padding: '1rem',
              width: '250px',
            }}
          >
            <CreditBreakdown finalizedCourses={finalizedCourses} />
            <button className="btn btn-primary m-2" onClick={handleCaptureSnapshot}>Download Snapshot</button>
            <button className="btn btn-primary" onClick={handleAutofillClasses} style={{ marginTop: '10px' }}>
              Autofill Classes
            </button>
            <button className="btn btn-primary" onClick={removeAllCourses} style={{ marginTop: '10px' }}>
              Remove All Courses
            </button>
            {showAddAllCoursesButton && (
            <button className="btn btn-primary" onClick={handleAddAllCourses} style={{ marginTop: '10px' }}>
              Add All Courses
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits;
