import React, { useState } from 'react';
import PathwayFormModal from './PathwayFormModal';
import PathwayList from './PathwayList';
import ClassSearch from '../../ClassSearch/ClassSearch';
import CustomSidebar from '../Sidebar/Sidebar';

const Pathways = () => {
  const [activeTab, setActiveTab] = useState('list');

  const handleSavePathway = () => {
    setActiveTab('list'); // Switch back to the list tab after saving
  };

  return (
    <div className="flex">
      <CustomSidebar adjustment={"Pathways"}/>
      <div className="flex-1 ml-0">
      <div
        className="fixed ml-14 top-6 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 z-5 w-full"
      >
        <div className="flex justify-center">
          <ul
            className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
            id="default-tab"
            data-tabs-toggle="#default-tab-content"
            role="tablist"
            style={{ listStyleType: 'none' }}
          >
            <li role="presentation">
              <button
                className={`inline-block py-3 px-6 whitespace-nowrap ${
                  activeTab === 'list'
                    ? 'text-blue-600'
                    : 'hover:text-gray-600 dark:hover:text-gray-300'
                }`}
                id="list-tab"
                type="button"
                role="tab"
                aria-controls="list"
                aria-selected={activeTab === 'list'}
                onClick={() => setActiveTab('list')}
              >
                Pathway List
              </button>
            </li>
            <li role="presentation">
              <button
                className={`inline-block py-3 px-6 whitespace-nowrap ${
                  activeTab === 'form'
                    ? 'text-blue-600'
                    : 'hover:text-gray-600 dark:hover:text-gray-300'
                }`}
                id="form-tab"
                type="button"
                role="tab"
                aria-controls="form"
                aria-selected={activeTab === 'form'}
                onClick={() => setActiveTab('form')}
              >
                Create New Pathway
              </button>
            </li>
            <li role="presentation">
              <button
                className={`inline-block py-3 px-6 whitespace-nowrap ${
                  activeTab === 'search'
                    ? 'text-blue-600'
                    : 'hover:text-gray-600 dark:hover:text-gray-300'
                }`}
                id="form-tab"
                type="button"
                role="tab"
                aria-controls="form"
                aria-selected={activeTab === 'search'}
                onClick={() => setActiveTab('search')}
              >
                Class Search
              </button>
            </li>
          </ul>
        </div>
        <div className="border-b border-gray-200 dark:border-gray-700"></div>
      </div>
        <div id="default-tab-content" className="mt-24 ml-20">
          {activeTab === 'list' ? (
            <PathwayList />
          ) : activeTab === 'form' ? (
            <PathwayFormModal onSave={handleSavePathway} handleClose={() => setActiveTab('list')} />
          ) : (
            <ClassSearch adjustment='admin' />
          )}
        </div>

      </div>
    </div>
  );
};

export default Pathways;
