// StudentPersistence.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import CustomSidebar from '../Sidebar/Sidebar';
import EngagementOpportunities from './EngagementOpportunities';
import PersistenceCard from './PersistenceCard';
import EnhancedSearchComponent from './EnhancedSearchComponent'; // Adjust path accordingly

// Helper functions
const formatPrediction = (prediction) => {
  switch (prediction) {
    case 'veryLow':
      return 'Very Low';
    case 'low':
      return 'Low';
    case 'moderate':
      return 'Moderate';
    case 'high':
      return 'High';
    case 'veryHigh':
      return 'Very High';
    default:
      return prediction?.charAt(0).toUpperCase() + prediction?.slice(1);
  }
};

const StudentPersistence = () => {
  const [predictors, setPredictors] = useState([]);
  const [activeTab, setActiveTab] = useState('predictors');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});

  // Fetch predictors on mount
  useEffect(() => {
    const fetchPredictorsFromFirestore = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const predictorsList = await Promise.all(
          usersSnapshot.docs.map(async (userDocSnapshot) => {
            const userId = userDocSnapshot.id;
            const userData = userDocSnapshot.data();
            let predictorData = { id: userId, ...userData };

            try {
              const persistenceCalcRef = doc(db, 'users', userId, 'persistence', 'persistenceCalculations');
              const persistenceCalcSnap = await getDoc(persistenceCalcRef);
              if (persistenceCalcSnap.exists()) {
                const persistenceData = persistenceCalcSnap.data();
                predictorData = { ...predictorData, ...persistenceData };
              }
            } catch (persistenceError) {
              console.error('Error fetching persistence data for user ID', userId, persistenceError);
            }

            const surveyAnswers = userData?.surveyAnswers;
            if (surveyAnswers?.Majors && Array.isArray(surveyAnswers.Majors)) {
              predictorData.major = surveyAnswers.Majors.join(' ');
            }

            return predictorData;
          })
        );

        setPredictors(predictorsList);
        localStorage.setItem('studentPersistenceData', JSON.stringify(predictorsList));
      } catch (error) {
        console.error('Error fetching predictors:', error);
      }
    };

    const localData = localStorage.getItem('studentPersistenceData');
    if (localData) {
      setPredictors(JSON.parse(localData));
    } else {
      fetchPredictorsFromFirestore();
    }
  }, []);

  const handleSearch = (term) => {
    setSearchQuery(term);
  };

  const handleFilter = (filters) => {
    setFilterCriteria(filters);
  };

  const filteredPredictors = React.useMemo(() => {
    let result = [...predictors];

    // 1. Apply persistence level filtering if criteria exist
    if (filterCriteria.persistence && filterCriteria.persistence.length > 0) {
      result = result.filter(p => filterCriteria.persistence.includes(formatPrediction(p.prediction)));
    }

    // 2. Apply search query filtering
    result = result.filter((p) => {
      const fullName = `${p.firstName} ${p.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });

    // 3. Apply graduation year filter
    if (filterCriteria.gradYear && filterCriteria.gradYear.length > 0) {
      result = result.filter((p) =>
        filterCriteria.gradYear.includes(p.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // 4. Apply program filter
    if (filterCriteria.program && filterCriteria.program.length > 0) {
      result = result.filter((p) => {
        if (Array.isArray(p.major)) {
          return p.major.some((prog) => filterCriteria.program.includes(prog));
        } else {
          return filterCriteria.program.includes(p.major);
        }
      });
    }

    // 5. Apply advisor filter
    if (filterCriteria.advisors && filterCriteria.advisors.length > 0) {
      result = result.filter((p) => filterCriteria.advisors.includes(p.advisor));
    }

    // 6. Apply performance (GPA) filter
    if (filterCriteria.performance && filterCriteria.performance.length > 0) {
      result = result.filter((p) => {
        const gpa = parseFloat(p.surveyAnswers?.GPA);
        return filterCriteria.performance.some((range) => {
          switch (range) {
            case '> 4.0':
              return gpa > 4.0;
            case '3.5 - 4.0':
              return gpa >= 3.5 && gpa <= 4.0;
            case '3.0 - 3.5':
              return gpa >= 3.0 && gpa < 3.5;
            case '2.5 - 3.0':
              return gpa >= 2.5 && gpa < 3.0;
            case '2.0 - 2.5':
              return gpa >= 2.0 && gpa < 2.5;
            case '< 2.0':
              return gpa < 2.0;
            default:
              return false;
          }
        });
      });
    }

    // 7. Apply custom filters
    if (filterCriteria.customFilters && filterCriteria.customFilters.length > 0) {
      filterCriteria.customFilters.forEach(({ field, operator, value }) => {
        result = result.filter((p) => {
          const studentVal = p[field];
          if (studentVal == null) return false;
          if (operator === 'equals') {
            if (Array.isArray(studentVal)) {
              return studentVal.some(
                (val) => String(val).trim().toLowerCase() === value.trim().toLowerCase()
              );
            } else {
              return String(studentVal).trim().toLowerCase() === value.trim().toLowerCase();
            }
          } else if (operator === 'greater than') {
            return parseFloat(studentVal) > parseFloat(value);
          } else if (operator === 'less than') {
            return parseFloat(studentVal) < parseFloat(value);
          }
          return true;
        });
      });
    }

    return result;
  }, [predictors, filterCriteria, searchQuery]);

  return (
    <div className="flex min-h-screen w-[98vw]">
      <CustomSidebar adjustment="PERSISTENCE PREDICTIONS" />

      <div className="flex-1 ml-0">
        <div className="ml-14">
          <div className="bg-white dark:bg-gray-800 w-full">
            <div className="flex justify-center">
              <ul
                className="flex space-x-4 py-2 mt-4 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
                role="tablist"
              >
                <li role="presentation">
                  <button
                    className={`inline-block py-3 px-6 whitespace-nowrap ${
                      activeTab === 'predictors'
                        ? 'text-blue-600'
                        : 'hover:text-gray-600 dark:hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab('predictors')}
                  >
                    Persistence Predictors
                  </button>
                </li>
                <li role="presentation">
                  <button
                    className={`inline-block py-3 px-6 whitespace-nowrap ${
                      activeTab === 'engagement'
                        ? 'text-blue-600'
                        : 'hover:text-gray-600 dark:hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab('engagement')}
                  >
                    Engagement Opportunities
                  </button>
                </li>
              </ul>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-700 w-full"></div>
          </div>

          <div className="mt-4 ml-14 p-2">
            {activeTab === 'predictors' ? (
              <>
                <h2 className="text-2xl font-light mb-4">Student Persistence Predictions</h2>

                {/* Integrated EnhancedSearchComponent for filters */}
                <EnhancedSearchComponent
                  onSearch={handleSearch}
                  onFilter={handleFilter}
                  students={predictors}
                  onSelectAll={() => {}}
                  filteredStudentsCount={filteredPredictors.length}
                  everythingSelected={false}
                  fieldTypes={{}}    // Provide actual fieldTypes if available
                  advisors={[]}      // Provide actual advisors if available
                  dashboardFilterType={null}
                  dashboardFilterValue={null}
                />

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {filteredPredictors
                    .filter((p) => p.role === 'student')
                    .sort((a, b) => a?.overallPersistenceScore - b?.overallPersistenceScore)
                    .map((predictor) => (
                      <PersistenceCard key={predictor.id} predictor={predictor} />
                    ))}
                </div>
              </>
            ) : activeTab === 'engagement' ? (
              <EngagementOpportunities />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPersistence;
