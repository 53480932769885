import React, { useState, useEffect, useCallback } from 'react';
import MultiSelect from '../../ClassSearch/MultiSelect';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const getTextColorClass = (prediction) => {
  switch (prediction) {
    case 'Very Low':
      return 'text-red-800 font-semibold';
    case 'Low':
      return 'text-orange-500 font-semibold';
    case 'Moderate':
      return 'text-yellow-500 font-semibold';
    case 'High':
      return 'text-green-600 font-semibold';
    case 'Very High':
      return 'text-blue-600 font-semibold';
    default:
      return 'font-semibold';
  }
};

const EnhancedSearchComponent = ({
  onSearch,
  students,
  onFilter,
  onSelectAll,
  filteredStudentsCount,
  everythingSelected,
  fieldTypes,
  advisors,
  dashboardFilterType,
  dashboardFilterValue,
}) => {
  const { fetchedMajors } = useAuth();
  const navigate = useNavigate();

  // Persistence filters state
  const [persistenceFilters, setPersistenceFilters] = useState({
    'Very Low': false,
    'Low': false,
    'Moderate': false,
    'High': false,
    'Very High': false,
  });

  const togglePersistenceFilter = (level) => {
    setPersistenceFilters((prev) => ({
      ...prev,
      [level]: !prev[level],
    }));
  };

  // Other states from the original component
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [operatorError, setOperatorError] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  const [selectedFieldOption, setSelectedFieldOption] = useState('');
  const [tempMultiValues, setTempMultiValues] = useState([]);
  const [tempOperator, setTempOperator] = useState('');
  const [tempValue, setTempValue] = useState('');

  const [availableFilters, setAvailableFilters] = useState({
    gradYear: [],
    program: [],
    performance: [],
    advisors: [],
  });

  const fetchFilters = useCallback(async () => {
    try {
      const response = await fetch('/filters.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      data['program'] = Object.keys(fetchedMajors);
      data['advisors'] = advisors;

      setAvailableFilters(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, [fetchedMajors, advisors]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  const transformFieldName = (field) => {
    if (['classesTaken', 'classesRegistered'].includes(field)) return null;
    const renameMap = { planned: 'Classes Planned', GPA: 'GPA' };
    if (renameMap[field]) return renameMap[field];
    const spaced = field.replace(/([A-Z])/g, ' $1');
    return spaced
      .split(' ')
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');
  };

  const fieldNameMap = Object.keys(fieldTypes).reduce((map, rawField) => {
    const niceName = transformFieldName(rawField);
    if (niceName) {
      map[niceName] = rawField;
    }
    return map;
  }, {});

  const customFieldLabels = Object.keys(fieldNameMap);
  const fieldOptions = [
    { label: 'Graduation Year', value: 'graduationYearSpecial' },
    { label: 'Program', value: 'programSpecial' },
    { label: 'Performance', value: 'performanceSpecial' },
    { label: 'Advisor', value: 'advisorSpecial' },
    ...customFieldLabels.map((lbl) => ({
      label: lbl,
      value: fieldNameMap[lbl],
    })),
  ];

  const buildParentFiltersObject = useCallback(() => {
    const newObj = {
      gradYear: [],
      program: [],
      performance: [],
      advisors: [],
      customFilters: [],
      persistence: Object.entries(persistenceFilters)
        .filter(([_, val]) => val)
        .map(([level]) => level),
    };
    activeFilters.forEach((f) => {
      switch (f.type) {
        case 'graduationYear':
          newObj.gradYear = [...newObj.gradYear, ...f.values];
          break;
        case 'program':
          newObj.program = [...newObj.program, ...f.values];
          break;
        case 'performance':
          newObj.performance = [...newObj.performance, ...f.values];
          break;
        case 'advisor':
          newObj.advisors = [...newObj.advisors, ...f.values];
          break;
        case 'custom':
          newObj.customFilters.push({
            field: f.field,
            operator: f.operator,
            value: f.value,
          });
          break;
        default:
          break;
      }
    });
    return newObj;
  }, [activeFilters, persistenceFilters]);

  useEffect(() => {
    const finalFilters = buildParentFiltersObject();
    onFilter(finalFilters);
  }, [activeFilters, onFilter, buildParentFiltersObject]);

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const handleSelectAllClick = () => {
    onSelectAll();
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setActiveFilters([]);
    setSelectedFieldOption('');
    setTempMultiValues([]);
    setTempOperator('');
    setTempValue('');
    setOperatorError('');
    setPersistenceFilters({
      'Very Low': false,
      'Low': false,
      'Moderate': false,
      'High': false,
      'Very High': false,
    });

    navigate('/students', { replace: true, state: {} });
  };

  useEffect(() => {
    // Add dashboardFilterType logic if needed
  }, [dashboardFilterType, dashboardFilterValue]);

  const handleFieldOptionChange = (val) => {
    const chosen = val[0];
    if (!chosen) return;

    setTempMultiValues([]);
    setTempOperator('');
    setTempValue('');
    setOperatorError('');
    setSelectedFieldOption(chosen);
  };

  const handleOperatorSelect = (op) => {
    setTempOperator(op);
    if (
      (op === 'greater than' || op === 'less than') &&
      fieldTypes[selectedFieldOption] !== 'number'
    ) {
      setOperatorError('Cannot use this operator with the selected field');
    } else {
      setOperatorError('');
    }
  };

  const getPossibleValuesForField = (field) => {
    const uniqueValues = new Set();
    students.forEach((s) => {
      const val = s[field];
      if (val === undefined || val === null) return;
      if (Array.isArray(val)) {
        val.forEach((v) => uniqueValues.add(v));
      } else {
        uniqueValues.add(val);
      }
    });
    return [...uniqueValues];
  };

  const handleAddFilter = () => {
    if (!selectedFieldOption) return;
    const specials = [
      'graduationYearSpecial',
      'programSpecial',
      'performanceSpecial',
      'advisorSpecial',
    ];
    if (specials.includes(selectedFieldOption)) {
      if (tempMultiValues.length === 0) return;
      let filterType = '';
      switch (selectedFieldOption) {
        case 'graduationYearSpecial':
          filterType = 'graduationYear';
          break;
        case 'programSpecial':
          filterType = 'program';
          break;
        case 'performanceSpecial':
          filterType = 'performance';
          break;
        case 'advisorSpecial':
          filterType = 'advisor';
          break;
        default:
          break;
      }
      const newFilter = {
        type: filterType,
        values: [...tempMultiValues],
      };
      setActiveFilters((prev) => [...prev, newFilter]);
    } else {
      if (!tempOperator || !tempValue) return;
      if (operatorError) return;

      const newFilter = {
        type: 'custom',
        field: selectedFieldOption,
        operator: tempOperator,
        value: tempValue,
      };
      setActiveFilters((prev) => [...prev, newFilter]);
    }

    setSelectedFieldOption('');
    setTempMultiValues([]);
    setTempOperator('');
    setTempValue('');
    setOperatorError('');
  };

  const handleRemoveFilter = (filterToRemove) => {
    setActiveFilters((prev) => prev.filter(f => f !== filterToRemove));
  };

  const tags = activeFilters.map((f, idx) => {
    let label;
    if (f.type === 'graduationYear') {
      label = `Grad Year: ${f.values.join(', ')}`;
    } else if (f.type === 'program') {
      label = `Program: ${f.values.join(', ')}`;
    } else if (f.type === 'performance') {
      label = `Performance: ${f.values.join(', ')}`;
    } else if (f.type === 'advisor') {
      label = `Advisor: ${f.values.join(', ')}`;
    } else if (f.type === 'custom') {
      let displayField = f.field;
      const niceName = Object.keys(fieldNameMap).find(
        (k) => fieldNameMap[k] === f.field
      );
      if (niceName) displayField = niceName;
      label = `${displayField} ${f.operator} ${f.value}`;
    } else {
      label = 'Unknown Filter';
    }
    return { filter: f, label, index: idx };
  });

  return (
    <div>
      {/* Persistence Filter Checkboxes */}
      <div className="border border-gray-200 w-full bg-white mb-4 shadow-md rounded-md p-4 flex flex-wrap items-center">
        <div className="flex items-center space-x-6">
          <span className="font-medium text-gray-700">
            Filter by persistence prediction:
          </span>
          {['Very Low', 'Low', 'Moderate', 'High', 'Very High'].map((level) => (
            <label key={level} className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded
                           focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                           focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={persistenceFilters[level]}
                onChange={() => togglePersistenceFilter(level)}
              />
              <span className={getTextColorClass(level)}>{level}</span>
            </label>
          ))}
        </div>
      </div>

      {/* TOP ROW: Filter button, Search bar, and Filter tags */}
      <div className="flex mb-4 flex-wrap items-center gap-2">
        <button
          type="button"
          onClick={handleToggleFilters}
          className="h-10 bg-blue-500 hover:bg-blue-700 text-white text-xs 
                     font-medium px-3 rounded"
        >
          {showFilters ? 'Hide Filters' : 'Filter'}
        </button>

        <input
          type="text"
          placeholder="Search for student name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="h-10 w-52 text-sm placeholder-gray-400 rounded border border-gray-300
                     focus:outline-none focus:border-blue-500 focus:ring-blue-500 px-2"
        />

        <div className="flex flex-wrap items-center gap-2">
          {tags.map((tag) => (
            <div
              key={tag.index}
              className="flex items-center bg-gray-200 text-gray-800 text-xs 
                         rounded-md px-2 h-8"
            >
              <span>{tag.label}</span>
              <button
                type="button"
                className="ml-2 text-gray-600 hover:text-gray-900 font-bold"
                onClick={() => handleRemoveFilter(tag.filter)}
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Collapsible Filter Panel */}
      {showFilters && (
        <div className="absolute top-0 left-0 right-0 ml-28 mt-40 p-3 bg-gray-50 shadow-md border rounded-lg z-10 w-1/2">
          <div className="flex justify-end">
            <button
              onClick={() => setShowFilters(false)}
              className="text-gray-500 hover:text-gray-700 text-xl font-bold"
            >
              ×
            </button>
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Filter by Field:
            </label>
            <MultiSelect
              suggestions={fieldOptions.map((o) => o.label)}
              value={
                selectedFieldOption
                  ? [
                      fieldOptions.find((o) => o.value === selectedFieldOption)
                        ?.label ?? selectedFieldOption,
                    ]
                  : []
              }
              onChange={(e) => {
                const label = e.target.value[0];
                const item = fieldOptions.find((o) => o.label === label);
                if (item) handleFieldOptionChange([item.value]);
              }}
              placeholder="(Select a field)"
              required={false}
              tagColor="gray"
              singleSelect={true}
            />
          </div>

          {selectedFieldOption === 'graduationYearSpecial' && (
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">
                Graduation Years:
              </label>
              <MultiSelect
                suggestions={availableFilters.gradYear}
                value={tempMultiValues}
                onChange={(e) => setTempMultiValues(e.target.value)}
                placeholder="Select graduation years"
                tagColor="red"
              />
            </div>
          )}
          {selectedFieldOption === 'programSpecial' && (
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">Program:</label>
              <MultiSelect
                suggestions={availableFilters.program}
                value={tempMultiValues}
                onChange={(e) => setTempMultiValues(e.target.value)}
                placeholder="Select programs"
                tagColor="green"
              />
            </div>
          )}
          {selectedFieldOption === 'performanceSpecial' && (
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">
                Performance:
              </label>
              <MultiSelect
                suggestions={availableFilters.performance}
                value={tempMultiValues}
                onChange={(e) => setTempMultiValues(e.target.value)}
                placeholder="Select performance levels"
                tagColor="orange"
              />
            </div>
          )}
          {selectedFieldOption === 'advisorSpecial' && (
            <div className="mb-2">
              <label className="block text-sm font-medium mb-1">Advisor:</label>
              <MultiSelect
                suggestions={availableFilters.advisors}
                value={tempMultiValues}
                onChange={(e) => setTempMultiValues(e.target.value)}
                placeholder="Select advisors"
                tagColor="purple"
              />
            </div>
          )}

          {selectedFieldOption &&
            ![
              'graduationYearSpecial',
              'programSpecial',
              'performanceSpecial',
              'advisorSpecial',
            ].includes(selectedFieldOption) && (
              <>
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">
                    Operator:
                  </label>
                  <MultiSelect
                    suggestions={['equals', 'greater than', 'less than']}
                    value={tempOperator ? [tempOperator] : []}
                    onChange={(e) =>
                      handleOperatorSelect(e.target.value[0] || '')
                    }
                    placeholder="Select an operator"
                    singleSelect={true}
                    tagColor="gray"
                  />
                  {operatorError && (
                    <p className="text-red-500 text-xs mt-1">{operatorError}</p>
                  )}
                </div>

                {tempOperator && !operatorError && (
                  <div className="mb-2">
                    <label className="block text-sm font-medium mb-1">
                      Value:
                    </label>
                    {fieldTypes[selectedFieldOption] === 'number' ? (
                      <input
                        type="number"
                        value={tempValue}
                        onChange={(e) => setTempValue(e.target.value)}
                        className="form-input mt-1 block w-full mb-2 rounded-md border-gray-300
                                   shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    ) : (
                      <MultiSelect
                        suggestions={getPossibleValuesForField(selectedFieldOption)}
                        value={tempValue ? [tempValue] : []}
                        onChange={(e) => setTempValue(e.target.value[0] || '')}
                        placeholder="Select a value"
                        singleSelect={true}
                        tagColor="gray"
                      />
                    )}
                  </div>
                )}
              </>
            )}

          <button
            type="button"
            onClick={handleAddFilter}
            className="bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 px-3 rounded"
          >
            Add Filter
          </button>

          <div className="flex flex-col sm:flex-row items-center justify-between gap-2 mt-4">
            <div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 px-3 rounded"
                onClick={handleSelectAllClick}
              >
                {everythingSelected
                  ? 'Unselect All Students'
                  : 'Select All Students'}
              </button>

              <button
                className="bg-blue-500 hover:bg-blue-700 ml-4 text-white text-xs py-2 px-3 rounded"
                onClick={handleResetFilters}
              >
                Reset Filters
              </button>
            </div>

            <span className="mt-2 sm:mt-0 bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              {filteredStudentsCount} Students Displayed
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnhancedSearchComponent;
