// src/utils/fetchMilestones.js

import { getDatabase, ref, get } from 'firebase/database';

/**
 * Fetches milestones based on the provided majors.
 *
 * @param {Array<string>} majors - An array of majors to filter milestones.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of milestones corresponding to the provided majors.
 */
export const fetchMilestonesFromDatabase = async (majors) => {
  try {
    if (!Array.isArray(majors)) {
      throw new Error('Invalid input: majors should be an array.');
    }

    // Check if milestones are already stored in localStorage
    const storedMilestones = localStorage.getItem('milestones');
    if (storedMilestones) {
      const parsedMilestones = JSON.parse(storedMilestones);
      const filteredMilestones = parsedMilestones.filter((milestone) =>
        majors.includes(milestone.Major)
      );
      return filteredMilestones;
    }

    // Define the Firebase Realtime Database reference
    const projectAndCollectionId = '1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/Milestones';
    const dbRef = ref(getDatabase(), projectAndCollectionId);

    // Fetch data from Firebase
    const milestonesSnapshot = await get(dbRef);
    const allMilestones = [];

    milestonesSnapshot.forEach((milestoneSnapshot) => {
      const milestoneData = milestoneSnapshot.val();
      allMilestones.push({
        Major: milestoneData.Major,
        Y1F: milestoneData['Y1F'],
        Y1S: milestoneData['Y1S'],
        Y2F: milestoneData['Y2F'],
        Y2S: milestoneData['Y2S'],
        Y3F: milestoneData['Y3F'],
        Y3S: milestoneData['Y3S'],
        Y4F: milestoneData['Y4F'],
        Y4S: milestoneData['Y4S'],
      });
    });

    // Store fetched milestones in localStorage for future use
    localStorage.setItem('milestones', JSON.stringify(allMilestones));

    // Filter milestones based on the provided majors
    const filteredMilestones = allMilestones.filter((milestone) =>
      majors.includes(milestone.Major)
    );

    return filteredMilestones;
  } catch (error) {
    console.error('Error fetching milestones from the Realtime Database:', error);
    return [];
  }
};
