// UsersList.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { TextInput, Badge } from 'flowbite-react';

const UsersList = ({ updateUserCount }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [groupFilter, setGroupFilter] = useState('');
  const [availableGroups, setAvailableGroups] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['admin', 'super_admin']).get();
        const usersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setFilteredUsers(usersData);
        updateUserCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        const groupsData = snapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
        setAvailableGroups(groupsData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchUsers();
    fetchGroups();
  }, [updateUserCount]);

  useEffect(() => {
    let filtered = [...users];

    // Search Filter
    if (searchTerm.trim() !== '') {
      const lowerSearch = searchTerm.toLowerCase();
      filtered = filtered.filter(user =>
        user.firstName.toLowerCase().includes(lowerSearch) ||
        user.lastName.toLowerCase().includes(lowerSearch) ||
        user.email.toLowerCase().includes(lowerSearch)
      );
    }

    // Group Filter
    if (groupFilter !== '') {
      filtered = filtered.filter(user => user.groups && user.groups.includes(groupFilter));
    }

    setFilteredUsers(filtered);
  }, [searchTerm, groupFilter, users]);

  return (
    <div>
      {/* Filter Card */}
      <div className="mb-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
          {/* Search Input */}
          <TextInput
            id="search"
            type="search"
            placeholder="Name, email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-1/3"
          />

          {/* Group Filter */}
          <select
            onChange={(e) => setGroupFilter(e.target.value)}
            className="w-full md:w-56 p-2 pr-10 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600"
            value={groupFilter}
          >
            <option value="">All Groups</option>
            {availableGroups.map((group, index) => (
              <option key={index} value={group.id}>{group.name}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Main List Card */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        {filteredUsers.length === 0 ? (
          <p className="text-center text-gray-500">No users found.</p>
        ) : (
          <div className="space-y-4">
            {filteredUsers.map(user => (
              <div key={user.id} className="flex items-center justify-between p-4 border rounded-md shadow-sm bg-gray-50 dark:bg-gray-800">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="bg-gray-300 dark:bg-gray-600 rounded-full w-12 h-12 flex items-center justify-center text-white text-lg font-semibold">
                      {user.firstName.charAt(0)}
                      {user.lastName.charAt(0)}
                    </span>
                  </div>
                  <div className="ml-4">
                    <p className="font-medium text-gray-800 dark:text-gray-200">{user.firstName} {user.lastName}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{user.email}</p>
                    {/* Display Groups if available */}
                    {user.groups && user.groups.length > 0 && (
                      <div className="flex flex-wrap mt-2">
                        {user.groups.map((groupId, index) => {
                          const group = availableGroups.find(g => g.id === groupId);
                          return group ? (
                            <Badge key={index} color="indigo" className="mr-2 mb-2">
                              {group.name}
                            </Badge>
                          ) : null;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                {/* Optional: Add action buttons or additional info here */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersList;
