import React, { forwardRef, useRef } from 'react';
import { useDrag } from 'react-dnd';
import CourseRendering from './CourseRendering';

const DraggableCourse = forwardRef(
  (
    {
      course,
      index,
      yearNumber,
      semester,
      onRemove,
      moveCourse,
      onAlternativesClick,
      isCompleted,
      onEvaluate,
      viewMode,
      prereqClasses,
      setShowSidebar
    },
    ref
  ) => {
    const nodeRef = useRef(null);

    const [{ isDragging }, drag] = useDrag({
      type: 'course',
      item: () => {
        const node = nodeRef.current;
        const height = node ? node.getBoundingClientRect().height : 0;
        return { course, fromYear: yearNumber, fromSemester: semester, index, height };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: !isCompleted,
    });

    const combinedRef = (node) => {
      drag(node);
      nodeRef.current = node;
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else {
          ref.current = node;
        }
      }
    };

    return (
      <>
        {!isDragging && (
          <div
            ref={combinedRef}
            className={`draggableCourse`}
          >
            <CourseRendering
              onAlternativesClick={onAlternativesClick}
              finalizedCourses={[course]}
              prereqClasses={prereqClasses}
              setShowSidebar={setShowSidebar}
              yearNumber={yearNumber}
              semester={semester}
              onRemove={() => onRemove(course, yearNumber, semester)}
              isCompleted={isCompleted}
              onEvaluate={onEvaluate}
              viewMode={viewMode}
              isDragging={isDragging}
              hideIcons={isDragging} // Hide icons during dragging
            />
          </div>
        )}
      </>
    );
  }
);

export default React.memo(DraggableCourse);
