import React, { useState, useEffect, useRef } from "react";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import MajorRequirementsRendering from "./MajorRequirementsRendering";
import MajorCoursesRendering from "./MajorCoursesRendering";
import ElectivesRendering from "./ElectivesRendering";

const DegreeAuditModal = ({ showModal, onClose, plannedCourses, pathwayCourses, fetchedCourseData }) => {
  const majors = Object.keys(pathwayCourses);
  const [selectedMajor, setSelectedMajor] = useState(majors[0] || "");
  const modalRef = useRef(null);
  const [expandedSections, setExpandedSections] = useState({
    majorCourses: false,
    majorRequirements: false,
    electives: false,
  });

  useEffect(() => {
    if (!majors.includes(selectedMajor)) {
      setSelectedMajor(majors[0] || "");
    }
  }, [pathwayCourses, majors, selectedMajor]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close modal if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!showModal) return null;

  const handleTabClick = (major) => {
    setSelectedMajor(major);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const courses = pathwayCourses[selectedMajor] || [];

  const majorCourses = courses.filter(
    (course) => course.courseType === "Major Course"
  );
  const majorRequirements = courses.filter(
    (course) => course.courseType === "Requirement"
  );
  const electives = courses.filter(
    (course) => course.courseType === "Elective"
  );

  
  const calculateMajorCredits = (courses) => {
    let totalCredits = 0;

    courses.forEach(course => {
      const courseNumber = course.courseInfo_courseNumber;

      const fetchedCourse = fetchedCourseData.find(
        data => data.courseInfo_courseNumber === courseNumber
      );

      if (fetchedCourse) {
        totalCredits += (fetchedCourse.Credits || 0);
      }
    });

    return totalCredits;
  };
  
  const calculateRequirementCredits = (courses) => {
    return 3*(courses.length);
  };

  const calculateElectiveCredits = (courses) => {
    return 3*(courses.length);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-4 overflow-y-auto"
        style={{ maxHeight: "95vh" }}
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-2xl font-semibold">Degree Audit: {selectedMajor}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none text-3xl"
            aria-label="Close Modal"
          >
            &times;
          </button>
        </div>

        <div className="mt-2">
          <div className="flex border-b mb-3">
            {majors.map((major) => (
              <button
                key={major}
                onClick={() => handleTabClick(major)}
                className={`px-6 py-2 mr-4 -mb-px border-b-2 font-medium text-sm focus:outline-none transition-colors ${
                  selectedMajor === major
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-600 hover:text-blue-600 hover:border-blue-500"
                }`}
              >
                {major}
              </button>
            ))}
          </div>

          <div className="space-y-2">
            <section>
              <div
                onClick={() => toggleSection("majorCourses")}
                className="flex justify-between items-center p-3 border border-gray-300 rounded-lg bg-gray-100 cursor-pointer"
              >
                <div className="flex items-center">
                  <h3 className="text-xl font-semibold">Major Courses</h3>
                  <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-gray-400 ml-2">
                    {calculateMajorCredits(majorCourses)} Credits
                  </span>
                </div>
                {expandedSections.majorCourses ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                )}
              </div>
              {expandedSections.majorCourses && (
                <MajorCoursesRendering
                  courseList={majorCourses}
                  plannedCourses={plannedCourses}
                  fetchedCourseData={fetchedCourseData}
                />
              )}
            </section>

            <section>
              <div
                onClick={() => toggleSection("majorRequirements")}
                className="flex justify-between items-center p-3 border border-gray-300 rounded-lg bg-gray-100 mt-3 cursor-pointer"
              >
                <div className="flex items-center">
                  <h3 className="text-xl font-semibold">Major Requirements</h3>
                  <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-gray-400 ml-2">
                    {calculateRequirementCredits(majorRequirements)} Credits
                  </span>
                </div>
                {expandedSections.majorRequirements ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                )}
              </div>
              {expandedSections.majorRequirements && (
                <MajorRequirementsRendering
                  courseList={majorRequirements}
                  plannedCourses={plannedCourses}
                />
              )}
            </section>

            <section>
              <div
                onClick={() => toggleSection("electives")}
                className="flex justify-between items-center p-3 border border-gray-300 rounded-lg bg-gray-100 mt-3 cursor-pointer"
              >
                <div className="flex items-center">
                  <h3 className="text-xl font-semibold">Electives</h3>
                  <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-gray-400 ml-2">
                    {calculateElectiveCredits(electives)} Credits
                  </span>
                </div>
                {expandedSections.electives ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                )}
              </div>
              {expandedSections.electives && (
                <ElectivesRendering
                  courseList={electives}
                  majorCourses={majorCourses}
                  plannedCourses={plannedCourses}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DegreeAuditModal;
