// src/components/Communications/DedicatedAdvisorChat.jsx

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { db } from '../../firebase';
import {
  collection,
  addDoc,
  query,
  orderBy,
  where,
  getDocs,
  writeBatch,
  serverTimestamp,
  onSnapshot
} from 'firebase/firestore';
import { FaPaperPlane } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';

const DedicatedAdvisorChat = ({ studentData, advisorData, activeTab }) => {
  // We'll rely on the props instead of re-fetching from Firestore
  const studentId = studentData?.uid;
  const advisorId = advisorData?.uid;

  // Construct a unique chatId if all props exist
  const chatId = studentId && activeTab && advisorId
    ? `${activeTab}_${studentId}_${advisorId}`
    : null;

  // Prepare a memoized Firestore query so it doesn't recreate on every render
  const messagesQuery = useMemo(() => {
    if (!chatId) return null;
    const messagesRef = collection(db, 'chats', chatId, 'messages');
    return query(messagesRef, orderBy('timestamp'));
  }, [chatId]);

  // State
  const [messages, setMessages] = useState([]);
  const [formValue, setFormValue] = useState('');

  const messagesEndRef = useRef(null);

  // ─────────────────────────────────────────────────────────────
  // 1) Real-time listener for messages (only if messagesQuery changes)
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!messagesQuery) {
      setMessages([]);
      return;
    }

    const unsubscribe = onSnapshot(
      messagesQuery,
      (snapshot) => {
        const msgs = [];
        snapshot.forEach((docSnap) => {
          const data = docSnap.data();

          // Add sender info from props
          if (data.senderId === advisorId) {
            data.senderName = `${advisorData.firstName} ${advisorData.lastName}`;
            data.senderPhotoURL = advisorData.photoURL;
            data.senderType = 'advisor';
          } else if (data.senderId === studentId) {
            data.senderName = `${studentData.firstName} ${studentData.lastName}`;
            data.senderPhotoURL = studentData.photoURL;
            data.senderType = 'student';
          } else {
            data.senderName = 'Unknown';
            data.senderPhotoURL = '/default.jpg';
            data.senderType = 'unknown';
          }

          msgs.push(data);
        });
        setMessages(msgs);

        // Scroll to bottom
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      },
      (error) => {
        console.error('Error fetching messages:', error);
      }
    );

    return () => unsubscribe();
  }, [
    messagesQuery, // changes only if chatId changes
    advisorId,
    studentId,
    advisorData,
    studentData
  ]);

  // ─────────────────────────────────────────────────────────────
  // 2) Mark messages as read (messages from the student)
  //    (only re-run if chatId changes or we get new messages)
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!chatId || !studentId || messages.length === 0) return;

    const markAsRead = async () => {
      try {
        // Find all unread messages from the student
        const messagesRef = collection(db, 'chats', chatId, 'messages');
        const unreadQ = query(
          messagesRef,
          where('senderId', '==', studentId),
          where('read', '==', false)
        );
        const snap = await getDocs(unreadQ);

        if (!snap.empty) {
          const batch = writeBatch(db);
          snap.forEach((docSnap) => {
            batch.update(docSnap.ref, { read: true });
          });
          await batch.commit();
        }
      } catch (err) {
        console.error('Error marking messages read:', err);
      }
    };
    markAsRead();
  }, [chatId, studentId, messages]);

  // ─────────────────────────────────────────────────────────────
  // 3) Send a message
  // ─────────────────────────────────────────────────────────────
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!formValue.trim() || !messagesQuery || !advisorId) return;

    try {
      // messagesQuery is read-only, so let's reconstruct the collection ref
      const messagesRef = collection(db, 'chats', chatId, 'messages');
      await addDoc(messagesRef, {
        content: formValue,
        senderId: advisorId,
        senderType: 'advisor',
        timestamp: serverTimestamp(),
        read: false,
      });
      setFormValue('');

      // Optionally scroll to bottom
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // Group messages for rendering
  // ─────────────────────────────────────────────────────────────
  const groupedMessages = [];
  let lastGroup = null;
  const TWO_MIN = 2 * 60 * 1000;

  messages.forEach((msg) => {
    const msgTime = msg.timestamp?.toDate
      ? msg.timestamp.toDate().getTime()
      : Date.now();

    if (!lastGroup) {
      lastGroup = {
        senderId: msg.senderId,
        senderName: msg.senderName,
        senderPhotoURL: msg.senderPhotoURL,
        messages: [msg],
        lastTimestamp: msgTime,
      };
    } else {
      const timeDiff = msgTime - lastGroup.lastTimestamp;
      const sameSender = msg.senderId === lastGroup.senderId;
      if (sameSender && timeDiff < TWO_MIN) {
        lastGroup.messages.push(msg);
        lastGroup.lastTimestamp = msgTime;
      } else {
        groupedMessages.push(lastGroup);
        lastGroup = {
          senderId: msg.senderId,
          senderName: msg.senderName,
          senderPhotoURL: msg.senderPhotoURL,
          messages: [msg],
          lastTimestamp: msgTime,
        };
      }
    }
  });
  if (lastGroup) groupedMessages.push(lastGroup);

  // ─────────────────────────────────────────────────────────────
  // Render
  // ─────────────────────────────────────────────────────────────
  return (
    <div className="flex flex-col w-full h-full bg-white border border-gray-200 rounded-md">
      {/* Header */}
      <div className="flex items-center justify-between p-3 bg-blue-600 rounded-t-md">
        <div className="flex items-center space-x-2">
          {studentData && (
            <img
              src={studentData.photoURL}
              alt={`${studentData.firstName} ${studentData.lastName}`}
              className="w-10 h-10 rounded-full object-cover border-2 border-white"
            />
          )}
          <div className="text-sm text-white">
            <div className="font-semibold">
              {studentData
                ? `${studentData.firstName} ${studentData.lastName}`
                : 'Student'}
            </div>
            <div className="text-xs text-white/90">Plan: {activeTab}</div>
          </div>
        </div>
        {studentData && (
          <Link
            to={`/profile/${studentData.uid}`}
            className="text-xs bg-white text-blue-600 px-2 py-1 rounded hover:bg-gray-100"
          >
            Show Student Profile
          </Link>
        )}
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-3 bg-gray-50">
        {messages.length === 0 && chatId && (
          <p className="text-center text-gray-500 text-sm">
            No messages yet. Start the conversation!
          </p>
        )}

        {groupedMessages.map((group, gIndex) => {
          const isAdvisor = group.senderId === advisorId;
          const lastMsg = group.messages[group.messages.length - 1];
          const timestamp = lastMsg.timestamp?.toDate
            ? lastMsg.timestamp.toDate()
            : null;
          const timeAgo = timestamp
            ? formatDistanceToNow(timestamp, { addSuffix: true })
            : '';

          return (
            <div
              key={gIndex}
              className={`mb-4 flex ${
                isAdvisor ? 'justify-end' : 'justify-start'
              }`}
            >
              {!isAdvisor && (
                <img
                  src={group.senderPhotoURL || '/default.jpg'}
                  alt={group.senderName}
                  className="w-8 h-8 rounded-full mr-2 self-end"
                />
              )}
              <div className="max-w-[70%] flex flex-col space-y-1">
                {group.messages.map((m, mIndex) => (
                  <div
                    key={mIndex}
                    className={`px-3 py-2 text-sm rounded-lg break-words ${
                      isAdvisor
                        ? 'bg-blue-500 text-white rounded-br-none'
                        : 'bg-white text-gray-800 rounded-bl-none shadow'
                    }`}
                  >
                    {m.content}
                  </div>
                ))}
                <p
                  className={`text-xs mt-1 ${
                    isAdvisor ? 'text-right text-gray-400' : 'text-gray-400'
                  }`}
                >
                  {timeAgo}
                </p>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <form
        onSubmit={handleSendMessage}
        className="p-2 bg-white border-t flex items-center"
      >
        <input
          type="text"
          className="flex-1 border border-gray-300 rounded-full px-3 py-1.5 focus:outline-none focus:ring focus:ring-blue-500 text-sm"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder={chatId ? 'Type your message...' : 'No plan selected'}
          disabled={!chatId}
        />
        <button
          type="submit"
          className={`ml-2 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ${
            !chatId ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={!chatId}
          aria-label="Send Message"
        >
          <FaPaperPlane size={14} />
        </button>
      </form>
    </div>
  );
};

export default DedicatedAdvisorChat;
