import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const AcademicYearChart = ({ data }) => {
  if (!data) {
    data = [
      { grade: "Freshman", count: 150 },
      { grade: "Sophomore", count: 120 },
      { grade: "Junior", count: 100 },
      { grade: "Senior", count: 80 },
      { grade: "Non-Degree Seeking", count: 30 },
      { grade: "Other", count: 20 },
    ];
  }

  // Define the desired order of grades
  const gradeOrder = [
    "Freshman",
    "Sophomore",
    "Junior",
    "Senior",
    "Non-Degree Seeking",
    "Other",
  ];

  // Sort the data array based on the specified order
  const sortedData = data.sort(
    (a, b) => gradeOrder.indexOf(a.grade) - gradeOrder.indexOf(b.grade)
  );

  const totalStudents = sortedData.reduce((sum, item) => sum + item.count, 0);

  const chartData = {
    labels: sortedData.map((item) => item.grade), // X-axis labels
    datasets: [
      {
        label: "Academic Year Distribution",
        data: sortedData.map((item) =>
          ((item.count / totalStudents) * 100).toFixed(2)
        ), // Convert to percentages
        backgroundColor: [
          "#4caf50",
          "#2196f3",
          "#ff9800",
          "#9c27b0",
          "#f44336",
          "#607d8b",
        ], // Unique colors for each bar
        hoverBackgroundColor: [
          "#388e3c",
          "#1976d2",
          "#f57c00",
          "#7b1fa2",
          "#d32f2f",
          "#455a64",
        ], // Hover colors for each bar
        borderColor: "#ffffff",
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`; // Show percentage value
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Percentage of Students", // X-axis title
        },
        ticks: {
          callback: (value) => `${value}%`, // Append '%' to X-axis ticks
        },
      },
      y: {
        grid: {
          display: false, // Hide grid lines
        },
        title: {
          display: true,
          text: "Academic Year", // Y-axis title
        },
      },
    },
  };

  return (
    <div
      className="w-full flex items-center justify-center"
      style={{ width: "900px", height: "350px" }}
    >
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default AcademicYearChart;
