import { db } from '../../../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { calculateEnrollmentTotal, randomizeEnrollment } from './CalculateEnrollmentData';
import { calculateEnrollmentTrends, findEnrollmentTotal } from './CalculateEnrollmentTrends';
import { calculateMajorDistribution } from './CalculateMajorDistribution';
import { calculateYearDistribution } from './CalculateYearDistribution';
import { calculateGradeDistribution } from './CalculateGradeDistribution';

const calculateAnalyticsData = async (fetchedCourseData) => {
  const first20Courses = fetchedCourseData.slice(0, 20);

  for (const course of fetchedCourseData) {
    const { courseInfo_courseNumber, courseInfo_courseName } = course;

    const courseRef = doc(db, 'courses', courseInfo_courseNumber);

    if (courseInfo_courseNumber === "CMPT 410") {
      const forcedEnrollmentData = [
        { term: "FA22", Total: 30 },
        { term: "SP23", Total: 40 },
        { term: "SU23", Total: 14 },
        { term: "FA23", Total: 35 },
        { term: "SP24", Total: 42 },
        { term: "SU24", Total: 22 },
        { term: "FA24", Total: 47 },
        { term: "SP25", Total: 53 },
        { term: "SU25", Total: 21 },
        { term: "FA25", Total: 67 },
        { term: "SP26", Total: 62 },
        { term: "SU26", Total: 30 },
        { term: "FA26", Total: 68 },
        { term: "SP27", Total: 74 },
        { term: "SU27", Total: 34 },
      ];

      const referenceTerms = {
        fall: { historical: ['FA22', 'FA23'], forecasted: ['FA23', 'FA24'] },
        spring: { historical: ['SP23', 'SP24'], forecasted: ['SP24', 'SP25'] },
        summer: { historical: ['SU23', 'SU24'], forecasted: ['SU24', 'SU25'] },
      };

      const trends = calculateEnrollmentTrends(forcedEnrollmentData, referenceTerms);

      const majorEnrollmentData = [
        { major: "Computer Science", count: 28 },
        { major: "Electrical Engineering", count: 7 },
        { major: "Mechanical Engineering", count: 4 },
        { major: "Mathematics", count: 4 },
        { major: "Physics", count: 2 },
        { major: "Economics", count: 2 },
        { major: "Data Science", count: 3 },
        { major: "Bioinformatics", count: 2 },
        { major: "Statistics", count: 1 },
      ];

      const yearEnrollmentData = [
        { grade: "Freshman", count: 2 },
        { grade: "Sophomore", count: 4 },
        { grade: "Junior", count: 17 },
        { grade: "Senior", count: 28 },
        { grade: "Non-Degree Seeking", count: 1 },
        { grade: "Other", count: 1 },
      ];

      const gradeData = [
        { grade: "A", count: 10 },
        { grade: "A-", count: 12 }, 
        { grade: "B+", count: 12 },
        { grade: "B", count: 6 },
        { grade: "B-", count: 3 },
        { grade: "C+", count: 1 }, 
        { grade: "C", count: 3 }, 
        { grade: "C-", count: 1 }, 
        { grade: "D", count: 3 }, 
        { grade: "F", count: 2 }, 
        { grade: "W", count: 1 }, 
        { grade: "N/A", count: 2 },
      ];


      await setDoc(courseRef, {
        courseNumber: "CMPT 410",
        courseName: "Machine Learning",
        enrollmentData: forcedEnrollmentData,
        currentEnrollments: 26,
        trends,
        majorEnrollmentData,
        yearEnrollmentData,
        gradeData
      });

      console.log(`Course ${courseInfo_courseNumber} created with forced enrollment data and trends.`);
      continue;
    }




    const enrollmentData = calculateEnrollmentTotal(courseInfo_courseNumber);

    const referenceTerms = {
      fall: { historical: ['FA22', 'FA23'], forecasted: ['FA23', 'FA26'] },
      spring: { historical: ['SP23', 'SP24'], forecasted: ['SP24', 'SP27'] },
      summer: { historical: ['SU23', 'SU24'], forecasted: ['SU24', 'SU27'] },
    };

    const trends = calculateEnrollmentTrends(enrollmentData, referenceTerms);

    const majorEnrollmentData = calculateMajorDistribution(courseInfo_courseNumber, enrollmentData);

    const yearEnrollmentData = calculateYearDistribution(courseInfo_courseNumber, enrollmentData);

    const gradeData = calculateGradeDistribution(courseInfo_courseNumber, enrollmentData);

    await setDoc(courseRef, {
      courseNumber: courseInfo_courseNumber,
      courseName: courseInfo_courseName,
      enrollmentData,
      currentEnrollments: randomizeEnrollment(findEnrollmentTotal(enrollmentData, 'FA24') - 20, 20),
      trends,
      majorEnrollmentData,
      yearEnrollmentData,
      gradeData,
    });

    console.log(`Course ${courseInfo_courseNumber} created with enrollment data and trends.`);
  }
};

export default calculateAnalyticsData;
