// StudentRecommendModal.js
import React, { useState, useMemo } from 'react';
import { Spinner } from 'flowbite-react';
import { FaUserPlus, FaSearch } from 'react-icons/fa';

const StudentRecommendModal = ({
  students = [],
  onRecommendSuccess,     // Function that updates Firestore (arrayUnion)
  selectedResource,       // Resource object to recommend
  onClose,                // Close the modal
  singleStudentId,        // If present, we show only that one student
  isVisible = false,
  isSubmitting = false,   // Show a local Spinner if recommending
}) => {
  // Pre-select single student if passed
  const [selectedStudentIds, setSelectedStudentIds] = useState(
    singleStudentId ? [singleStudentId] : []
  );

  // Search text
  const [searchTerm, setSearchTerm] = useState('');

  // If singleStudentId is given, find that student
  const singleStudent = students.find((s) => s.id === singleStudentId);

  // Helper to combine firstName + lastName
  const getFullName = (student) => {
    const f = student?.firstName || '';
    const l = student?.lastName || '';
    return `${f} ${l}`.trim() || 'Student';
  };

  // Helper to get initials (if you want the same logic you used elsewhere)
  const getInitials = (student) => {
    const f = (student.firstName || '').charAt(0).toUpperCase();
    const l = (student.lastName || '').charAt(0).toUpperCase();
    return (f + l) || 'NA';
  };

  // Filter logic for multi-student mode
  const filteredStudents = useMemo(() => {
    if (!searchTerm) return students;

    const lower = searchTerm.toLowerCase();
    return students.filter((student) => {
      const firstName = student.firstName?.toLowerCase() || '';
      const lastName = student.lastName?.toLowerCase() || '';
      const email = student.email?.toLowerCase() || '';
      const majorStr = student.major?.toLowerCase() || '';

      return (
        firstName.includes(lower) ||
        lastName.includes(lower) ||
        email.includes(lower) ||
        majorStr.includes(lower)
      );
    });
  }, [students, searchTerm]);

  // Toggling selection for multi-student
  const handleSelect = (studentId) => {
    // In single-student mode, do nothing
    if (singleStudentId) return;

    setSelectedStudentIds((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  // Submit recommendation
  const handleSubmit = async () => {
    if (selectedStudentIds.length === 0) {
      alert('Please select at least one student.');
      return;
    }
    try {
      // Delegate to parent: it will handle the actual Firestore update
      await onRecommendSuccess(selectedStudentIds);
    } catch (err) {
      console.error('Error recommending resource:', err);
    }
  };

  // If the modal is not visible or we have no resource, exit early
  if (!isVisible || !selectedResource) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 min-w-[70vw] max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Stop click from closing modal
      >
        {/* Close Button */}
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
                     hover:text-gray-900 rounded-lg text-sm w-8 h-8 dark:hover:bg-gray-600 
                     dark:hover:text-white"
          onClick={onClose}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <h2 className="text-2xl font-bold mb-4">
          Recommend “{selectedResource.name}” to{' '}
          {singleStudentId ? getFullName(singleStudent) : 'Students'}
        </h2>

        {/* Show a search bar if we have multi-student mode */}
        {!singleStudentId && (
          <div className="mb-3 flex items-center space-x-2 border-b pb-3">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search name, email, or major..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border-none focus:outline-none flex-1 bg-transparent dark:bg-transparent"
            />
          </div>
        )}

        <div className="max-h-[50vh] overflow-y-auto">
          {/* Multi-student table */}
          {!singleStudentId ? (
            <table className="min-w-full bg-white dark:bg-gray-800 border">
              <thead className="border-b sticky top-0 bg-gray-50 dark:bg-gray-600">
                <tr>
                  <th className="py-2 px-4 border-b text-center">Select</th>
                  <th className="py-2 px-4 border-b">Name</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Major</th>
                  <th className="py-2 px-4 border-b">Grad Year</th>
                  <th className="py-2 px-4 border-b">Last Login</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => {
                  // If resource is already recommended
                  const resourceRecommended = selectedResource
                    ? (student.resources || []).includes(selectedResource.id)
                    : false;

                  return (
                    <tr
                      key={student.id}
                      className="hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <td className="py-2 px-4 border-b text-center">
                        <input
                          type="checkbox"
                          disabled={resourceRecommended}
                          checked={selectedStudentIds.includes(student.id)}
                          onChange={() => handleSelect(student.id)}
                        />
                      </td>
                      <td className="py-2 px-4 border-b">
                        {getFullName(student)}
                      </td>
                      <td className="py-2 px-4 border-b">{student.email}</td>
                      <td className="py-2 px-4 border-b">
                        {student.surveyAnswers?.Majors || 'Undeclared Major'}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {student.surveyAnswers?.SchoolEnd?.year || 'N/A'}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {student.lastLoginDate || 'N/A'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            // Single-student scenario
            <div className="flex flex-col space-y-2">
              {singleStudent ? (
                <div className="flex flex-col p-2 border rounded">
                  <div className="flex items-center mb-2">
                    {/* Photo or initials */}
                    {singleStudent.photoURL ? (
                      <img
                        src={singleStudent.photoURL}
                        alt="Profile"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full mr-2 flex items-center justify-center bg-gray-400 text-white font-semibold">
                        {getInitials(singleStudent)}
                      </div>
                    )}
                    <div>
                      <p className="font-semibold">
                        {getFullName(singleStudent)}
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        {singleStudent.email}
                      </p>
                    </div>
                  </div>
                  <p className="text-sm">
                    Major:{' '}
                    {singleStudent.major || 'Undeclared Major'}
                  </p>
                  <p className="text-sm">
                    Grad Year:{' '}
                    {singleStudent.surveyAnswers?.SchoolEnd?.year || 'N/A'}
                  </p>
                  <p className="text-sm">
                    Last Login: {singleStudent.lastLoginDate || 'N/A'}
                  </p>
                  {/* Checkbox is effectively read-only here */}
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      checked={selectedStudentIds.includes(singleStudent.id)}
                      readOnly
                    />
                    <span className="ml-2 text-sm">
                      This student is selected
                    </span>
                  </div>
                </div>
              ) : (
                <p className="text-red-500">Student not found.</p>
              )}
            </div>
          )}
        </div>

        {/* Recommend Action */}
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSubmit}
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            disabled={isSubmitting || selectedStudentIds.length === 0}
          >
            <FaUserPlus className="mr-2" />
            Recommend
          </button>
        </div>

        {/* Local Spinner overlay if isSubmitting */}
        {isSubmitting && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25 rounded-lg">
            <Spinner aria-label="Processing" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentRecommendModal;
