import { useCallback } from 'react';
import { db } from '../../firebase'; // Adjust path as needed
import { runTransaction } from 'firebase/firestore';

/**
 * Custom hook to handle moving a course between semesters (or reordering within the same semester).
 *
 * @param {Object} params
 * @param {Object} params.currentUser - The currently logged-in user object
 * @param {string} params.effectiveUserId - The userId of the student whose plan is being edited
 * @param {Object} params.finalizedOrganizedCourses - The local state object containing the user's courses by year/semester
 * @param {function} params.setFinalizedOrganizedCourses - State setter function for finalizedOrganizedCourses
 * @param {function} params.recheckPrerequisites - A function to re-check prerequisites after updates
 * @param {string} params.activeTab - The plan name that's currently active
 */
export default function useMoveCourse({
  currentUser,
  effectiveUserId,
  finalizedOrganizedCourses,
  setFinalizedOrganizedCourses,
  recheckPrerequisites,
  activeTab,
}) {
  /**
   * moveCourse allows dragging/dropping a course from one year/semester to another, or
   * reordering courses within the same semester.
   *
   * @param {Object}   course        - The course object being moved
   * @param {number}   fromYear      - The original year (e.g., 1, 2, 3, 4)
   * @param {string}   fromSemester  - The original semester name, e.g. 'Fall' or 'Spring'
   * @param {number}   fromIndex     - The index of the course in the fromSemester's list
   * @param {number}   toYear        - The destination year
   * @param {string}   toSemester    - The destination semester name
   * @param {number}   toIndex       - The index in the toSemester's list where the course should be inserted
   */
  const moveCourse = useCallback(
    async (course, fromYear, fromSemester, fromIndex, toYear, toSemester, toIndex) => {
      // 1) Check if moving within the same semester or between different semesters
      if (fromYear === toYear && fromSemester === toSemester) {
        // --------------------------------------------
        // Reordering within the same semester
        // --------------------------------------------
        setFinalizedOrganizedCourses((prev) => {
          const updatedCourses = { ...prev };
          const semesterList = [...updatedCourses[`year${fromYear}`][fromSemester]];

          // Remove the course from its old position and insert at new position
          const [movedCourse] = semesterList.splice(fromIndex, 1);
          semesterList.splice(toIndex, 0, movedCourse);

          updatedCourses[`year${fromYear}`][fromSemester] = semesterList;
          recheckPrerequisites(updatedCourses);

          return updatedCourses;
        });

        // Update Firestore: reorder course IDs in the same semester
        try {
          if (currentUser?.uid) {
            const userRef = db.collection('users').doc(currentUser.uid);
            // Convert 'Fall'/'Spring' to 'S1'/'S2'
            const semesterCode = fromSemester === 'Fall' ? 'S1' : 'S2';

            // Prepare the updated course IDs in the new order
            const finalCourseNumbers = [
              ...finalizedOrganizedCourses[`year${fromYear}`][fromSemester],
            ].map((c) => c.courseInfo_courseNumber);

            // We reorder the array in memory to reflect the new `toIndex`
            const [movedCourseId] = finalCourseNumbers.splice(fromIndex, 1);
            finalCourseNumbers.splice(toIndex, 0, movedCourseId);

            await userRef.update({
              [`schedules.${activeTab}.year${fromYear}.${semesterCode}.courses`]: finalCourseNumbers,
            });
          }
        } catch (err) {
          console.error('Error updating course order in Firestore:', err);
        }
      } else {
        // --------------------------------------------
        // Moving between different semesters
        // --------------------------------------------
        const fromCourses = [
          ...(finalizedOrganizedCourses[`year${fromYear}`]?.[fromSemester] ?? []),
        ];
        const toCourses = [
          ...(finalizedOrganizedCourses[`year${toYear}`]?.[toSemester] ?? []),
        ];

        // Find the course index in the fromCourses array
        const courseIndex = fromCourses.findIndex(
          (c) => c.courseInfo_courseNumber === course.courseInfo_courseNumber
        );
        if (courseIndex === -1) return; // Course not found — should never happen if data is correct

        const [moved] = fromCourses.splice(courseIndex, 1);
        // Insert at toIndex or at the end if no toIndex is provided
        toCourses.splice(toIndex !== undefined ? toIndex : toCourses.length, 0, moved);

        // Locally update state
        setFinalizedOrganizedCourses((prev) => {
          const updated = { ...prev };
          updated[`year${fromYear}`] = {
            ...updated[`year${fromYear}`],
            [fromSemester]: fromCourses,
          };
          updated[`year${toYear}`] = {
            ...updated[`year${toYear}`],
            [toSemester]: toCourses,
          };

          recheckPrerequisites(updated);
          return updated;
        });

        // Sync changes to Firestore
        if (currentUser?.uid) {
          const userRef = db.collection('users').doc(currentUser.uid);
          const fromSemCode = fromSemester === 'Fall' ? 'S1' : 'S2';
          const toSemCode = toSemester === 'Fall' ? 'S1' : 'S2';

          try {
            await runTransaction(db, async (transaction) => {
              const userDoc = await transaction.get(userRef);
              if (!userDoc.exists) {
                throw new Error('User data not found');
              }

              const userData = userDoc.data();
              const scheduleData = userData.schedules[activeTab];

              // Remove from original semester in Firestore
              const originalSemesterCourses =
                scheduleData[`year${fromYear}`][fromSemCode]?.courses || [];
              const updatedFromCourses = originalSemesterCourses.filter(
                (courseNumber) => courseNumber !== course.courseInfo_courseNumber
              );

              // Add to new semester in Firestore
              const originalToCourses = scheduleData[`year${toYear}`][toSemCode]?.courses || [];
              originalToCourses.splice(
                toIndex !== undefined ? toIndex : originalToCourses.length,
                0,
                course.courseInfo_courseNumber
              );

              // Save updated arrays back to Firestore
              transaction.update(userRef, {
                [`schedules.${activeTab}.year${fromYear}.${fromSemCode}.courses`]: updatedFromCourses,
                [`schedules.${activeTab}.year${toYear}.${toSemCode}.courses`]: originalToCourses,
              });
            });
          } catch (err) {
            console.error('Error moving course in Firestore:', err);
          }
        } else {
          console.error('Error: currentUser or effectiveUserId is not defined');
        }
      }
    },
    [
      currentUser,
      finalizedOrganizedCourses,
      recheckPrerequisites,
      setFinalizedOrganizedCourses,
      activeTab,
    ]
  );

  return { moveCourse };
}
