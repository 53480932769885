import React, { useState } from 'react';
import { TextInput } from 'flowbite-react';

const NumericalInput = ({ value, onChange, placeholder }) => {
  const [warning, setWarning] = useState('');

  const handleBlur = () => {
    // Auto-correct format to 'x.xx'
    let numericValue = parseFloat(value);

    if (isNaN(numericValue) || numericValue < 0 || numericValue > 4) {
      setWarning('Please enter a valid number from 0.00 to 4.00.');
      return;
    }

    // Format value to x.xx
    numericValue = numericValue.toFixed(2);
    setWarning('');
    onChange(numericValue);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Allow only valid characters and enforce max length of 4
    const isValidInput = /^\d{0,1}(\.\d{0,2})?$/.test(inputValue) && inputValue.length <= 4;

    if (isValidInput) {
      setWarning('');
      onChange(inputValue);
    }
  };

  return (
    <div>
      <TextInput
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        className="bg-gray-200 text-black rounded"
        onFocus={() => setWarning('')}
      />
      {warning && <p className="text-red-500 mt-1">{warning}</p>}
    </div>
  );
};

export default NumericalInput;
