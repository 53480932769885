import React, { useState, useEffect } from 'react';
import NavBar from '../../NavBar';
import { db, auth } from '../../firebase';
import VolunteerCart from './VolunteerCart';

const fetchDataFromBackend = async () => {
  const apiUrl = 'http://localhost:4000/volunteeropportunities';

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      console.error(`Error fetching data. Status: ${response.status}`);
      throw new Error(`Error fetching data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data || {};
  } catch (error) {
    console.error('Network error:', error);
    return {};
  }
};

const VolunteerPage = () => {
  const [opportunities, setOpportunities] = useState({});
  const [filteredOpportunities, setFilteredOpportunities] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [durationFilter, setDurationFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [finalizedVolunteerOpportunities, setFinalizedVolunteerOpportunities] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const fetchedOpportunities = await fetchDataFromBackend();
        console.log('Fetched Volunteer Opportunities:', fetchedOpportunities);

        setOpportunities(fetchedOpportunities);
        setFilteredOpportunities(fetchedOpportunities);
      } catch (error) {
        console.error('Error fetching volunteer opportunities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, []);

  useEffect(() => {
    // Filter logic goes here
    // Update setFilteredOpportunities based on searchQuery, durationFilter, or other filters

    const filtered = Object.entries(opportunities).filter(
      ([opportunityName, opportunity]) =>
        opportunityName.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (durationFilter ? opportunity.Duration === durationFilter : true)
    );

    // Convert filtered array back to an object
    const filteredOpportunitiesObject = Object.fromEntries(filtered);
    setFilteredOpportunities(filteredOpportunitiesObject);
  }, [searchQuery, durationFilter, opportunities]);

  useEffect(() => {
    const fetchFinalizedVolunteerOpportunities = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(currentUser.uid);
          const doc = await userDocRef.get();

          if (doc.exists) {
            const userData = doc.data();
            if (userData.finalizedVolunteerOpportunities) {
              setFinalizedVolunteerOpportunities(userData.finalizedVolunteerOpportunities);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching finalized volunteer opportunities:', error);
      }
    };

    fetchFinalizedVolunteerOpportunities();
  }, [currentUser]);

  const addToFinalizedVolunteerOpportunities = async (userId, opportunityName) => {
    try {
      if (userId) {
        const userDocRef = db.collection('users').doc(userId);
        await userDocRef.update({
          finalizedVolunteerOpportunities: [...finalizedVolunteerOpportunities, opportunityName]
        });
        setFinalizedVolunteerOpportunities(prevFinalizedOpportunities => [
          ...prevFinalizedOpportunities,
          opportunityName
        ]);
      }
    } catch (error) {
      console.error('Error adding volunteer opportunity to finalized opportunities:', error);
    }
  };

  const handleAddToFinalizedVolunteerOpportunities = opportunityName => {
    addToFinalizedVolunteerOpportunities(currentUser.uid, opportunityName);
  };

  const removeFromFinalizedVolunteerOpportunities = async (userId, opportunityName) => {
    try {
      if (userId) {
        const userDocRef = db.collection('users').doc(userId);
        await userDocRef.update({
          finalizedVolunteerOpportunities: finalizedVolunteerOpportunities.filter(
            item => item !== opportunityName
          )
        });
        setFinalizedVolunteerOpportunities(prevFinalizedOpportunities =>
          prevFinalizedOpportunities.filter(item => item !== opportunityName)
        );
      }
    } catch (error) {
      console.error('Error removing volunteer opportunity from finalized opportunities:', error);
    }
  };

  const handleRemoveFromFinalizedVolunteerOpportunities = async opportunityName => {
    removeFromFinalizedVolunteerOpportunities(currentUser.uid, opportunityName);
  };

  return (
    <div>
      <NavBar />
      <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ flex: '0 0 20%', marginBottom: '20px' }}>
          <div className="card" style={{ padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4' }}>
            <h5 className="card-title">Filters</h5>
            <div>
              <label htmlFor="searchQuery">Search:</label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control mb-2"
              />
            </div>
          </div>
        </div>
  
        <div style={{ flex: '20 0 0%', width: '1000px', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
          <div className="card" style={{ width: '100%', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4', minHeight: '200px' }}>
            <h5 className="card-title">Ongoing Opportunities</h5>
            <div style={{ height: '500px', overflowY: 'auto', marginBottom: '0px' }}>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div>
                  {Object.entries(filteredOpportunities).map(([opportunityName, opportunity]) => (
                    <div key={opportunityName} style={{ border: '0px', background: '#b3d7ed', borderRadius: '0px', marginBottom: '10px', minWidth: '20px' }}>
                      <div className="opportunity-card" style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0px 0px 5px #999' }}>
                        <div className="opportunity-details">
                          <h3>{opportunity.Title}</h3>
                          <p>{"Description: " + opportunity.Description}</p>
                          <p>{"Organization: " + opportunity.Organization}</p>
                          <p>{"Location: " + opportunity.Location}</p>
                          {/* Include other details as needed */}
                        </div>
                        <button className="add-to-finalized-btn" onClick={() => handleAddToFinalizedVolunteerOpportunities(opportunityName)} style={{ width: '20%', padding: '8px', backgroundColor: '#4caf50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Sign Up</button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
  
        <div style={{ flex: '1', minWidth: '300px' }}>
          <div className="class-cart card" style={{ padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4' }}>
            <h5 className="card-title">My Volunteer Opportunities</h5>
            <div className="class-cart-items-container">
              {finalizedVolunteerOpportunities.length === 0 ? (
                <p>No finalized volunteer opportunities added</p>
              ) : (
                <div>
                  {/*{finalizedVolunteerOpportunities.map((opportunityName, index) => (
                    <div key={index} className="class-cart-item finalized-opportunity-item" style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0px 0px 5px #999' }}>
                      <p style={{ margin: '0' }}>{opportunityName}</p>
                      <button className="remove-from-finalized-btn" onClick={() => handleRemoveFromFinalizedVolunteerOpportunities(opportunityName)} style={{ width: '100%', padding: '8px', marginTop: '5px', backgroundColor: '#ff6347', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Remove</button>
                    </div>
                  ))}*/}
                  <VolunteerCart
                    Volunteers={finalizedVolunteerOpportunities}
                    onRemoveVolunteer={handleRemoveFromFinalizedVolunteerOpportunities}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerPage;
