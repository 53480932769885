// RequirementsSection.jsx
import React, { useState } from 'react';

const RequirementsSection = ({ requirements, setRequirements, setShowClassSearch }) => {
  const handleRemoveRequirement = (requirementToRemove) => {
    const updatedRequirements = requirements.filter(
      (requirement) => requirement.RequirementsName !== requirementToRemove.RequirementsName
    );
    setRequirements(updatedRequirements);
  };

  const handleAssignPossibilities = (index) => {
    setShowClassSearch('assign-requirement-possibilities', index);
  };

  const handleAddRequirement = () => {
    const newRequirement = {
      RequirementsName: '', // Now the user inputs this
      courseYear: '',
      CourseSem: '',
      Description: '',
      Possibilities: [], // Initialized as an empty array
      RequirementsDesc: '',
      courseType: 'Requirement', 
    };
    setRequirements([...requirements, newRequirement]);
  };

  const handleRemovePossibility = (requirementIndex, possibilityIndex) => {
    const updatedRequirements = [...requirements];
    if (
      Array.isArray(updatedRequirements[requirementIndex].Possibilities) &&
      updatedRequirements[requirementIndex].Possibilities.length > possibilityIndex
    ) {
      updatedRequirements[requirementIndex].Possibilities.splice(possibilityIndex, 1);
      setRequirements(updatedRequirements);
    }
  };

  // For dropdown
  const [openDropdown, setOpenDropdown] = useState(null); // To track which dropdown is open

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2"></div>
      {requirements.length === 0 ? (
        <p>No requirements currently included</p>
      ) : (
        <ul className="space-y-4">
          {requirements.map((requirement, index) => (
            <li key={index} className="p-4 bg-gray-100 rounded-lg shadow">
              <div className="flex justify-between items-center">
                <span className="font-medium">
                  {requirement.RequirementsName || 'No requirement name entered'}
                </span>
                <div className="flex space-x-2">
                  {/* Assign Possibilities Button */}
                  {requirement.RequirementsName && (
                    <button
                      type="button"
                      onClick={() => handleAssignPossibilities(index)}
                      className="bg-purple-600 hover:bg-purple-700 text-white px-2 py-1 rounded transition-colors"
                    >
                      Assign Possibilities
                    </button>
                  )}

                  {/* "Remove" button is always rendered */}
                  <button
                    type="button"
                    onClick={() => handleRemoveRequirement(requirement)}
                    className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 transition-colors"
                  >
                    Remove
                  </button>
                </div>
              </div>

              {/* Requirement Details */}
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Requirement Name
                  </label>
                  <input
                    type="text"
                    value={requirement.RequirementsName}
                    onChange={(e) => {
                      const updatedRequirements = [...requirements];
                      updatedRequirements[index].RequirementsName = e.target.value;
                      setRequirements(updatedRequirements);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                    placeholder="Enter requirement name"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Requirement Description
                  </label>
                  <input
                    type="text"
                    value={requirement.RequirementsDesc}
                    onChange={(e) => {
                      const updatedRequirements = [...requirements];
                      updatedRequirements[index].RequirementsDesc = e.target.value;
                      setRequirements(updatedRequirements);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                    placeholder="Enter requirement description"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Course Year
                  </label>
                  <select
                    value={requirement.courseYear}
                    onChange={(e) => {
                      const updatedRequirements = [...requirements];
                      updatedRequirements[index].courseYear = e.target.value;
                      setRequirements(updatedRequirements);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Select Course Year
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Semester
                  </label>
                  <select
                    value={requirement.CourseSem}
                    onChange={(e) => {
                      const updatedRequirements = [...requirements];
                      updatedRequirements[index].CourseSem = e.target.value;
                      setRequirements(updatedRequirements);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Select Semester
                    </option>
                    <option value="S1">S1</option>
                    <option value="S2">S2</option>
                  </select>
                </div>
              </div>

              {/* Possibilities Dropdown */}
              {requirement.RequirementsName && (
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Possibilities
                  </label>
                  <div className="mt-1 relative">
                    <button
                      type="button"
                      onClick={() => toggleDropdown(index)}
                      className="w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    >
                      {requirement.Possibilities.length > 0
                        ? `${requirement.Possibilities.length} Possibility(ies)`
                        : 'No Possibilities Assigned'}
                    </button>
                    {openDropdown === index && (
                      <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {requirement.Possibilities.length === 0 ? (
                          <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                            No possibilities assigned.
                          </div>
                        ) : (
                          requirement.Possibilities.map((possibility, pIndex) => (
                            <div
                              key={`${possibility.courseInfo_courseNumber}-${pIndex}`}
                              className="flex justify-between items-center cursor-pointer select-none relative py-2 px-4 text-gray-700 hover:bg-gray-100"
                            >
                              <span>{possibility.courseInfo_courseNumber}</span>
                              <button
                                type="button"
                                onClick={() => handleRemovePossibility(index, pIndex)}
                                className="text-red-600 hover:text-red-800"
                              >
                                Remove
                              </button>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddRequirement}
        className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Add Requirement
      </button>
    </div>
  );
};

export default RequirementsSection;
