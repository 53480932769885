import React, { useState } from 'react';
import { getDatabase, ref, set, push } from 'firebase/database';
import CustomSidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const AddCourse = () => {
  const navigate = useNavigate(); // Initialize the navigate hook

  const [newCourse, setNewCourse] = useState({
    courseInfo_courseNumber: '',
    courseInfo_courseName: '',
    Credits: 0,
    sch_name: '',
    courseYear: '',
    CourseSem: '',
    Major: '',
    Mode: '',
    Notes: '',
    Instructor: '',
    Offering: '',
    "RecSTEM-HSS": 0,
    "RecProject-Test": 0,
    "RecInPerson-Online": 0,
    "RecStructured-Flexible": 0,
    "RecSmall-LargeClass": 0,
    "RecApplied-Theoretical": 0,
    "RecCollaborative-Individual": 0,
  });

  const handleInputChange = (e, field) => {
    setNewCourse({ ...newCourse, [field]: e.target.value });
  };

  const handleIndexInputChange = (e, field) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && Number.isInteger(value) && value >= -5 && value <= 5) {
      setNewCourse({ ...newCourse, [field]: value });
    } else {
      alert("Input must be an integer between -5 and 5");
    }
  };

  const addNewCourse = async () => {
    const db = getDatabase();
    const courseRef = ref(db, `/${process.env.REACT_APP_PARENT_SHEET}/${process.env.REACT_APP_COURSES_SHEET}`);
    
    console.log('course ref: ', `/${process.env.REACT_APP_PARENT_SHEET}/${process.env.REACT_APP_COURSES_SHEET}`);
  
    // Log the payload for debugging
    console.log('Payload:', newCourse);
  
    try {
      // Use push() to generate a unique key for the new course
      const newCourseRef = push(courseRef);
      await set(newCourseRef, newCourse);
  
      setNewCourse({
        courseInfo_courseNumber: '',
        courseInfo_courseName: '',
        Credits: 0,
        sch_name: '',
        courseYear: '',
        CourseSem: '',
        Major: '',
        Mode: '',
        Notes: '',
        Instructor: '',
        Offering: '',
        "RecSTEM-HSS": 0,
        "RecProject-Test": 0,
        "RecInPerson-Online": 0,
        "RecStructured-Flexible": 0,
        "RecSmall-LargeClass": 0,
        "RecApplied-Theoretical": 0,
        "RecCollaborative-Individual": 0,
      });
      console.log('New course added successfully');
    } catch (error) {
      console.error('Error adding new course:', error);
    }
  };

  const resetForm = () => {
    setNewCourse({
      courseInfo_courseNumber: '',
      courseInfo_courseName: '',
      Credits: 0,
      sch_name: '',
      courseYear: '',
      CourseSem: '',
      Major: '',
      Mode: '',
      Notes: '',
      Instructor: '',
      Offering: '',
      "RecSTEM-HSS": 0,
      "RecProject-Test": 0,
      "RecInPerson-Online": 0,
      "RecStructured-Flexible": 0,
      "RecSmall-LargeClass": 0,
      "RecApplied-Theoretical": 0,
      "RecCollaborative-Individual": 0,
    });
  };

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64 p-6">
        <div className="fixed top-3.5 right-0 w-full bg-white dark:bg-gray-800 z-10" style={{ width: 'calc(100% - 16rem)' }}>
          <div className="flex justify-center">
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-full mx-auto">
            <div className="mt-2">
              <h3 className="text-lg font-semibold mb-2 text-center">Add New Course</h3>
              <div className="flex justify-start">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-4"
                  onClick={() => navigate(-1)} // Go back to the previous page
                >
                  Back
                </button>
              </div>
              <div className="mt-4">
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <div className="text-xs font-bold">Course Number</div>
                    <input
                      type="text"
                      placeholder="Course Number"
                      value={newCourse.courseInfo_courseNumber}
                      onChange={(e) => handleInputChange(e, 'courseInfo_courseNumber')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Course Name</div>
                    <input
                      type="text"
                      placeholder="Course Name"
                      value={newCourse.courseInfo_courseName}
                      onChange={(e) => handleInputChange(e, 'courseInfo_courseName')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Credits</div>
                    <input
                      type="number"
                      placeholder="Credits"
                      value={newCourse.Credits}
                      onChange={(e) => handleInputChange(e, 'Credits')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">School Name</div>
                    <input
                      type="text"
                      placeholder="School Name"
                      value={newCourse.sch_name}
                      onChange={(e) => handleInputChange(e, 'sch_name')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Course Year</div>
                    <input
                      type="text"
                      placeholder="Course Year"
                      value={newCourse.courseYear}
                      onChange={(e) => handleInputChange(e, 'courseYear')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Course Semester</div>
                    <input
                      type="text"
                      placeholder="Course Semester"
                      value={newCourse.CourseSem}
                      onChange={(e) => handleInputChange(e, 'CourseSem')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Major</div>
                    <input
                      type="text"
                      placeholder="Major"
                      value={newCourse.Major}
                      onChange={(e) => handleInputChange(e, 'Major')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Mode</div>
                    <input
                      type="text"
                      placeholder="Mode"
                      value={newCourse.Mode}
                      onChange={(e) => handleInputChange(e, 'Mode')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className='col-span-2'>
                    <div className="text-xs font-bold">Notes</div>
                    <textarea
                      placeholder="Notes"
                      value={newCourse.Notes}
                      onChange={(e) => handleInputChange(e, 'Notes')}
                      className="p-2 border border-gray-300 rounded w-full"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Instructor</div>
                    <input
                      type="text"
                      placeholder="Instructor"
                      value={newCourse.Instructor}
                      onChange={(e) => handleInputChange(e, 'Instructor')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Offering</div>
                    <input
                      type="text"
                      placeholder="Offering"
                      value={newCourse.Offering}
                      onChange={(e) => handleInputChange(e, 'Offering')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">STEM/HSS Index</div>
                    <input
                      type="number"
                      placeholder="STEM/HSS Index"
                      value={newCourse["RecSTEM-HSS"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecSTEM-HSS')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Project/Test Index</div>
                    <input
                      type="number"
                      placeholder="Project/Test Index"
                      value={newCourse["RecProject-Test"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecProject-Test')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">In-Person/Online Index</div>
                    <input
                      type="number"
                      placeholder="In-Person/Online Index"
                      value={newCourse["RecInPerson-Online"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecInPerson-Online')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Structured/Flexible Index</div>
                    <input
                      type="number"
                      placeholder="Structured/Flexible Index"
                      value={newCourse["RecStructured-Flexible"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecStructured-Flexible')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Small/Large Class Index</div>
                    <input
                      type="number"
                      placeholder="Small/Large Class Index"
                      value={newCourse["RecSmall-LargeClass"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecSmall-LargeClass')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Applied/Theoretical Index</div>
                    <input
                      type="number"
                      placeholder="Applied/Theoretical Index"
                      value={newCourse["RecApplied-Theoretical"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecApplied-Theoretical')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-bold">Collaborative/Individual Index</div>
                    <input
                      type="number"
                      placeholder="Collaborative/Individual Index"
                      value={newCourse["RecCollaborative-Individual"]}
                      onChange={(e) => handleIndexInputChange(e, 'RecCollaborative-Individual')}
                      className="p-2 border border-gray-300 rounded"
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 mt-2 text-white font-bold py-2 px-4 rounded"
                    onClick={addNewCourse}
                  >
                    Add Course
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 mt-2 text-white font-bold py-2 px-4 rounded ml-2"
                    onClick={resetForm}
                  >
                    Reset Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
