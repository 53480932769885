import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag } from '@fortawesome/free-solid-svg-icons';
import '../../index.css';

function SettingsPage() {
    const [studentData, setStudentData] = useState(null);

    useEffect(() => {
        fetch('/studentData.json')
            .then(response => response.json())
            .then(data => setStudentData(data))
            .catch(error => console.error('Error loading the student data:', error));
    }, []);

    if (!studentData) return <div className="text-center p-4">Loading...</div>;

    const totalCourses = studentData.courses.taken + studentData.courses.registered + studentData.courses.remaining;
    const progressTaken = Math.round((studentData.courses.taken / totalCourses) * 100);
    const progressRegistered = Math.round((studentData.courses.registered / totalCourses) * 100);
    const totalProgress = progressTaken + progressRegistered;
    const milestoneProgress = Math.round((studentData.milestones.taken / (studentData.milestones.taken + studentData.milestones.remaining)) * 100);

    return (
        <div>
            <NavBar />
            <div className="profile-container">
                <div className="profile-header">
                    <img className="profile-pic" src="/simba.jpg" alt="Profile Picture" />
                    <div className="student-info">
                        <h1 className="info-title mb-1">{studentData.name}</h1>
                        <p className="text-sm mb-1">{studentData.email}</p>
                        <p className="text-sm mb-1">{studentData.role}</p>
                        <p className="text-sm"><FontAwesomeIcon icon={faBook} /> {studentData.degree}</p>
                        <p className="text-sm mb-1">Concentration: {studentData.concentration.name} <span className={`inline-block px-1 py-1 bg-gray-200 text-xs rounded-md ${studentData.concentration.declared ? 'text-green-700' : 'text-red-700'}`}>{studentData.declared ? 'Declared' : 'Not Declared'}</span></p>
                        <p className="text-sm mb-1"><FontAwesomeIcon icon={faBook} /> Minor in {studentData.minor}</p>
                        <p className="text-sm mb-1"><FontAwesomeIcon icon={faMapMarkerAlt} /> {studentData.city}</p>
                        <p className="text-sm mb-1"><FontAwesomeIcon icon={faGraduationCap} /> {studentData.startYear} - {studentData.endYear}</p>
                        <p className="text-sm mb-1" style={{ whiteSpace: 'nowrap' }}>{studentData.tags.map((tag, index) => (<span key={index} className="flex items-center" style={{ display: 'inline-block', marginRight: '10px' }}><FontAwesomeIcon icon={faTag} className="mr-1" />{tag}&nbsp;</span>))}</p>
                    </div>
                </div>
                <div className="progress-bars">
                    <div className="course-progress mb-4">
                    <h4 className = "mb-1">Course Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 relative">
                            <div className="bg-green-500 h-2.5 rounded-full" style={{ width: `${progressTaken}%` }}></div>
                            <div className="bg-yellow-500 h-2.5 rounded-full absolute" style={{ width: `${progressRegistered}%`, left: `${progressTaken}%`, top:0 }}></div>
                        </div>
                    <div className="flex justify-between mt-2">
                        <div className="bg-green-100  px-2 py-0.3">
                            <span className="text-green-600 font-semibold">{studentData.courses.taken} Taken</span>
                        </div>        
                        <div className="bg-yellow-100  px-2 py-0.3 ml-3">
                            <span className="text-yellow-500 font-semibold">{studentData.courses.registered} Registered</span>
                        </div>
                        <div className="bg-gray-100  px-2 py-0.3 ml-3">
                            <span className="text-gray-600 font-semibold">{studentData.courses.remaining} Remaining</span>
                        </div>
                    </div>                    
                    </div>
                    <div className="milestone-progress">
                        <h4 className = "mb-2">Milestone Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress}%` }}></div>
                        </div>
                        <div className="flex justify-between mt-2">
                        <div className="bg-blue-100  px-2 py-0.3">
                            <span className="text-blue-600 font-semibold">{studentData.milestones.taken} Completed</span>
                        </div>        
                        <div className="bg-gray-100  px-2 py-0.3 ml-3">
                            <span className="text-gray-500 font-semibold">{studentData.milestones.remaining} Remaining</span>
                        </div>
                    </div>  
                    </div>
                </div>
                <div className="details">
                <div className="flex flex-col">
                    <div>
                        <h2 className="text-gray-500 font-semibold mb-3">Advisors</h2>
                    </div>
                    <div className="flex">
                        <div>
                            <img className="profile-pic mr-4" src="https://talkjs.com/images/avatar-1.jpg" alt="Profile Picture" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                        </div>
                        <div className="advisors">
                            {studentData.advisors.map(advisor => (
                                <p key={advisor.name} className="ml-2"> <span className="font-semibold">{advisor.name}</span> <span className="bg-gray-200 px-1.5 py-1 text-xs rounded-lg font-semibold mb-3">{advisor.role}</span> <br /> <span className="text-sm mb-1">{advisor.email}</span> <br/> <span className="text-sm">{advisor.department}</span> </p>                            ))}
                        </div>
                    </div>
                </div>
                    <div className="grades">
                        <h2 className="text-gray-500 font-semibold mb-2">Grades</h2>
                        <p><span className="text-gray-700 font-semibold">Current GPA: {studentData.grades.currentGPA}</span></p>
                        <p><span className="text-gray-700 font-semibold">Cumulative GPA: {studentData.grades.cumulativeGPA}</span></p>
                    </div>
                    <div className="interests">
                        <h2 className="text-gray-500 font-semibold mb-2">Interests</h2>
                        <div className="flex flex-wrap">
                            {studentData.interests.map((interest, index) => (
                                <span key={index} className="bg-gray-200 text-gray-700 rounded-lg px-1.5 py-0.5 mr-2 mb-2">{interest}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="schedule-card">
                    <h2 className="text-gray-500 font-semibold mb-2 p-2">Current Schedule ({studentData.Schedule.year}) - Credits: {studentData.Schedule.credits}</h2>
                    <ul>
                        {studentData.Schedule.classes.map((course, index) => (
                            <div key={index} className="bg-gray-200 p-1.5  mb-2 rounded-lg" style={{ display: 'inline-block', marginRight: '10px' }}>
                                <li>{course}</li>
                            </div>
                        ))}
                    </ul>
                        </div>
                    </div>
                </div>
    );
}

export default SettingsPage;