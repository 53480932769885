// src/components/Advisor/AdvisorAvailabilityCalendar.jsx

import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // For month view
import timeGridPlugin from '@fullcalendar/timegrid'; // For week/day view
import interactionPlugin from '@fullcalendar/interaction'; // For dateClick and eventClick
import { db } from '../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { FaPlus } from 'react-icons/fa'; // Import FaPlus for the add button
import firebase from 'firebase/compat/app'; // Ensure compatibility
import { toast } from 'react-toastify';
import { RRule } from 'rrule';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox, Button } from 'flowbite-react'; // Assuming you're using Flowbite for UI components
import TimeRangePicker from './TimeRangePicker'; // Import the TimeRangePicker component
import { v4 as uuidv4 } from 'uuid'; // Install 'uuid' library for unique IDs
import { addMonths, format } from 'date-fns'; // Import format from date-fns

const AdvisorAvailabilityCalendar = () => {
  const { currentUser } = useAuth();
  const calendarRef = useRef(null);
  
  // State for managing events
  const [availabilityEvents, setAvailabilityEvents] = useState([]);
  const [meetingEvents, setMeetingEvents] = useState([]);
  const [combinedEvents, setCombinedEvents] = useState([]);
  
  // State for Add Availability Modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState(null); // Store as Date object
  const [modalStartTime, setModalStartTime] = useState('');
  const [modalEndTime, setModalEndTime] = useState('');
  
  // State for Delete Modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  // Recurrence states
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceDays, setRecurrenceDays] = useState([]); // e.g., ['MO', 'WE']
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  
  // Loading state for adding slot
  const [isAdding, setIsAdding] = useState(false);
  
  // Form validation error
  const [formError, setFormError] = useState('');
  
  // Fetch availability from Firestore
  useEffect(() => {
    if (!currentUser) return;
    
    const unsubscribe = db
      .collection('availability')
      .doc(currentUser.uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const availableSlots = data.availableSlots || [];
          let formattedEvents = [];
          const now = new Date();

          // Filter out booked slots
          const filteredSlots = availableSlots.filter(slot => !slot.isBooked);

          filteredSlots.forEach((slot) => {
            // Decompose the date and time to create local Date objects
            const [year, month, day] = slot.date.split('-').map(Number);
            const [startHour, startMinute] = slot.startTime.split(':').map(Number);
            const [endHour, endMinute] = slot.endTime.split(':').map(Number);

            const eventStart = new Date(year, month - 1, day, startHour, startMinute);
            const eventEnd = new Date(year, month - 1, day, endHour, endMinute);

            if (eventEnd > now) {
              formattedEvents.push({
                id: slot.id,
                title: 'Available',
                start: eventStart.toISOString(),
                end: eventEnd.toISOString(),
                backgroundColor: '#28a745', // Green if available
                borderColor: '#28a745',
                extendedProps: {
                  isBooked: slot.isBooked,
                  recurrenceId: slot.recurrenceId || null, // Include recurrenceId if exists
                  isMeeting: false, // Availability slot
                },
              });
            }
          });

          setAvailabilityEvents(formattedEvents);
        } else {
          setAvailabilityEvents([]);
        }
      }, (error) => {
        console.error('Error fetching availability:', error);
        toast.error('Failed to fetch availability. Please try again.');
      });
    
    return () => unsubscribe();
  }, [currentUser]);

  // Fetch meetings from Firestore
  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = db
      .collection('meetings')
      .where('advisorId', '==', currentUser.uid)
      .where('status', '==', 'Scheduled') // Fetch only scheduled meetings
      .onSnapshot((snapshot) => {
        const meetingsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          const start = data.datetime ? data.datetime.toDate() : null;
          const end = start ? new Date(start.getTime() + data.meetingDuration * 60000) : null; // Calculate end time based on duration
          return {
            id: doc.id,
            title: `Meeting with ${data.studentName || 'Student'}`,
            start: start ? start.toISOString() : null,
            end: end ? end.toISOString() : null,
            backgroundColor: '#007bff', // Blue color for meetings
            borderColor: '#007bff',
            extendedProps: {
              isMeeting: true,
            },
          };
        }).filter(event => event.start && event.end); // Ensure valid dates

        setMeetingEvents(meetingsData);
      }, (error) => {
        console.error('Error fetching meetings:', error);
        toast.error('Failed to fetch meetings. Please try again.');
      });

    return () => unsubscribe();
  }, [currentUser]);

  // Combine availability and meetings events
  useEffect(() => {
    setCombinedEvents([...availabilityEvents, ...meetingEvents]);
  }, [availabilityEvents, meetingEvents]);

  // Handler for Add Button Click
  const handleAddButtonClick = () => {
    const nextInterval = getNext15MinuteInterval();
    const defaultStartTime = `${String(nextInterval.getHours()).padStart(2, '0')}:${String(nextInterval.getMinutes()).padStart(2, '0')}`;

    // Calculate end time as one hour after start time
    const endDate = new Date(nextInterval.getTime() + 60 * 60 * 1000); // Add one hour in milliseconds
    const defaultEndTime = `${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(2, '0')}`;

    // Initialize modalDate as a Date object representing today
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight

    // Check if default times are within 9 AM - 5 PM
    const [startHour, startMinute] = defaultStartTime.split(':').map(Number);
    const [endHour, endMinute] = defaultEndTime.split(':').map(Number);

    const startTotal = startHour * 60 + startMinute;
    const endTotal = endHour * 60 + endMinute;

    if (startTotal < 9 * 60 || endTotal > 17 * 60) {
      toast.error('Cannot add availability outside of 9:00 AM - 5:00 PM.');
      return;
    }

    setModalDate(new Date(today)); // Use Date object
    setModalStartTime(defaultStartTime);
    setModalEndTime(defaultEndTime);
    setIsRecurring(false);
    setRecurrenceDays([]);
    setRecurrenceEndDate(null);
    setFormError('');
    setIsAddModalOpen(true);
  };
  
  // Helper function to get the next 15-minute interval
  const getNext15MinuteInterval = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    const remainder = minutes % 15;
    const minutesToAdd = remainder === 0 ? 15 : 15 - remainder;

    now.setMinutes(minutes + minutesToAdd);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
  };
  
  // Handle date and time range selection to add availability
  const handleSelect = (selectionInfo) => {
    const { start, end, allDay } = selectionInfo;

    const now = new Date();

    if (allDay) {
      // Use start date directly
      const selectedDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      
      // Prevent selecting past dates
      const selectedStartDate = new Date(selectedDate);
      selectedStartDate.setHours(0, 0, 0, 0);
      const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      if (selectedStartDate < todayStart) {
        toast.error('Cannot add availability in the past.');
        return;
      }

      setModalDate(selectedDate);
      setModalStartTime('');
      setModalEndTime('');
    } else {
      const selectedStart = new Date(start);
      const selectedEnd = new Date(end);

      // Prevent selecting past times
      if (selectedEnd <= now) {
        toast.error('Cannot add availability in the past.');
        return;
      }

      // Ensure selected times are within 9 AM - 5 PM
      const startHour = selectedStart.getHours();
      const endHour = selectedEnd.getHours();
      const startTotal = startHour * 60 + selectedStart.getMinutes();
      const endTotal = endHour * 60 + selectedEnd.getMinutes();

      if (startTotal < 9 * 60 || endTotal > 17 * 60 || endTotal <= startTotal) {
        toast.error('Availability must be between 9:00 AM and 5:00 PM with end time after start time.');
        return;
      }

      // Use selectedStart's local date
      const selectedDate = new Date(selectedStart.getFullYear(), selectedStart.getMonth(), selectedStart.getDate());

      const formattedStartTime = `${String(startHour).padStart(2, '0')}:${String(selectedStart.getMinutes()).padStart(2, '0')}`;
      const formattedEndTime = `${String(endHour).padStart(2, '0')}:${String(selectedEnd.getMinutes()).padStart(2, '0')}`;

      setModalDate(selectedDate);
      setModalStartTime(formattedStartTime);
      setModalEndTime(formattedEndTime);
    }

    // Reset recurrence settings
    setIsRecurring(false);
    setRecurrenceDays([]);
    setRecurrenceEndDate(null);

    // Clear any previous form errors
    setFormError('');

    // Open the Add Modal
    setIsAddModalOpen(true);
  };
  
  // Handle event click to open Delete Modal
  const handleEventClick = (arg) => {
    const event = arg.event;
    
    // If the event is a meeting, allow deletion
    if (event.extendedProps.isMeeting) {
      setSelectedEvent(event);
      setIsDeleteModalOpen(true);
    } else if (event.extendedProps.isBooked) {
      toast.info('This slot is already booked and cannot be modified.');
      return;
    } else {
      // Availability slot
      setSelectedEvent(event);
      setIsDeleteModalOpen(true);
    }
  };
  
  // Add new availability slot to Firestore
  const addAvailabilitySlot = async () => {
    // Clear any previous form errors
    setFormError('');

    if (!modalDate) {
      toast.error('Please select a date.');
      return;
    }

    // If the selection included times, validate them
    if (modalStartTime && modalEndTime) {
      // Validate that endTime is after startTime
      const [startHour, startMinute] = modalStartTime.split(':').map(Number);
      const [endHour, endMinute] = modalEndTime.split(':').map(Number);
      const startTotal = startHour * 60 + startMinute;
      const endTotal = endHour * 60 + endMinute;

      if (endTotal <= startTotal) {
        toast.error('End time must be after start time.');
        return;
      }

      // Ensure times are within 9 AM - 5 PM
      if (startTotal < 9 * 60 || endTotal > 17 * 60) {
        toast.error('Availability slots must be between 9:00 AM and 5:00 PM.');
        return;
      }

      // Decompose modalDate and modalStartTime to create local Date objects
      const year = modalDate.getFullYear();
      const month = modalDate.getMonth();
      const day = modalDate.getDate();
      const slotStartDateTime = new Date(year, month, day, startHour, startMinute);
      const slotEndDateTime = new Date(year, month, day, endHour, endMinute);

      const now = new Date();
      if (slotStartDateTime <= now) {
        toast.error('Cannot add availability in the past.');
        return;
      }
    }

    // If recurring, ensure recurrenceEndDate is set
    let finalRecurrenceEndDate = recurrenceEndDate;
    if (isRecurring) {
      if (!recurrenceEndDate) {
        // Automatically set it to 6 months from modalDate
        const defaultEndDate = addMonths(modalDate, 6);
        finalRecurrenceEndDate = defaultEndDate;

        // Notify the user
        toast.info(`No end date selected. Setting end date to ${format(defaultEndDate, 'yyyy-MM-dd')}.`);
      }
    }

    // Check for overlapping slots on the selected date
    const formattedModalDate = format(modalDate, 'yyyy-MM-dd'); // Format as 'YYYY-MM-DD'

    const existingSlots = availabilityEvents.filter(event => {
      return event.start.startsWith(formattedModalDate);
    });

    let isOverlap = false;

    if (modalStartTime && modalEndTime) {
      const [startHour, startMinute] = modalStartTime.split(':').map(Number);
      const [endHour, endMinute] = modalEndTime.split(':').map(Number);
      const startTotal = startHour * 60 + startMinute;
      const endTotal = endHour * 60 + endMinute;

      isOverlap = existingSlots.some(event => {
        const existingStart = event.start.slice(11, 16); // 'HH:MM'
        const existingEnd = event.end.slice(11, 16);

        const existingStartTotal = parseInt(existingStart.split(':')[0], 10) * 60 + parseInt(existingStart.split(':')[1], 10);
        const existingEndTotal = parseInt(existingEnd.split(':')[0], 10) * 60 + parseInt(existingEnd.split(':')[1], 10);

        return (
          (startTotal >= existingStartTotal && startTotal < existingEndTotal) ||
          (endTotal > existingStartTotal && endTotal <= existingEndTotal) ||
          (startTotal <= existingStartTotal && endTotal >= existingEndTotal)
        );
      });
    }

    if (isOverlap) {
      toast.error('This time slot overlaps with an existing availability.');
      return;
    }

    setIsAdding(true);

    try {
      const docRef = db.collection('availability').doc(currentUser.uid);
      const doc = await docRef.get();

      if (doc.exists) {
        if (isRecurring) {
          // Handle recurring slots by creating separate entries with a common recurrenceId
          const recurrenceId = uuidv4(); // Generate a unique recurrenceId
          
          // Generate recurrence dates without time to prevent DST shifts
          const rrule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: recurrenceDays.map(day => RRule[day]),
            dtstart: new Date(modalDate.getFullYear(), modalDate.getMonth(), modalDate.getDate(), 0, 0, 0), // Start at midnight local time
            until: finalRecurrenceEndDate ? finalRecurrenceEndDate : addMonths(modalDate, 6),
          });

          const dates = rrule.all();

          const newSlots = dates.map(date => {
            const dateStr = format(date, 'yyyy-MM-dd'); // 'YYYY-MM-DD'
            return {
              date: dateStr,
              startTime: modalStartTime, // Fixed start time or empty
              endTime: modalEndTime,     // Fixed end time or empty
              isBooked: false,
              id: `${dateStr}-${modalStartTime}-${uuidv4()}`, // Unique ID
              recurrenceId: recurrenceId, // Link to recurrence group
            };
          });

          // Filter out slots without times (if any)
          const filteredNewSlots = newSlots.filter(slot => slot.startTime && slot.endTime);

          // Add all newSlots to Firestore
          await docRef.update({
            availableSlots: firebase.firestore.FieldValue.arrayUnion(...filteredNewSlots),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        } else {
          // Handle single occurrence slot
          if (!modalStartTime || !modalEndTime) {
            toast.error('Please select a time range for the availability slot.');
            setIsAdding(false);
            return;
          }

          const newSlot = {
            date: formattedModalDate,
            startTime: modalStartTime,
            endTime: modalEndTime,
            isBooked: false,
            id: `${formattedModalDate}-${modalStartTime}-${uuidv4()}`, // Unique ID
            recurrenceId: null, // Not part of a recurrence group
          };

          await docRef.update({
            availableSlots: firebase.firestore.FieldValue.arrayUnion(newSlot),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      } else {
        // If the document doesn't exist, create it
        if (isRecurring) {
          const recurrenceId = uuidv4(); // Generate a unique recurrenceId
          
          // Generate recurrence dates without time to prevent DST shifts
          const rrule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: recurrenceDays.map(day => RRule[day]),
            dtstart: new Date(modalDate.getFullYear(), modalDate.getMonth(), modalDate.getDate(), 0, 0, 0), // Start at midnight local time
            until: finalRecurrenceEndDate ? finalRecurrenceEndDate : addMonths(modalDate, 6),
          });

          const dates = rrule.all();

          const newSlots = dates.map(date => {
            const dateStr = format(date, 'yyyy-MM-dd'); // 'YYYY-MM-DD'
            return {
              date: dateStr,
              startTime: modalStartTime, // Fixed start time or empty
              endTime: modalEndTime,     // Fixed end time or empty
              isBooked: false,
              id: `${dateStr}-${modalStartTime}-${uuidv4()}`, // Unique ID
              recurrenceId: recurrenceId, // Link to recurrence group
            };
          });

          // Filter out slots without times (if any)
          const filteredNewSlots = newSlots.filter(slot => slot.startTime && slot.endTime);

          await docRef.set({
            advisorId: currentUser.uid,
            advisorName: `${currentUser.firstName} ${currentUser.lastName}`,
            availableSlots: filteredNewSlots,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        } else {
          if (!modalStartTime || !modalEndTime) {
            toast.error('Please select a time range for the availability slot.');
            setIsAdding(false);
            return;
          }

          const newSlot = {
            date: format(modalDate, 'yyyy-MM-dd'), // Format as 'YYYY-MM-DD'
            startTime: modalStartTime,
            endTime: modalEndTime,
            isBooked: false,
            id: `${format(modalDate, 'yyyy-MM-dd')}-${modalStartTime}-${uuidv4()}`, // Unique ID
            recurrenceId: null, // Not part of a recurrence group
          };

          await docRef.set({
            advisorId: currentUser.uid,
            advisorName: `${currentUser.firstName} ${currentUser.lastName}`,
            availableSlots: [newSlot],
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      }

      toast.success('Availability slot(s) added successfully!');
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding availability slot:', error);
      toast.error('Failed to add availability slot(s). Please try again.');
    } finally {
      setIsAdding(false);
    }
  };
  
  // Delete single availability slot from Firestore
  const deleteAvailabilitySlot = async (slotId) => {
    try {
      const docRef = db.collection('availability').doc(currentUser.uid);
      const doc = await docRef.get();
      
      if (doc.exists) {
        const updatedSlots = doc.data().availableSlots.filter(slot => slot.id !== slotId);
        await docRef.update({
          availableSlots: updatedSlots,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        toast.success('Availability slot deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting availability slot:', error);
      toast.error('Failed to delete availability slot. Please try again.');
    }
  };
  
  // Delete all slots with the same recurrenceId
  const deleteRecurringSlots = async (recurrenceId) => {
    try {
      const docRef = db.collection('availability').doc(currentUser.uid);
      const doc = await docRef.get();
      
      if (doc.exists) {
        const updatedSlots = doc.data().availableSlots.filter(slot => slot.recurrenceId !== recurrenceId);
        await docRef.update({
          availableSlots: updatedSlots,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        toast.success('All related recurring availability slots deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting recurring availability slots:', error);
      toast.error('Failed to delete recurring availability slots. Please try again.');
    }
  };

  // Delete a meeting from Firestore
  const deleteMeeting = async (meetingId) => {
    try {
      const meetingDoc = await db.collection('meetings').doc(meetingId).get();
      if (!meetingDoc.exists) {
        toast.error('Meeting does not exist.');
        return;
      }

      const meetingData = meetingDoc.data();
      const linkedSlotId = meetingData.slotId;

      // Delete the meeting document
      await db.collection('meetings').doc(meetingId).delete();

      // Update the corresponding availability slot to mark it as not booked
      if (linkedSlotId) {
        const availabilityDocRef = db.collection('availability').doc(currentUser.uid);
        const availabilityDoc = await availabilityDocRef.get();
        if (availabilityDoc.exists) {
          const availableSlots = availabilityDoc.data().availableSlots || [];
          const updatedSlots = availableSlots.map(slot => 
            slot.id === linkedSlotId ? { ...slot, isBooked: false } : slot
          );
          await availabilityDocRef.update({
            availableSlots: updatedSlots,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      }

      toast.success('Meeting canceled and slot is now available!');
    } catch (error) {
      console.error('Error deleting meeting:', error);
      toast.error('Failed to cancel meeting. Please try again.');
    }
  };
  
  // Customize event rendering for better visuals and full clickable area
  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const start = new Date(event.start);
    const end = new Date(event.end);
    
    // Format the start and end times
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const startTime = start.toLocaleTimeString([], options);
    const endTime = end.toLocaleTimeString([], options);
    
    return (
      <div className="w-full h-full flex flex-col justify-center items-start pointer-events-auto">
        <div className="text-sm font-medium">
          {startTime} - {endTime}
        </div>
        <div className="text-xs">
          {event.title}
        </div>
      </div>
    );
  };
  
  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md relative">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Your Availability Calendar</h2>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek" // Display week view with time slots
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        selectable={true} // Enable date and time selection
        selectMirror={true}
        dayMaxEvents={true}
        weekends={false} // Hide weekends
        selectAllow={(selectInfo) => {
          const today = new Date();
          const start = selectInfo.start;
          return start >= today.setHours(0, 0, 0, 0);
        }}
        select={handleSelect} // Handle date/time selection
        events={combinedEvents}
        ref={calendarRef}
        eventClick={handleEventClick} // Handle event clicks
        height="auto"
        editable={false} // Disable drag and drop
        eventOverlap={false} // Prevent overlapping events
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
          startTime: '09:00',
          endTime: '17:00',
        }}
        slotMinTime="09:00:00"
        slotMaxTime="17:00:00"
        allDaySlot={false}
        slotDuration="00:15:00" // 15-minute increments
        nowIndicator={true}
        scrollTime="09:00:00"
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }}
        eventContent={renderEventContent} // Custom event rendering
        timeZone="local" // Ensure FullCalendar uses local timezone
      />
      
      {/* Floating Add Availability Button */}
      <button
        type="button"
        className="fixed bottom-6 right-6 bg-blue-600 text-white rounded-full p-4 shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={handleAddButtonClick}
        aria-label="Add Availability"
      >
        <FaPlus size={20} />
      </button>
      
      {/* Add Availability Modal */}
      {isAddModalOpen && (
        <div
          id="add-availability-modal"
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out"
        >
          <div className="relative p-4 w-full max-w-md max-h-2xl">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Add Availability Slot
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsAddModalOpen(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form onSubmit={(e) => { e.preventDefault(); addAvailabilitySlot(); }} className="space-y-4">
                  <div>
                    <label
                      htmlFor="date"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Date
                    </label>
                    <DatePicker
                      selected={modalDate}
                      onChange={(date) => setModalDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className={`bg-gray-50 border ${
                        !modalDate ? 'border-red-500' : 'border-gray-300'
                      } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                      placeholderText="Select date"
                      minDate={new Date()} // Disable past dates
                      required
                    />
                    {/* Display a validation message */}
                    {!modalDate && (
                      <p className="mt-1 text-xs text-red-500">Please select a date.</p>
                    )}
                  </div>
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Available Time Range:
                    </label>
                    <TimeRangePicker
                      startTime={modalStartTime}
                      endTime={modalEndTime}
                      onChange={({ start, end }) => {
                        setModalStartTime(start);
                        setModalEndTime(end);
                      }}
                    />
                  </div>
                  {/* Recurrence Options */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Recurring Availability
                    </label>
                    <div className="flex items-center">
                      <Checkbox
                        id="isRecurring"
                        checked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                      />
                      <label htmlFor="isRecurring" className="ml-2 text-sm font-medium text-gray-900 dark:text-white">
                        Repeat Weekly
                      </label>
                    </div>
                  </div>
                  {isRecurring && (
                    <>
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Select Days:
                        </label>
                        <div className="flex space-x-2">
                          {['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].map((day) => (
                            <div key={day} className="flex items-center">
                              <Checkbox
                                id={`day-${day}`}
                                value={day}
                                checked={recurrenceDays.includes(day)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRecurrenceDays([...recurrenceDays, day]);
                                  } else {
                                    setRecurrenceDays(recurrenceDays.filter((d) => d !== day));
                                  }
                                }}
                              />
                              <label htmlFor={`day-${day}`} className="ml-1 text-sm">
                                {day}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Recurrence End Date:
                        </label>
                        <DatePicker
                          selected={recurrenceEndDate}
                          onChange={(date) => setRecurrenceEndDate(date)}
                          dateFormat="yyyy-MM-dd"
                          className={`bg-gray-50 border ${
                            isRecurring && !recurrenceEndDate ? 'border-red-500' : 'border-gray-300'
                          } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                          minDate={new Date()}
                          placeholderText="Select end date"
                          required={isRecurring} // Make it required when recurring
                        />
                        {/* Display a validation message */}
                        {isRecurring && !recurrenceEndDate && (
                          <p className="mt-1 text-xs text-red-500">Please select a recurrence end date.</p>
                        )}
                      </div>
                    </>
                  )}
                  {/* Display form validation errors */}
                  {formError && (
                    <div className="text-red-500 text-sm">
                      {formError}
                    </div>
                  )}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-sm px-4 py-2 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => setIsAddModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      disabled={isAdding || (isRecurring && !recurrenceEndDate)}
                    >
                      {isAdding ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                          </svg>
                          Adding...
                        </>
                      ) : (
                        'Add Slot'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Delete Modal */}
      {isDeleteModalOpen && selectedEvent && (
        <div
          id="delete-modal"
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out"
        >
          <div className="relative p-4 w-full max-w-md max-h-2xl">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {selectedEvent.extendedProps.isMeeting ? 'Delete Meeting' : 'Delete Availability Slot'}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsDeleteModalOpen(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                {selectedEvent.extendedProps.isMeeting ? (
                  <p className="text-gray-700 dark:text-gray-300">
                    Do you want to delete this meeting?
                  </p>
                ) : (
                  <p className="text-gray-700 dark:text-gray-300">
                    Do you want to delete this availability slot{selectedEvent.extendedProps.recurrenceId ? " and all related recurring slots" : ""}?
                  </p>
                )}
                <div className="flex justify-end mt-4 space-x-2">
                  {selectedEvent.extendedProps.isMeeting ? (
                    <>
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
                        onClick={() => {
                          // Delete the meeting
                          deleteMeeting(selectedEvent.id);
                          setIsDeleteModalOpen(false);
                        }}
                      >
                        Delete Meeting
                      </button>
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-700"
                        onClick={() => setIsDeleteModalOpen(false)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
                        onClick={() => {
                          // Delete only this slot
                          deleteAvailabilitySlot(selectedEvent.id);
                          setIsDeleteModalOpen(false);
                        }}
                      >
                        Delete This Slot
                      </button>
                      {selectedEvent.extendedProps.recurrenceId && (
                        <button
                          type="button"
                          className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-700"
                          onClick={() => {
                            // Delete all recurring slots
                            deleteRecurringSlots(selectedEvent.extendedProps.recurrenceId);
                            setIsDeleteModalOpen(false);
                          }}
                        >
                          Delete All Related Slots
                        </button>
                      )}
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-gray-600 dark:bg-gray-700 dark:hover:bg-gray-800"
                        onClick={() => setIsDeleteModalOpen(false)}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvisorAvailabilityCalendar;
