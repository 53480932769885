// StudentInitiatives.jsx
import React, { useState } from 'react';

const StudentInitiatives = () => {
  // We'll keep the sub-tabs, but set them all to "completed" to show all 6 at once.
  const [activeSubTab, setActiveSubTab] = useState('completed');

  /**
   * Hardcoded sample initiatives.
   * All are set to "completed" so they appear at once.
   */
  const initiativesData = [
    {
      id: '1',
      persistenceChange: +5.8,
      persistenceConfidence: 0.4,
      isSignificant: true,
      title: 'First-Generation Student Mentorship Program',
      participantsCount: 5075,
      description:
        'Connecting first-gen students with faculty and peer mentors to improve academic performance and retention.',
      startTerm: 'Fall 2024',
      endTerm: 'Spring 2025',
      addedDate: 'January 12, 2025',
      lastModifiedBy: 'Dr. Jane Smith',
      status: 'completed',
      timeAgo: '3 days ago',
    },
    {
      id: '2',
      persistenceChange: +1.8,
      persistenceConfidence: null,
      isSignificant: false,
      title: 'STEM Peer Tutoring Initiative',
      participantsCount: 2457,
      description:
        'Providing group and 1-on-1 tutoring for core STEM courses to help students stay on track.',
      startTerm: 'Spring 2024',
      endTerm: 'Fall 2024',
      addedDate: 'February 1, 2025',
      lastModifiedBy: 'Eric McIntosh',
      status: 'completed',
      timeAgo: '2 weeks ago',
    },
    {
      id: '3',
      persistenceChange: +4.9,
      persistenceConfidence: 3.2,
      isSignificant: true,
      title: 'Campus Wellness Program',
      participantsCount: 3267,
      description:
        'Comprehensive mental health and wellness services aimed at improving student satisfaction and persistence.',
      startTerm: 'Summer 2024',
      endTerm: 'Spring 2025',
      addedDate: 'January 20, 2025',
      lastModifiedBy: 'Impact User',
      status: 'completed',
      timeAgo: '5 days ago',
    },
    {
      id: '4',
      persistenceChange: +0.0,
      persistenceConfidence: 1.2,
      isSignificant: false,
      title: 'International Student Orientation Enhancement',
      participantsCount: 5556,
      description:
        'Expanding orientation programming for international students, focusing on academic and social integration.',
      startTerm: 'Fall 2024',
      endTerm: 'Fall 2025',
      addedDate: 'February 3, 2025',
      lastModifiedBy: 'Grace Gee',
      status: 'completed',
      timeAgo: '1 day ago',
    },
    {
      id: '5',
      persistenceChange: -0.8, // negative => Drop in Persistence
      persistenceConfidence: null,
      isSignificant: false,
      title: 'Undergraduate Research Opportunities Expansion',
      participantsCount: 1397,
      description:
        'Incentivizing faculty-mentored research for undergraduates; studying the effect on retention.',
      startTerm: 'Spring 2024',
      endTerm: 'Fall 2024',
      addedDate: 'January 29, 2025',
      lastModifiedBy: 'Grace Gee',
      status: 'completed',
      timeAgo: '4 hours ago',
    },
    {
      id: '6',
      persistenceChange: +2.4,
      persistenceConfidence: 0.5,
      isSignificant: true,
      title: 'Online Academic Advising Pilot',
      participantsCount: 29225,
      description:
        'Testing virtual advising sessions for remote and non-traditional students to see if it improves persistence.',
      startTerm: 'Fall 2024',
      endTerm: 'Spring 2025',
      addedDate: 'January 10, 2025',
      lastModifiedBy: 'Grace Gee',
      status: 'completed',
      timeAgo: '3 weeks ago',
    },
  ];

  // Filter by sub-tab (all are "completed" for demo to show 6 cards).
  const filteredInitiatives = initiativesData.filter(
    (init) => init.status === activeSubTab
  );

  // Inline SVG icons for up/down arrows
  const UpArrowIcon = (
    <svg
      className="w-4 h-4 inline-block text-green-600"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.293 12.293a1 1 0 001.414 0L10 7l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 000 1.414z" />
    </svg>
  );

  const DownArrowIcon = (
    <svg
      className="w-4 h-4 inline-block text-red-600"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.707 7.707a1 1 0 00-1.414-1.414L10 11.586 4.707 6.293a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6z" />
    </svg>
  );

  // Clock icon (for "time ago" badges)
  const ClockIcon = (
    <svg
      className="w-2.5 h-2.5 mr-1.5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
    </svg>
  );

  return (
    <div className="w-full px-2 py-3">
      {/* Sub-tabs for "Completed", "Pending", "Archived" */}
      <div className="flex items-center justify-between mb-2 border-b border-gray-200 pb-1">
        <div>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'completed'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('completed')}
          >
            Completed
          </button>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'pending'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('pending')}
          >
            Pending
          </button>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'archived'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('archived')}
          >
            Archived
          </button>
        </div>

        {/* Example button to "Add Initiative" (dummy for now) */}
        <button
          onClick={() => alert('Add Initiative (placeholder)')}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Add Initiative
        </button>
      </div>

      {filteredInitiatives.length === 0 ? (
        <p className="text-gray-500 italic">No initiatives in this status.</p>
      ) : (
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {filteredInitiatives.map((item) => {
            const isDrop = item.persistenceChange < 0;
            const absChange = Math.abs(item.persistenceChange).toFixed(1);
            const significanceBadge = item.isSignificant ? (
              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                Significant
              </span>
            ) : (
              <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                Not significant
              </span>
            );

            return (
              <div
                key={item.id}
                className="relative border border-gray-200 bg-white rounded-md shadow-sm p-4 hover:shadow-md transition-shadow"
              >
                {/* Top bar to indicate lift/drop (colored) */}
                <div
                  className={`absolute top-0 left-0 right-0 h-1 ${
                    isDrop ? 'bg-red-500' : 'bg-green-500'
                  } rounded-t-md`}
                ></div>

                {/* Time "badge" in top-right corner */}
                <div className="absolute top-2 right-2 z-10">
                  <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                    {ClockIcon}
                    {item.timeAgo}
                  </span>
                </div>

                {/* Persistence change row */}
                <div className="flex items-center mb-1 mt-2">
                  {isDrop ? DownArrowIcon : UpArrowIcon}
                  <span
                    className={`ml-2 font-semibold ${
                      isDrop ? 'text-red-700' : 'text-green-600'
                    } text-sm`}
                  >
                    {absChange}% {isDrop ? 'Drop' : 'Lift'} in Persistence
                  </span>
                  {item.persistenceConfidence !== null && (
                    <span className="text-xs text-gray-500 ml-2">
                      ±{item.persistenceConfidence}%
                    </span>
                  )}
                </div>

                {/* Significance badge */}
                <div className="mb-2">{significanceBadge}</div>

                {/* Title */}
                <h3 className="text-lg font-semibold mb-2 line-clamp-2">
                  {item.title}
                </h3>

                {/* Participants */}
                <p className="text-sm text-gray-600 mb-1">
                  {item.participantsCount.toLocaleString()} analyzed participants
                </p>

                {/* Description */}
                <p className="text-sm text-gray-700 mb-3 line-clamp-3">
                  {item.description}
                </p>

                {/* Terms */}
                <div className="text-xs text-gray-500 mb-0.5">
                  {item.startTerm} – {item.endTerm}
                </div>

                {/* "Added on ... by ..." */}
                <div className="text-xs text-gray-500">
                  Added on {item.addedDate}
                  {item.lastModifiedBy ? `, by ${item.lastModifiedBy}` : ''}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StudentInitiatives;
