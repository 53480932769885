import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { db } from '../../../firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  orderBy, 
  where 
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../../contexts/AuthContext';

import SearchComponent from '../StudentListing/SearchComponent';
import { FaPlus, FaTimes } from 'react-icons/fa';

const StudentGroups = ({ students, advisors }) => {
  const { currentUser } = useAuth();

  // ---------------------------
  // GROUP CREATION
  // ---------------------------
  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [groupType, setGroupType] = useState('filter'); // 'filter' = dynamic, 'manual' = static
  const [groupName, setGroupName] = useState('');

  // For dynamic group creation (filters + searchTerm)
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);

  // For manual group creation
  const [manuallySelectedIds, setManuallySelectedIds] = useState([]);

  // ---------------------------
  // GROUP LISTING
  // ---------------------------
  const [groups, setGroups] = useState([]);
  const [groupSearch, setGroupSearch] = useState('');
  const [groupTypeFilter, setGroupTypeFilter] = useState('All'); // All | Dynamic | Static
  const [expandedGroupId, setExpandedGroupId] = useState(null);

  // Exclude certain fields from dynamic custom filters
  const excludeFields = ['id', 'fromFirestore', 'surveyAnswers', 'name', 'email'];

  // ===========================
  // Compute fieldTypes for custom filters
  // ===========================
  const fieldTypes = useMemo(() => {
    const types = {};
    if (students.length > 0) {
      const student = students[0];
      Object.keys(student).forEach((field) => {
        if (!excludeFields.includes(field)) {
          const value = student[field];
          if (Array.isArray(value)) {
            if (value.length > 0) {
              types[field] = `array-${typeof value[0]}`;
            } else {
              types[field] = 'array';
            }
          } else {
            if (field === 'GPA') {
              types[field] = 'number';
            } else {
              types[field] = typeof value;
            }
          }
        }
      });
      // Include "tags" if surveyAnswers.Tags exists
      if (student.surveyAnswers?.Tags) {
        types['tags'] = 'array';
      }
    }
    return types;
  }, [students]);

  // ===========================
  // Compute all available tags for dropdown
  // ===========================
  const allAvailableTags = useMemo(() => {
    const tagsSet = new Set();
    students.forEach(student => {
      const tags = student.surveyAnswers?.Tags || [];
      tags.forEach(tag => tagsSet.add(tag));
    });
    return Array.from(tagsSet);
  }, [students]);

  // ===========================
  // 1) FETCH GROUPS
  // ===========================
  const fetchGroups = useCallback(async () => {
    if (!currentUser) return;
    try {
    
      const ref = collection(db, 'studentGroups');
      const allGroups = await getDocs(collection(db, 'studentGroups'));
      console.log(allGroups.docs.length, allGroups.docs);
      console.log('ref', ref);
      const qRef = query(
        ref,
        // where('owner', '==', currentUser.uid),
        orderBy('createdAt', 'desc')
      );
      console.log('qref: ', qRef);

      const snapshot = await getDocs(qRef);
      console.log('snapshot: ', snapshot, snapshot.docs);
      const loaded = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      console.log('Success: ', loaded);
      setGroups(loaded);
    } catch (error) {
      console.error('Error fetching groups:', error);
      toast.error('Failed to fetch groups. Please try again.');
    }
  }, [currentUser]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  // ===========================
  // 2) APPLY FILTERS FOR DYNAMIC PREVIEW
  // ===========================
  useEffect(() => {
    if (groupType !== 'filter') {
      setFilteredStudents([]);
      return;
    }

    let results = [...students];

    // a) Name search
    if (searchTerm) {
      results = results.filter((s) =>
        s.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // b) Grad year
    if (filters.gradYear?.length) {
      results = results.filter((s) =>
        filters.gradYear.includes(s.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // c) Program
    if (filters.program?.length) {
      results = results.filter((s) =>
        Array.isArray(s.major)
          ? s.major.some((prog) => filters.program.includes(prog))
          : filters.program.includes(s.major)
      );
    }

    // d) Advisor
    if (filters.advisors?.length) {
      results = results.filter((s) => filters.advisors.includes(s.advisor));
    }

    // e) Performance (GPA)
    if (filters.performance?.length) {
      results = results.filter((s) => {
        const gpa = parseFloat(s.GPA);
        return filters.performance.some((range) => {
          switch (range) {
            case '> 4.0':
              return gpa > 4.0;
            case '3.5 - 4.0':
              return gpa >= 3.5 && gpa <= 4.0;
            case '3.0 - 3.5':
              return gpa >= 3.0 && gpa < 3.5;
            case '2.5 - 3.0':
              return gpa >= 2.5 && gpa < 3.0;
            case '2.0 - 2.5':
              return gpa >= 2.0 && gpa < 2.5;
            case '< 2.0':
              return gpa < 2.0;
            default:
              return false;
          }
        });
      });
    }

    // Tags filter
    if (filters.tags?.length) {
      results = results.filter((s) => {
        const studentTags = s.surveyAnswers?.Tags || [];
        return studentTags.some(tag => filters.tags.includes(tag));
      });
    }

    // f) Custom filter(s)
    if (filters.customFilter) {
      const { field, operator, value } = filters.customFilter;
      results = results.filter((s) => {
        const studentVal = field === 'tags' ? s.surveyAnswers?.Tags : s[field];
        if (studentVal == null) return false;
        if (operator === 'equals') {
          if (field === 'tags') {
            if (Array.isArray(studentVal) && Array.isArray(value)) {
              return studentVal.some(tag =>
                value.some(v => String(tag).trim().toLowerCase() === String(v).trim().toLowerCase())
              );
            }
            if (Array.isArray(studentVal)) {
              return studentVal.some(tag => String(tag).trim().toLowerCase() === String(value).trim().toLowerCase());
            }
            return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
          } else {
            if (Array.isArray(studentVal)) {
              return studentVal.some(
                (val) => String(val).trim().toLowerCase() === String(value).trim().toLowerCase()
              );
            } else {
              return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
            }
          }
        } else if (operator === 'greater than') {
          return parseFloat(studentVal) > parseFloat(value);
        } else if (operator === 'less than') {
          return parseFloat(studentVal) < parseFloat(value);
        }
        return true;
      });
    } else if (filters.customFilters?.length) {
      filters.customFilters.forEach(({ field, operator, value }) => {
        results = results.filter((s) => {
          const studentVal = field === 'tags' ? s.surveyAnswers?.Tags : s[field];
          if (studentVal == null) return false;
          if (operator === 'equals') {
            if (field === 'tags') {
              if (Array.isArray(studentVal) && Array.isArray(value)) {
                return studentVal.some(tag =>
                  value.some(v => String(tag).trim().toLowerCase() === String(v).trim().toLowerCase())
                );
              }
              if (Array.isArray(studentVal)) {
                return studentVal.some(tag => String(tag).trim().toLowerCase() === String(value).trim().toLowerCase());
              }
              return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
            } else {
              if (Array.isArray(studentVal)) {
                return studentVal.some(
                  (val) => String(val).trim().toLowerCase() === String(value).trim().toLowerCase()
                );
              } else {
                return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
              }
            }
          } else if (operator === 'greater than') {
            return parseFloat(studentVal) > parseFloat(value);
          } else if (operator === 'less than') {
            return parseFloat(studentVal) < parseFloat(value);
          }
          return true;
        });
      });
    }

    setFilteredStudents(results);
  }, [groupType, filters, searchTerm, students]);

  // ===========================
  // 3) MANUAL (static) selection
  // ===========================
  const handleManualCheckbox = (studentId, checked) => {
    setManuallySelectedIds((prev) => {
      if (checked) return [...prev, studentId];
      return prev.filter((id) => id !== studentId);
    });
  };
  const isManuallySelected = (id) => manuallySelectedIds.includes(id);

  // ===========================
  // 4) CREATE NEW GROUP
  // ===========================
  const handleCreateGroup = async () => {
    if (!groupName.trim()) {
      toast.warning('Please enter a group name.');
      return;
    }
    if (!currentUser) {
      toast.error('You must be logged in.');
      return;
    }

    try {
      const ref = collection(db, 'studentGroups');

      if (groupType === 'filter') {
        const payload = {
          name: groupName.trim(),
          isDynamic: true,
          filterDefinition: filters,
          owner: currentUser.uid,
          createdAt: new Date(),
        };
        await addDoc(ref, payload);
        toast.success(`Dynamic group "${groupName.trim()}" created!`);
      } else {
        if (manuallySelectedIds.length === 0) {
          toast.warning('Please select at least one student (manual).');
          return;
        }
        const payload = {
          name: groupName.trim(),
          isDynamic: false,
          studentIds: manuallySelectedIds,
          owner: currentUser.uid,
          createdAt: new Date(),
        };
        await addDoc(ref, payload);
        toast.success(`Static group "${groupName.trim()}" created!`);
      }

      // Reset
      setShowCreatePanel(false);
      setGroupName('');
      setFilters({});
      setSearchTerm('');
      setManuallySelectedIds([]);

      // Reload groups
      fetchGroups();
    } catch (error) {
      console.error('Error creating group:', error);
      toast.error('Failed to create group.');
    }
  };

  // ===========================
  // 5) FILTER GROUP LIST (BY NAME + TYPE)
  // ===========================
  const filteredGroups = useMemo(() => {
    let list = [...groups];

    if (groupSearch.trim()) {
      list = list.filter((g) =>
        g.name.toLowerCase().includes(groupSearch.toLowerCase())
      );
    }
    if (groupTypeFilter !== 'All') {
      const wantDynamic = groupTypeFilter === 'Dynamic';
      list = list.filter((g) => g.isDynamic === wantDynamic);
    }
    return list;
  }, [groups, groupSearch, groupTypeFilter]);

  // ===========================
  // 6) TOGGLE EXPAND
  // ===========================
  const toggleExpandGroup = (groupId) => {
    setExpandedGroupId((prev) => (prev === groupId ? null : groupId));
  };

  // ===========================
  // 7) APPLY DYNAMIC FILTER TO SHOW MEMBERS
  // ===========================
  const applyDynamicFilter = (studentList, fDef = {}) => {
    let results = [...studentList];

    // Graduation Year
    if (fDef.gradYear?.length) {
      results = results.filter((s) =>
        fDef.gradYear.includes(s.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // Program
    if (fDef.program?.length) {
      results = results.filter((s) =>
        Array.isArray(s.major)
          ? s.major.some((prog) => fDef.program.includes(prog))
          : fDef.program.includes(s.major)
      );
    }

    // Advisor
    if (fDef.advisors?.length) {
      results = results.filter((s) => fDef.advisors.includes(s.advisor));
    }

    // Performance (GPA)
    if (fDef.performance?.length) {
      results = results.filter((s) => {
        const gpa = parseFloat(s.GPA);
        return fDef.performance.some((range) => {
          switch (range) {
            case '> 4.0':
              return gpa > 4.0;
            case '3.5 - 4.0':
              return gpa >= 3.5 && gpa <= 4.0;
            case '3.0 - 3.5':
              return gpa >= 3.0 && gpa < 3.5;
            case '2.5 - 3.0':
              return gpa >= 2.5 && gpa < 3.0;
            case '2.0 - 2.5':
              return gpa >= 2.0 && gpa < 2.5;
            case '< 2.0':
              return gpa < 2.0;
            default:
              return false;
          }
        });
      });
    }

    // Tags filter
    if (fDef.tags?.length) {
      results = results.filter((s) => {
        const studentTags = s.surveyAnswers?.Tags || [];
        return studentTags.some(tag => fDef.tags.includes(tag));
      });
    }

    // Custom filters
    if (fDef.customFilter) {
      const { field, operator, value } = fDef.customFilter;
      results = results.filter((s) => {
        const studentVal = field === 'tags' ? s.surveyAnswers?.Tags : s[field];
        if (studentVal == null) return false;
        if (operator === 'equals') {
          if (field === 'tags') {
            if (Array.isArray(studentVal) && Array.isArray(value)) {
              return studentVal.some(tag =>
                value.some(v => String(tag).trim().toLowerCase() === String(v).trim().toLowerCase())
              );
            }
            if (Array.isArray(studentVal)) {
              return studentVal.some(tag => String(tag).trim().toLowerCase() === String(value).trim().toLowerCase());
            }
            return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
          } else {
            if (Array.isArray(studentVal)) {
              return studentVal.some(
                (val) => String(val).trim().toLowerCase() === String(value).trim().toLowerCase()
              );
            } else {
              return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
            }
          }
        } else if (operator === 'greater than') {
          return parseFloat(studentVal) > parseFloat(value);
        } else if (operator === 'less than') {
          return parseFloat(studentVal) < parseFloat(value);
        }
        return true;
      });
    } else if (fDef.customFilters?.length) {
      fDef.customFilters.forEach(({ field, operator, value }) => {
        results = results.filter((s) => {
          const studentVal = field === 'tags' ? s.surveyAnswers?.Tags : s[field];
          if (studentVal == null) return false;
          if (operator === 'equals') {
            if (field === 'tags') {
              if (Array.isArray(studentVal) && Array.isArray(value)) {
                return studentVal.some(tag =>
                  value.some(v => String(tag).trim().toLowerCase() === String(v).trim().toLowerCase())
                );
              }
              if (Array.isArray(studentVal)) {
                return studentVal.some(tag => String(tag).trim().toLowerCase() === String(value).trim().toLowerCase());
              }
              return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
            } else {
              if (Array.isArray(studentVal)) {
                return studentVal.some(
                  (val) => String(val).trim().toLowerCase() === String(value).trim().toLowerCase()
                );
              } else {
                return String(studentVal).trim().toLowerCase() === String(value).trim().toLowerCase();
              }
            }
          } else if (operator === 'greater than') {
            return parseFloat(studentVal) > parseFloat(value);
          } else if (operator === 'less than') {
            return parseFloat(studentVal) < parseFloat(value);
          }
          return true;
        });
      });
    }

    return results;
  };

  // ===========================
  // RENDER
  // ===========================
  return (
    <div className="w-full ">
      <div className="mx-auto">

        {/* A) GROUP LISTING FILTERS */}
        <div className="flex flex-col md:flex-row items-center gap-2 mb-2">
          <input
            type="text"
            placeholder="Search Groups by Name"
            value={groupSearch}
            onChange={(e) => setGroupSearch(e.target.value)}
            className="h-10 w-52 text-sm placeholder-gray-400 rounded border border-gray-300 
                       focus:outline-none focus:border-blue-500 px-2"
          />
          <select
            value={groupTypeFilter}
            onChange={(e) => setGroupTypeFilter(e.target.value)}
            className="h-10 text-sm border border-gray-300 rounded focus:outline-none
                       focus:border-blue-500 bg-white w-32"
          >
            <option value="All">All Types</option>
            <option value="Dynamic">Dynamic</option>
            <option value="Static">Static</option>
          </select>
        </div>

        {/* B) GROUPS LISTING CONTAINER */}
        <div className="border border-gray-300 rounded bg-gray-50 p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-700">Student Groups</h2>
            <button
              onClick={() => setShowCreatePanel((prev) => !prev)}
              className="inline-flex items-center text-blue-600 font-medium hover:text-blue-800"
            >
              <FaPlus className="mr-2" />
              Create Group
            </button>
          </div>

          {/* C) CREATE GROUP PANEL */}
          {showCreatePanel && (
            <div className="relative border border-gray-300 rounded p-4 mb-4 bg-white">
              <button
                onClick={() => setShowCreatePanel(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <FaTimes />
              </button>

              <h3 className="text-lg font-semibold mb-2">Define New Group</h3>

              {/* Group Name */}
              <div className="mb-3">
                <label className="block text-sm font-medium">Group Name:</label>
                <input
                  type="text"
                  className="mt-1 w-full border border-gray-300 rounded px-2 py-1"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder="e.g. Junior Honors"
                />
              </div>

              {/* Group Type */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Group Type:</label>
                <div className="flex items-center gap-4">
                  <label className="text-sm flex items-center">
                    <input
                      type="radio"
                      name="groupType"
                      value="filter"
                      checked={groupType === 'filter'}
                      onChange={() => setGroupType('filter')}
                      className="mr-1"
                    />
                    Filter (Dynamic)
                  </label>
                  <label className="text-sm flex items-center">
                    <input
                      type="radio"
                      name="groupType"
                      value="manual"
                      checked={groupType === 'manual'}
                      onChange={() => setGroupType('manual')}
                      className="mr-1"
                    />
                    Manual (Static)
                  </label>
                </div>
              </div>

              {groupType === 'filter' && (
                <div className="border border-gray-200 bg-gray-50 rounded p-3 mb-4">
                  <SearchComponent
                    students={students}
                    advisors={advisors}
                    fieldTypes={fieldTypes}
                    onSearch={setSearchTerm}
                    onFilter={setFilters}
                    onSelectAll={() => {}}
                    everythingSelected={false}
                    filteredStudentsCount={filteredStudents.length}
                    dashboardFilterType={null}
                    dashboardFilterValue={null}
                  />
                  <p className="text-sm text-gray-600 mt-2">
                    Currently matches <strong>{filteredStudents.length}</strong> students
                  </p>
                </div>
              )}

              {groupType === 'manual' && (
                <div className="border border-gray-200 bg-gray-50 rounded p-3 mb-4 max-h-64 overflow-y-auto">
                  {students.map((stu) => (
                    <div key={stu.id} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        checked={isManuallySelected(stu.id)}
                        onChange={(e) => handleManualCheckbox(stu.id, e.target.checked)}
                        className="mr-2"
                      />
                      <span className="text-sm">{stu.name}</span>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowCreatePanel(false)}
                  className="border border-gray-300 text-gray-700 hover:bg-gray-200 px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateGroup}
                  className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded"
                >
                  Save Group
                </button>
              </div>
            </div>
          )}

          {/* NEW: Header Row for Group List */}
          {filteredGroups.length > 0 && (
            <div className="grid grid-cols-12 items-center font-semibold text-sm text-gray-500 mb-2 border-b pb-2">
              <div className="col-span-4 ml-2 text-left">Group Name <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} /></div>
              <div className="col-span-2 text-center">Type <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} /></div>
              <div className="col-span-2 text-center">Members <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} /></div>
              <div className="col-span-4 text-right pr-2">Action <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} /></div>
            </div>
          )}

          {/* D) GROUP ROWS */}
          {filteredGroups.length === 0 ? (
            <p className="text-gray-500 text-sm">No groups found.</p>
          ) : (
            filteredGroups.map((group) => {
              let members = [];
              if (group.isDynamic) {
                members = applyDynamicFilter(students, group.filterDefinition || {});
              } else {
                const setIds = new Set(group.studentIds || []);
                members = students.filter((s) => setIds.has(s.id));
              }

              const count = members.length;
              const expanded = expandedGroupId === group.id;

              return (
                <GroupItem
                  key={group.id}
                  group={group}
                  count={count}
                  members={members}
                  isExpanded={expanded}
                  onToggle={toggleExpandGroup}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentGroups;

// A simplified GroupItem for listing each group
const GroupItem = ({ group, count, members, isExpanded, onToggle }) => {
  // Define styles for dynamic and static labels
  const typeStyles = group.isDynamic
    ? 'bg-green-100 text-green-800 rounded-full px-2 py-1 text-xs font-semibold'
    : 'bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs font-semibold';

  return (
    <div
      className="border bg-white rounded-lg p-1.5 mb-2 cursor-pointer hover:bg-gray-100"
      onClick={() => onToggle(group.id)}
    >
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-4 ml-2">
          <h4 className="font-bold text-black text-sm">{group.name}</h4>
        </div>
        <div className="col-span-2 text-center text-sm">
          <span className={typeStyles}>
            {group.isDynamic ? 'Dynamic' : 'Static'}
          </span>
        </div>
        <div className="col-span-2 text-center text-sm">{count}</div>
        <div className="col-span-4 text-right pr-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggle(group.id);
            }}
            className="text-xs text-blue-700 hover:text-white border border-blue-700 
                       hover:bg-blue-700 py-1 px-2 rounded transition-all duration-300"
          >
            {isExpanded ? 'Hide' : 'View'}
          </button>
        </div>
      </div>

      {isExpanded && (
        <div className="ml-2 mt-2">
          {/* Header for expanded student list */}
          {members.length > 0 && (
            <div className="grid grid-cols-12 items-center font-semibold text-sm text-gray-500 mb-1 border-b pb-1">
              <div className="col-span-3 ml-2">Name </div>
              <div className="col-span-3">Program </div>
              <div className="col-span-2 text-center">Grad Year </div>
              <div className="col-span-4 text-right pr-2">Last Login </div>
            </div>
          )}

          {members.length === 0 ? (
            <p className="text-sm text-gray-500">No students in this group.</p>
          ) : (
            members.map((stu) => (
              <div
                key={stu.id}
                className="
                  border bg-white rounded-lg p-1.5 mb-1 
                  hover:bg-gray-50 
                  cursor-default
                  grid grid-cols-12 items-center
                "
              >
                <div className="col-span-3 ml-2 text-sm font-semibold">
                  {stu.name}
                </div>
                <div className="col-span-3 text-sm">
                  {Array.isArray(stu.major) ? stu.major.join(', ') : stu.major}
                </div>
                <div className="col-span-2 text-sm text-center">
                  {stu.surveyAnswers?.SchoolEnd?.year || 'N/A'}
                </div>
                <div className="col-span-4 text-sm text-right pr-2">
                  <span className="text-gray-500 italic">
                    {stu.lastLoginDate || ''}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
