import React, { useState, useEffect, useRef } from 'react';
import calculateProgressForMajor from '../CourseProgressFunctions/CalculateProgressForMajor';
import calculateProgressForMinor from '../CourseProgressFunctions/CalculateProgressForMinor'; // Import the minor progress function

const ProgressBars = ({
    authUser,
    fetchedMajors,
    fetchedMinors, // Assuming this is needed elsewhere
    fetchedCourseData,
    finalizedSchedule,
    completedSemesters,
    milestones,
    completedMilestones
}) => {

    // Unified state for selected program (major or minor)
    const [selectedProgram, setSelectedProgram] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility

    // Ref for the dropdown container
    const dropdownRef = useRef(null);

    useEffect(() => {
        const majors = authUser?.surveyAnswers?.Majors || [];
        const minors = authUser?.surveyAnswers?.Minors || [];
        if (majors.length > 0 || minors.length > 0) {
            // Initialize with the first major or minor if available
            setSelectedProgram({
                type: majors.length > 0 ? 'Major' : 'Minor',
                name: majors.length > 0 ? majors[0] : (minors[0] + ' Minor')
            });
        }
    }, [authUser]);

    const handleProgramChange = (program) => {
        setSelectedProgram(program);
        setDropdownVisible(false); // Hide dropdown after selection
    };

    const handleSelectProgramButtonClick = () => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
    };

    // Determine if the selected program is a major or minor
    const isMajor = selectedProgram?.type === 'Major';

    // Calculate progress based on the program type
    const { takenCoursesCount, planCoursesCount, totCoursesCount } = isMajor
        ? calculateProgressForMajor(
            selectedProgram.name, // Pass the major name
            fetchedMajors,
            fetchedCourseData,
            finalizedSchedule,
            completedSemesters
        )
        : calculateProgressForMinor(
            selectedProgram?.name?.replace(' Minor', ''), // Remove ' Minor' suffix for calculation
            fetchedMinors,
            fetchedCourseData,
            finalizedSchedule,
            completedSemesters
        );

    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const totalMilestones = milestones?.length;
    const milestoneProgress = totalMilestones > 0 ? Math.round((completedMilestones / totalMilestones) * 100) : 0;

    // Effect to handle clicks outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="flex justify-between">
            {/* Program Pathway Progress Section */}
            <div className="mb-0">
                <div className="flex items-center mb-2">
                    {/* Check if there are multiple programs (majors/minors) */}
                    {((authUser?.surveyAnswers.Majors?.length || 0) + (authUser?.surveyAnswers.Minors?.length || 0)) > 1 ? (
                        <>
                            <h4>Progress Towards</h4>
                            <div className="relative" ref={dropdownRef}>
                                {/* Button that shows the program name and toggles dropdown */}
                                <button
                                    className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                                    onClick={handleSelectProgramButtonClick}
                                >
                                    <span>{selectedProgram?.name || 'N/A'}</span>
                                </button>
                                <span className="ml-1">Pathway</span>

                                {/* Dropdown that appears when button is clicked */}
                                {dropdownVisible && (
                                    <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                                        <ul className="py-1">
                                            {/* List Majors */}
                                            {authUser?.surveyAnswers?.Majors?.map((major, index) => (
                                                <li key={`major-${index}`}>
                                                    <button
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() => handleProgramChange({ type: 'Major', name: major })}
                                                    >
                                                        {major}
                                                    </button>
                                                </li>
                                            ))}
                                            {/* List Minors */}
                                            {authUser?.surveyAnswers?.Minors?.map((minor, index) => (
                                                <li key={`minor-${index}`}>
                                                    <button
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() => handleProgramChange({ type: 'Minor', name: `${minor} Minor` })}
                                                    >
                                                        {`${minor} Minor`}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <h4>
                            Progress Towards {selectedProgram?.name || 'N/A'} Pathway
                        </h4>
                    )}
                </div>
                <div className="relative w-full max-w-[22vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className="absolute bg-green-500 h-2.5"
                        style={{
                            width: `${progressDone}%`,
                            left: '0',
                            top: 0,
                            borderTopLeftRadius: '9999px',
                            borderBottomLeftRadius: '9999px',
                            borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                            borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                        }}
                    ></div>
                    <div
                        className="absolute bg-yellow-300 h-2.5"
                        style={{
                            width: `${progressTaken}%`,
                            left: `${progressDone}%`,
                            top: 0,
                            borderTopRightRadius: '9999px',
                            borderBottomRightRadius: '9999px',
                            borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                            borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                        }}
                    ></div>
                </div>
                <div className="flex justify-between mt-2 space-x-10 w-full max-w-[22vw] items-center">
                    <div className="rounded bg-green-100 px-2 py-0.3">
                        <span className="text-green-600 font-semibold">{takenCoursesCount} Taken</span>
                    </div>
                    <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                        <span className="text-yellow-500 font-semibold">{planCoursesCount || 0} Planned</span>
                    </div>
                    <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                        <span className="text-gray-600 font-semibold">{remainingCourses || 0} Unplanned</span>
                    </div>
                </div>
            </div>

            {/* Milestone Progress Section */}
            <div className="milestone-progress">
                <h4 className="mb-2">Milestone Progress</h4>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress || 0}%` }}></div>
                </div>
                <div className="flex justify-between mt-2">
                    <div className="rounded bg-blue-100 px-2 py-0.3">
                        <span className="text-blue-600 font-semibold">{completedMilestones || 0} Completed</span>
                    </div>
                    <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                        <span className="text-gray-500 font-semibold">{(totalMilestones || 0) - (completedMilestones || 0)} Remaining</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBars;
