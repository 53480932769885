import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const RoleSelection = () => {
  const [selectedRole, setSelectedRole] = useState('');
  const [showTestingModal, setShowTestingModal] = useState(true);
  const { pathwayData } = useAuth();
  const navigate = useNavigate();

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    navigate(`/signup/${role}`);
  };

  const closeTestingModal = () => {
    setShowTestingModal(false);
  };

  const completePathways = pathwayData ? pathwayData.filter(pathway => pathway.Completeness === 'Complete') : [];

  useEffect(() => {
    const modal = document.getElementById('select-modal');
    modal.classList.remove('hidden');
  }, []);

  return (
    <>
      <div
        id="select-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-hidden overflow-y-auto bg-white-900 bg-opacity-50"
      >
        <div id="select-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-hidden overflow-y-auto bg-white-900 bg-opacity-50">
          {showTestingModal && (
            <div
              tabIndex="-1"
              aria-hidden="true"
              className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-x-hidden overflow-y-auto bg-white-900 bg-opacity-50"
            >
              <div className="relative w-full max-w-md p-4 h-auto">
                <div className="relative bg-yellow-100 rounded-lg shadow dark:bg-yellow-700">
                  <div className="p-4 md:p-5">
                    <p className="mb-4 text-yellow-800 dark:text-yellow-300">
                      This application is still in testing
                    </p>
                    <p className="text-sm text-yellow-800 dark:text-yellow-300 mb-4">
                      Some degree pathways may be incomplete or missing.
                    </p>
                    <p className="text-sm text-yellow-800 dark:text-yellow-300 mb-4">
                      If you notice any inconsistencies or inaccuracies within planning resources or bugs in the application, please report them.
                    </p>
                    {/* {completePathways.length > 0 && (
                      <div>
                        <p className="font-semibold mb-2 text-yellow-800 dark:text-yellow-300">Currently Complete Pathways:</p>
                        <ul className="list-disc pl-4">
                          {completePathways.map(pathway => (
                            <li key={pathway.id} className="text-yellow-800 dark:text-yellow-300">{pathway.Major}</li>
                          ))}
                        </ul>
                      </div>
                    )} */}
                    <button
                      className="absolute top-0 right-0 mt-4 mr-4 text-yellow-800 hover:text-yellow-900 dark:text-yellow-300 dark:hover:text-yellow-200"
                      onClick={closeTestingModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      <div className="relative w-full max-w-md p-4 h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 border-b rounded-t md:p-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Roles
            </h3>
          </div>
          <div className="p-4 md:p-5 max-h-150 overflow-y-auto">
            <p className="mb-4 text-gray-500 dark:text-gray-400">Select your desired role:</p>
            <ul className="space-y-2 mb-4">
              <li>
              <Link 
                to="/signup/student" 
                className="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:!text-gray-300 dark:!border-gray-500 dark:peer-checked:!text-blue-500 peer-checked:!border-blue-600 peer-checked:!text-blue-600 hover:!text-gray-900 hover:!bg-gray-100 dark:!text-white dark:!bg-gray-600 dark:hover:!bg-gray-500" 
                onClick={() => handleRoleSelection('student')}>                  
                <div className="block">
                    <div className="w-full text-base font-semibold">Student</div>
                    <div className="w-full text-sm text-gray-500 dark:text-gray-400">For students and parents.</div>
                  </div>
                  <svg className="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </Link>
              </li>
              {/* <li>
                <Link to="/signup/staff" className="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500" onClick={() => handleRoleSelection('advisor')}>
                  <div className="block">
                    <div className="w-full text-base font-semibold">Advisor</div>
                    <div className="w-full text-sm text-gray-500 dark:text-gray-400">For academic advisors.</div>
                  </div>
                  <svg className="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/signup/staff" className="inline-flex items-center justify-between w-full p-3 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500" onClick={() => handleRoleSelection('admin')}>
                  <div className="block">
                    <div className="w-full text-base font-semibold">Admin</div>
                    <div className="w-full text-sm text-gray-500 dark:text-gray-400">For administrators and staff.</div>
                  </div>
                  <svg className="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default RoleSelection;
