import { findEnrollmentTotal } from './CalculateEnrollmentTrends'; // Helper for finding term enrollment total

export const calculateYearDistribution = (courseNumber, enrollmentData) => {
  const enrollmentTotal = findEnrollmentTotal(enrollmentData, "FA24");

  // Hard-coded weight distributions based on the first digit of the course number
  const weightDistributions = {
    1: { Freshman: 0.7, Sophomore: 0.15, Junior: 0.1, Senior: 0.05, "Non-Degree Seeking": 0.02, Other: 0.01 },
    2: { Freshman: 0.1, Sophomore: 0.6, Junior: 0.2, Senior: 0.05, "Non-Degree Seeking": 0.03, Other: 0.02 },
    3: { Freshman: 0.05, Sophomore: 0.2, Junior: 0.55, Senior: 0.18, "Non-Degree Seeking": 0.02, Other: 0.05 },
    4: { Freshman: 0.03, Sophomore: 0.1, Junior: 0.2, Senior: 0.6, "Non-Degree Seeking": 0.02, Other: 0.05 },
    default: { Freshman: 0.1, Sophomore: 0.1, Junior: 0.2, Senior: 0.2, "Non-Degree Seeking": 0.2, Other: 0.2 },
  };

  // Extract the first digit from the course number
  const firstDigitMatch = courseNumber.match(/\d/);
  const firstDigit = firstDigitMatch ? parseInt(firstDigitMatch[0], 10) : null;

  // Get the weights for the specific first digit or use default
  const baseWeights = weightDistributions[firstDigit] || weightDistributions.default;

  // Introduce randomness by slightly modifying each weight
  const randomizedWeights = Object.fromEntries(
    Object.entries(baseWeights).map(([grade, weight]) => {
      const randomFactor = 0.9 + Math.random() * 0.2; // Random factor between 0.9 and 1.1
      return [grade, weight * randomFactor];
    })
  );

  // Normalize weights to ensure they sum to 1
  const totalWeight = Object.values(randomizedWeights).reduce((sum, weight) => sum + weight, 0);
  const normalizedWeights = Object.fromEntries(
    Object.entries(randomizedWeights).map(([grade, weight]) => [grade, weight / totalWeight])
  );

  // Calculate counts for each academic year based on weights
  const yearDistribution = Object.entries(normalizedWeights).map(([grade, weight]) => ({
    grade,
    count: Math.round(enrollmentTotal * weight),
  }));

  // Adjust distribution to match exact enrollmentTotal
  const totalCalculated = yearDistribution.reduce((sum, { count }) => sum + count, 0);
  const adjustment = enrollmentTotal - totalCalculated;
  if (adjustment !== 0) {
    // Apply adjustment to the largest group
    yearDistribution.sort((a, b) => b.count - a.count)[0].count += adjustment;
  }

  // Return the final distribution
  return yearDistribution.filter(({ count }) => count > 0);
};
