import React from 'react';

const ReviewSection = ({
  pathwayName,
  upperDivisionCredits,
  overallCredits,
  courses,
  electives,
  requirements,
  milestones,
  isBasedOnAnotherMajor,
  basedOnMajor,
  faculty,
  numberOfYears,
}) => {
  const renderPossibilities = (possibilities) => {
    if (!Array.isArray(possibilities) || possibilities.length === 0) {
      return <span className="text-gray-500">No Possibilities Assigned</span>;
    }

    return possibilities.map((possibility, index) => (
      <span key={possibility.courseInfo_courseNumber} className="text-blue-600">
        {possibility.courseInfo_courseNumber}
        {index < possibilities.length - 1 && ', '}
      </span>
    ));
  };

  return (
    <div className="p-8 bg-gray-50 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Review Your Pathway</h2>

      {/* Pathway Details */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Pathway Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p>
              <strong>Name:</strong> {pathwayName || <span className="text-red-500">Not Set</span>}
            </p>
            <p>
              <strong>Upper Division Credits:</strong> {upperDivisionCredits}
            </p>
            <p>
              <strong>Overall Credits:</strong> {overallCredits}
            </p>
            {/* New: Faculty */}
            <p>
              <strong>Faculty:</strong> {faculty || <span className="text-red-500">Not Set</span>}
            </p>
            {/* New: Number of Years */}
            <p>
              <strong>Number of Years:</strong> {numberOfYears || <span className="text-red-500">Not Set</span>}
            </p>
            {/* New: Based On Major (Conditional) */}
            {isBasedOnAnotherMajor && (
              <p>
                <strong>Based On Major:</strong> {basedOnMajor || <span className="text-red-500">Not Set</span>}
              </p>
            )}
            {/* Optional: Display Pathway Type */}
            <p>
              <strong>Pathway Type:</strong> {isBasedOnAnotherMajor ? 'Based on Another Major' : 'Independent'}
            </p>
          </div>
        </div>
      </div>

      {/* Courses */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Courses</h3>
        {courses.length === 0 ? (
          <p className="text-gray-500">No courses added.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg shadow-sm">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b text-left">Course Number</th>
                  <th className="py-3 px-4 border-b text-left">Year</th>
                  <th className="py-3 px-4 border-b text-left">Semester</th>
                  <th className="py-3 px-4 border-b text-left">Possibilities</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.courseInfo_courseNumber} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{course.courseInfo_courseNumber}</td>
                    <td className="py-2 px-4 border-b">{course.courseYear}</td>
                    <td className="py-2 px-4 border-b">{course.CourseSem}</td>
                    <td className="py-2 px-4 border-b">{course.Description}</td>
                    <td className="py-2 px-4 border-b">
                      {renderPossibilities(course.Possibilities)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Electives */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Electives</h3>
        {electives.length === 0 ? (
          <p className="text-gray-500">No electives added.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg shadow-sm">
              <thead>
                <tr>
                <th className="py-3 px-4 border-b text-left">Requirement</th>
                  <th className="py-3 px-4 border-b text-left">Category</th>
                  <th className="py-3 px-4 border-b text-left">Year</th>
                  <th className="py-3 px-4 border-b text-left">Semester</th>
                </tr>
              </thead>
              <tbody>
                {electives.map((elective, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{elective.requirement}</td>
                    <td className="py-2 px-4 border-b">{elective.category}</td>
                    <td className="py-2 px-4 border-b">{elective.courseYear}</td>
                    <td className="py-2 px-4 border-b">{elective.CourseSem}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Requirements Section with Possibilities Display */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Requirements</h3>
        {requirements.length === 0 ? (
          <p className="text-gray-500">No requirements added.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg shadow-sm">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b text-left">Name</th>
                  <th className="py-3 px-4 border-b text-left">Year</th>
                  <th className="py-3 px-4 border-b text-left">Semester</th>
                  <th className="py-3 px-4 border-b text-left">Description</th>
                  <th className="py-3 px-4 border-b text-left">Possibilities</th> {/* New Column */}
                </tr>
              </thead>
              <tbody>
                {requirements.map((requirement, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{requirement.RequirementsName}</td>
                    <td className="py-2 px-4 border-b">{requirement.courseYear}</td>
                    <td className="py-2 px-4 border-b">{requirement.CourseSem}</td>
                    <td className="py-2 px-4 border-b">{requirement.RequirementsDesc}</td>
                    <td className="py-2 px-4 border-b">
                      {/* Render Possibilities */}
                      {renderPossibilities(requirement.Possibilities)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Milestones */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Milestones</h3>
        {milestones.length === 0 ? (
          <p className="text-gray-500">No milestones added.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg shadow-sm">
              <thead>
                <tr>
                <th className="py-3 px-4 border-b text-left">Title</th>
                  <th className="py-3 px-4 border-b text-left">Description</th>
                  <th className="py-3 px-4 border-b text-left">Year</th>
                  <th className="py-3 px-4 border-b text-left">Semester</th>
                </tr>
              </thead>
              <tbody>
                {milestones.map((milestone, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{milestone.title}</td>
                    <td className="py-2 px-4 border-b">{milestone.Description}</td>
                    <td className="py-2 px-4 border-b">{milestone.courseYear}</td>
                    <td className="py-2 px-4 border-b">{milestone.CourseSem}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewSection;
