// Helper function to calculate the percentage change between two values
export const calculatePercentageChange = (oldValue, newValue) => {
    if (oldValue === 0) return 0; // Avoid division by zero
    return Math.round(((newValue - oldValue) / oldValue) * (100 / 2));
  };
  
  // Helper function to find the enrollment total for a specific term
  export const findEnrollmentTotal = (enrollmentData, term) => {
    const termData = enrollmentData.find(data => data.term === term);
    return termData ? termData.Total : 0; // Return 0 if the term is not found
  };
  
  // Function to calculate historical and forecasted trends
  export const calculateEnrollmentTrends = (enrollmentData, referenceTerms) => {
    const {
      fall: { historical: fallHistTerms, forecasted: fallForecastTerms },
      spring: { historical: springHistTerms, forecasted: springForecastTerms },
      summer: { historical: summerHistTerms, forecasted: summerForecastTerms },
    } = referenceTerms;
  
    return {
      fall: {
        historical: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, fallHistTerms[0]),
          findEnrollmentTotal(enrollmentData, fallHistTerms[1])
        ),
        forecasted: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, fallForecastTerms[0]),
          findEnrollmentTotal(enrollmentData, fallForecastTerms[1])
        ),
      },
      spring: {
        historical: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, springHistTerms[0]),
          findEnrollmentTotal(enrollmentData, springHistTerms[1])
        ),
        forecasted: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, springForecastTerms[0]),
          findEnrollmentTotal(enrollmentData, springForecastTerms[1])
        ),
      },
      summer: {
        historical: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, summerHistTerms[0]),
          findEnrollmentTotal(enrollmentData, summerHistTerms[1])
        ),
        forecasted: calculatePercentageChange(
          findEnrollmentTotal(enrollmentData, summerForecastTerms[0]),
          findEnrollmentTotal(enrollmentData, summerForecastTerms[1])
        ),
      },
    };
  };
  