import React from "react";

const IncompletePrereqsWarningModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 w-96">
        <h2 className="text-xl font-bold text-lg text-center mb-4">Incomplete Data Warning</h2>
        <p className="mb-4">
          This application is still in testing. Some prerequisites may be incomplete or missing.
        </p>
        <p className="mb-4">
          If you notice any inconsistencies or inaccuracies within planning
          resources or bugs in the application, please report them.
        </p>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncompletePrereqsWarningModal;
