import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import CollapsibleClassSearchEditPage from './CollapsibleClassSearchEditPage';

const CourseSearch = ({ loading, filteredData, handleAddClass, onLoadMore }) => {
  const navigate = useNavigate();
  const scrollableDivRef = useRef(null);
  const sentinelRef = useRef(null);

  const handleAddCourse = () => {
    navigate('/add-course'); // Ensure this route is correctly set up
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      },
      {
        threshold: 0,
        root: scrollableDivRef.current,
        rootMargin: '100px',
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [onLoadMore]);

  return (
    <div className="p-4 bg-gray-50 shadow-md border rounded-lg flex flex-col h-full">
      {/* Header with Centered Title */}
      <div className="flex items-center justify-center mb-4 relative">
        <h5 className="text-lg font-bold text-center">Courses</h5>
      </div>

      {/* Courses Header */}
      <div className="grid grid-cols-12 font-semibold text-sm text-gray-500 mb-2">
        <div className="text-left col-span-5 ml-12 mt-3">
          Course ID
          <FontAwesomeIcon icon={faSort} className="mb-0.5 ml-1" style={{ fontSize: '10px' }} />
        </div>
        <div className="text-center col-span-3 ml-2 mt-3">
          Course Name
          <FontAwesomeIcon icon={faSort} className="mb-0.5 ml-1" style={{ fontSize: '10px' }} />
        </div>
        {/* Add other columns if needed */}
        <div className="col-span-4 text-right mr-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-3 rounded"
            onClick={handleAddCourse}
          >
            Add Course
          </button>
        </div>
      </div>

      {/* Courses List with Infinite Scroll */}
      <div
        className="flex-1 overflow-y-auto"
        style={{ minHeight: 'calc(100vh - 19rem)', maxHeight: 'calc(100vh - 19rem)' }}
        ref={scrollableDivRef}
      >
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
            >
              {/* SVG Path */}
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : filteredData.length > 0 ? (
          filteredData.map((classData, index) => (
            <CollapsibleClassSearchEditPage
              key={index}
              classData={classData}
              onAddClass={handleAddClass}
              isRec={false}
            />
          ))
        ): (
          <p className="text-center text-gray-500">No courses found.</p>
        )}
      </div>
    </div>
  );
};

export default CourseSearch;
