import React, { useState, useEffect } from 'react';
import { TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import StudentDistribution from './Student/StudentDistribution';
import PopularPrograms from './Student/PopularPrograms';
import GPABreakdown from './Student/GPABreakdown';
import RelevantStudents from './Student/RelevantStudents';
import 'flowbite'; // Import flowbite JavaScript
import { db } from '../../firebase';

const StudentsAnalytics = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    gradYear: [],
    program: [],
    performance: [],
    advisor: [],
  });
  const [dropdownVisibility, setDropdownVisibility] = useState({
    gradYear: false,
    program: false,
    performance: false,
    advisor: false,
  });
  const [myStudents, setMyStudents] = useState(false);

  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const response = await fetch('/students.json');
        const jsonData = await response.json();

        const snapshot = await db.collection('users').where('roles', 'array-contains', 'student').get();
        const firestoreData = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            fromFirestore: true,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email || 'N/A',
            major: data.major || 'N/A',
            minor: data.minor || 'No Minor',
            gpa: data.gpa || 'N/A',
            gradYear: data.gradYear || 'N/A',
            classesTaken: data.classesTaken || 0,
            classesRegistered: data.classesRegistered || 0,
            advisor: data.advisor || 'N/A',
          };
        });

        const combinedData = [...jsonData, ...firestoreData];
        setStudents(combinedData);
        setFilteredStudents(combinedData);
      } catch (error) {
        console.error('Error fetching students data:', error);
      }
    };

    fetchStudentsData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, filters, myStudents]);

  const applyFilters = () => {
    let filtered = students;

    if (searchTerm) {
      filtered = filtered.filter(student =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filters.gradYear.length) {
      filtered = filtered.filter(student => filters.gradYear.includes(student.gradYear));
    }

    if (filters.program.length) {
      filtered = filtered.filter(student => filters.program.includes(student.major));
    }

    if (filters.performance.length) {
      filtered = filtered.filter(student => {
        const gpa = parseFloat(student.gpa);
        return filters.performance.some(range => {
          if (range === '< 2.0') {
            return gpa < 2.0;
          } else {
            const [min, max] = range.split('-').map(parseFloat);
            return gpa >= min && gpa <= max;
          }
        });
      });
    }

    if (filters.advisor.length || myStudents) {
      filtered = filtered.filter(student => filters.advisor.includes(student.advisor) || (myStudents && student.advisor === 'Advisor 1'));
    }

    setFilteredStudents(filtered);
  };

  const handleCheckboxChange = (category, value) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      const index = newFilters[category].indexOf(value);
      if (index > -1) {
        newFilters[category] = newFilters[category].filter(item => item !== value);
      } else {
        newFilters[category] = [...newFilters[category], value];
      }
      return newFilters;
    });
  };

  const toggleDropdownVisibility = (category) => {
    setDropdownVisibility(prevVisibility => ({
      ...prevVisibility,
      [category]: !prevVisibility[category]
    }));
  };

  const renderDropdown = (category, label, options) => (
    <div className="relative mt-2">
      <div className="flex flex-col items-center">
        <button
          className="flex items-center justify-center bg-white text-blue-600 border border-blue-600 rounded-lg text-sm px-5 py-2.5 inline-flex"
          type="button"
          onClick={() => toggleDropdownVisibility(category)}
        >
          {label}
          <svg className="w-4 h-4 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6" stroke="currentColor" strokeWidth="2">
            <path d="M1 1l4 4 4-4" stroke="currentColor" />
          </svg>
        </button>
        {dropdownVisibility[category] && (
          <div className="z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 absolute top-full mt-2">
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              {options.map((item, idx) => (
                <li key={idx} className="px-4 py-2 flex items-center">
                  <input
                    type="checkbox"
                    id={`${category}-${item}`}
                    name={category}
                    value={item}
                    checked={filters[category].includes(item)}
                    onChange={() => handleCheckboxChange(category, item)}
                    className="form-checkbox rounded-full"
                  />
                  <label htmlFor={`${category}-${item}`} className="ml-2 text-gray-700">{item}</label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  const toggleMyStudents = () => {
    setMyStudents(prev => !prev);
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-4">
        <div className="mt-8 w-full max-w-6xl mx-auto bg-white p-4 rounded-lg shadow">
          <div className="relative w-full mb-4">
            <TextInput
              id="search-student"
              type="text"
              placeholder="Search for students"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 w-full"
            />
            <HiSearch className="absolute left-2 top-2 w-6 h-6 text-gray-500" />
          </div>
          <div className="flex flex-wrap justify-center space-x-4 mb-4">
            {renderDropdown('gradYear', 'Grad Year', ['2022', '2023', '2024', '2025', '2026', 'Graduated'])}
            {renderDropdown('program', 'Program', ['Mechanical Engineering', 'Mathematics', 'Biomedical Engineering', 'History', 'Nursing', 'Information Technology', 'Education', 'Biology', 'Environmental Science', 'Business Administration'])}
            {renderDropdown('performance', 'Performance', ['4.0-4.0', '3.5-3.9', '3.0-3.4', '2.5-2.9', '2.0-2.4', '< 2.0'])}
            {renderDropdown('advisor', 'Advisors', ['Advisor 1', 'Advisor 2', 'Advisor 3', 'Advisor 4'])}
            <button
              className={`flex items-center justify-center mt-2 ${myStudents ? 'bg-blue-600 text-white' : 'bg-white text-blue-600'} border border-blue-600 rounded-lg text-sm px-5 py-2.5`}
              onClick={toggleMyStudents}
            >
              My Students
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
        <StudentDistribution students={filteredStudents} />
        <GPABreakdown students={filteredStudents} />
        <PopularPrograms students={filteredStudents} />
      </div>
      <div className="mt-6 grid grid-cols-1">
        <RelevantStudents students={filteredStudents} />
      </div>
    </div>
  );
};

export default StudentsAnalytics;
