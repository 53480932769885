// StudentsPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import StudentListing from './StudentListing/StudentListing';
import StudentGroups from './Groups/StudentGroups';
import StudentInitiatives from './Initiatives/StudentInitiatives';
import CustomSidebar from '../Sidebar/Sidebar';
import { db, auth } from '../../firebase';

const StudentsPage = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  
  // Active tab: "listing" | "groups" | "initiatives"
  const [activeTab, setActiveTab] = useState('listing');

  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          return;
        }

        // Fetch students (role: student)
        const snapshot = await db
          .collection('users')
          .where('role', '==', 'student')
          .get();

        const firestoreData = snapshot.docs.map((doc) => {
          const data = doc.data();
          const majors = data.surveyAnswers?.Majors || 'No Major';
          const minors = data.surveyAnswers?.Minors || 'No Minor';
          const planned = data.courseProgress?.planned || 0;
          const taken = data.courseProgress?.taken || 0;
          const total = data.courseProgress?.total || 0;

          return {
            id: doc.id,
            fromFirestore: true,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email || 'N/A',
            firstName: data.firstName,
            lastName: data.lastName,
            GPA: data.surveyAnswers?.GPA,
            surveyAnswers: data.surveyAnswers,
            major: majors,
            minor: minors,
            planned,
            taken,
            total,
            classesTaken: data.classesTaken || 0,
            classesRegistered: data.classesRegistered || 0,
            lastLoginDate: data.lastLoginDate,
            advisor: data.advisor || '',
          };
        });

        setStudents(firestoreData);
        setFilteredStudents(firestoreData);
      } catch (error) {
        console.error('Error fetching students data:', error);
      }
    };

    const fetchAdvisorsData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          return;
        }
        const snapshot = await db.collection('advisors').get();
        const firestoreAdvisorsSnapshot = snapshot.docs.map((advisor) => advisor.data());
        const firestoreAdvisorsData = firestoreAdvisorsSnapshot.map(
          (advisor) => `${advisor.firstName} ${advisor.lastName}`
        );
        setAdvisors(firestoreAdvisorsData);
      } catch (error) {
        console.error('Error fetching advisors data:', error);
      }
    };

    fetchStudentsData();
    fetchAdvisorsData();
  }, []);

  // Example search
  const handleSearch = useCallback(
    (searchTerm) => {
      const filtered = students.filter((student) =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStudents(filtered);
    },
    [students]
  );

  // Example filter
  const handleFilter = useCallback(
    (filters) => {
      let filtered = [...students];
      // implement your filter logic here
      setFilteredStudents(filtered);
    },
    [students]
  );

  return (
    <div className="flex">
      {/* SIDEBAR */}
      <CustomSidebar adjustment="Student Search" />

      {/* MAIN CONTENT AREA */}
      <div className="mt-4 min-h-[96vh] ml-[4vw] min-w-[94vw] max-w-[92vw] flex-1 px-3">

        {/* TOP TAB BAR: fixed style so no vertical shift */}
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-3">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('listing')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'listing'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Student Listing
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('groups')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'groups'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Student Groups
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('initiatives')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'initiatives'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Initiatives
              </button>
            </li>
          </ul>
        </div>

        {/* TAB CONTENT */}
        {activeTab === 'listing' && (
          <StudentListing
            students={filteredStudents}
            advisors={advisors}
            onSearch={handleSearch}
            onFilter={handleFilter}
          />
        )}

        {activeTab === 'groups' && (
          <StudentGroups
            students={students}
            advisors={advisors}
          />
        )}

        {activeTab === 'initiatives' && (
          <StudentInitiatives />
        )}
      </div>
    </div>
  );
};

export default StudentsPage;
