import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsThreeDots } from 'react-icons/bs';

const TagList = ({ tags }) => {
    const [showAll, setShowAll] = useState(false);

    if (!Array.isArray(tags) || tags.length === 0) {
        return (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                No Tags Selected
            </span>
        );
    }

    const handleToggle = () => setShowAll(!showAll);

    return (
        <div className="relative">
            <div
                className={`flex flex-wrap items-center ${showAll ? 'max-h-none' : 'max-h-12 overflow-hidden'} transition-all`}
            >
                {tags.map((tag, index) => (
                    <div
                        key={index}
                        className={`flex items-center ml-2 mr-2 mb-1 ${!showAll && index >= 3 ? 'hidden' : ''}`}
                        style={{ lineHeight: '1.2' }} // Ensures better alignment of text and icon
                    >
                        <FontAwesomeIcon
                            icon={faTag}
                            className="mr-1"
                            style={{ verticalAlign: 'middle', lineHeight: 'inherit' }} // Vertically align the icon
                        />
                        <span className="text-sm leading-none">{tag}</span>
                    </div>
                ))}
                {showAll && (
                    <button
                        onClick={handleToggle}
                        className="rounded bg-gray-100 px-1 py-1 flex items-center justify-center hover:text-blue-500 hover:underline"
                        aria-label="Hide"
                    >
                        <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                    </button>
                )}
            </div>
            {tags.length > 3 && !showAll && (
                <button
                    onClick={handleToggle}
                    className="absolute top-0 rounded right-0 bg-gray-100 px-1 py-.5 flex items-center justify-center hover:text-blue-500 hover:underline"
                    aria-label="Toggle"
                    style={{ right: '-18px' }} // Move button further right
                >
                    <BsThreeDots size={15} />
                </button>
            )}
        </div>
    );
};

export default TagList;
