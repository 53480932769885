import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PathwayCollapsibleClassSearch from './PathwayCollapsibleClassSearch';
import PathwayClassCart from './PathwayClassCart';
import { useAuth } from '../../../../contexts/AuthContext';
import { Modal } from '@mui/material';
import MultiSelect from './MultiSelect';
import debounce from 'lodash.debounce';

const PathwayClassSearchOverlay = ({ onClose, onAddClass, currentPathway }) => {
  const { fetchedCourseData } = useAuth();

  const [displayedData, setDisplayedData] = useState([]);
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [classesInCart, setClassesInCart] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResultsCount, setFilteredResultsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [duplicateClassMessage, setDuplicateClassMessage] = useState('');
  const sentinelRef = useRef(null);

  // Multiselect filter states
  const [levelFilter, setLevelFilter] = useState([]);
  const [majorFilter, setMajorFilter] = useState([]);
  const [creditsFilter, setCreditsFilter] = useState([]);

  // Debounce delay in milliseconds
  const DEBOUNCE_DELAY = 300;

  // Fetch data on mount or when fetchedCourseData changes
  useEffect(() => {
    const fetchData = () => {
      try {
        setData(fetchedCourseData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchedCourseData]);

  // Extract unique filter options using useMemo for performance
  const uniqueLevels = useMemo(() => {
    if (!data) return [];
    const levels = new Set();
    Object.values(data).forEach((item) => {
      if (item.courseYear) levels.add(item.courseYear);
    });
    return Array.from(levels).sort((a, b) => parseInt(a) - parseInt(b));
  }, [data]);

  const uniqueMajors = useMemo(() => {
    if (!data) return [];
    const majors = new Set();
    Object.values(data).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [data]);

  const uniqueCredits = useMemo(() => {
    if (!data) return [];
    const credits = new Set();
    Object.values(data).forEach((item) => {
      if (item.Credits) credits.add(item.Credits);
    });
    return Array.from(credits).sort((a, b) => parseFloat(a) - parseFloat(b));
  }, [data]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(
    () => uniqueMajors,
    [uniqueMajors]
  );

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit}`),
    [uniqueCredits]
  );

  // Debounced filter function
  const debouncedFilter = useCallback(
    debounce(() => {
      filterDataAndSetDisplay();
    }, DEBOUNCE_DELAY),
    [
      data,
      searchQuery,
      levelFilter,
      majorFilter,
      creditsFilter,
      currentPathway,
    ]
  );

  // Filter data whenever dependencies change, using debounce
  useEffect(() => {
    debouncedFilter();

    // Cleanup debounce on unmount
    return debouncedFilter.cancel;
  }, [
    data,
    searchQuery,
    levelFilter,
    majorFilter,
    creditsFilter,
    currentPathway,
    debouncedFilter,
  ]);

  // Filtering logic
  const filterDataAndSetDisplay = () => {
    if (data) {
      const filtered = Object.values(data).filter((item) => {
        const majors = item.Major
          ? item.Major.split(',').map((major) => major.trim())
          : [];

        // Prevent adding classes already in the pathway
        const isAlreadyInPathway =
          (item.courseType === 'Major Course' &&
            currentPathway.courses.some(
              (course) =>
                course.courseInfo_courseNumber === item.courseInfo_courseNumber
            )) ||
          (item.courseType === 'Elective' &&
            currentPathway.electives.some(
              (elective) =>
                elective.courseInfo_courseNumber === item.courseInfo_courseNumber
            )) ||
          (item.courseType === 'Requirement' &&
            currentPathway.requirements.some(
              (requirement) =>
                requirement.courseInfo_courseNumber === item.courseInfo_courseNumber
            )) ||
          (item.courseType === 'Milestone' &&
            currentPathway.milestones.some(
              (milestone) =>
                milestone.courseInfo_courseNumber === item.courseInfo_courseNumber
            ));

        if (isAlreadyInPathway) {
          return false;
        }

        // Level Filter
        if (
          levelFilter.length > 0 &&
          !levelFilter.some(
            (filter) => parseInt(filter) === parseInt(item.courseYear)
          )
        ) {
          return false;
        }

        // Major Filter
        if (
          majorFilter.length > 0 &&
          !majorFilter.some((filter) => majors.includes(filter))
        ) {
          return false;
        }

        // Credits Filter
        if (
          creditsFilter.length > 0 &&
          !creditsFilter.some(
            (filter) => parseFloat(filter) === parseFloat(item.Credits)
          )
        ) {
          return false;
        }

        // Search Query
        const nameMatch = item.courseInfo_courseName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const numberMatch = item.courseInfo_courseNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        return nameMatch || numberMatch;
      });

      setFilteredData(filtered);
      setDisplayedData(filtered.slice(0, 100));
      setFilteredResultsCount(filtered.length);
    }
  };

  // Infinite scroll: Load more data when sentinel is intersecting
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          displayedData.length < filteredData.length
        ) {
          loadMoreData();
        }
      },
      {
        threshold: 0,
        root: sentinelRef.current
          ? sentinelRef.current.parentElement
          : null,
        rootMargin: '100px',
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const loadMoreData = () => {
    const newData = filteredData.slice(0, displayedData.length + 100);
    setDisplayedData(newData);
  };

  // Reset all filters
  const handleResetFilters = () => {
    setLevelFilter([]);
    setMajorFilter([]);
    setCreditsFilter([]);
    setSearchQuery('');
  };

  // Handle adding a class to the cart
  const handleAddClass = (classData) => {
    const isClassAlreadyAdded =
      classesInCart.some(
        (cartItem) =>
          cartItem.courseInfo_courseNumber === classData.courseInfo_courseNumber
      ) ||
      currentPathway.courses.some(
        (course) =>
          course.courseInfo_courseNumber === classData.courseInfo_courseNumber
      );

    if (!isClassAlreadyAdded) {
      setClassesInCart([
        ...classesInCart,
        {
          ...classData,
          courseInfo_courseNumber: classData.courseInfo_courseNumber,
        },
      ]);
      setDuplicateClassMessage('');
    } else {
      setDuplicateClassMessage(
        `Class with course number ${classData.courseInfo_courseNumber} is already in your cart or pathway.`
      );
    }
  };

  // Handle removing a class from the cart
  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter(
      (classData) => classData !== classToRemove
    );
    setClassesInCart(updatedClassesInCart);
  };

  // Handle adding classes from the cart to the pathway
  const handleAddToPathway = () => {
    if (classesInCart.length > 0) {
      onAddClass(classesInCart);
      setClassesInCart([]);
      onClose();
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-2/3 p-6 overflow-y-auto max-h-full relative">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            onClick={onClose}
            aria-label="Close"
          >
            &#10005;
          </button>

          <div className="grid grid-cols-10 gap-3">
            {/* Filters Section */}
            <div className="col-span-2">
              <div className="p-3 bg-gray-100 rounded-lg">
                <h5 className="text-lg text-center font-bold mb-4">Filters</h5>
                {/* Search Query */}
                <div className="mb-2">
                  <label
                    htmlFor="searchQuery"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Search:
                  </label>
                  <input
                    type="text"
                    id="searchQuery"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="Search by course number, name, etc."
                  />
                </div>
                {/* Level Filter */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Level:
                  </label>
                  <MultiSelect
                    suggestions={levelSuggestions}
                    value={levelFilter}
                    onChange={(value) => setLevelFilter(value)}
                    placeholder="Select Level(s)"
                    required={false}
                  />
                </div>
                {/* Major Filter */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Major:
                  </label>
                  <MultiSelect
                    suggestions={majorSuggestions}
                    value={majorFilter}
                    onChange={(value) => setMajorFilter(value)}
                    placeholder="Select Major(s)"
                    required={false}
                  />
                </div>
                {/* Credits Filter */}
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Credits:
                  </label>
                  <MultiSelect
                    suggestions={creditsSuggestions}
                    value={creditsFilter}
                    onChange={(value) => setCreditsFilter(value)}
                    placeholder="Select Credit(s)"
                    required={false}
                  />
                </div>
                {/* Reset Filters Button */}
                <button
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1 transition-colors duration-200"
                  onClick={handleResetFilters}
                >
                  Reset Filters
                </button>
                {/* Display Courses Count */}
                <div className="flex justify-center mt-2">
                  <span className="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
                    {filteredResultsCount} Courses Displayed
                  </span>
                </div>
                {/* Display Duplicate Class Message */}
                {duplicateClassMessage && (
                  <p className="text-red-600 mt-2 text-center">
                    {duplicateClassMessage}
                  </p>
                )}
              </div>
            </div>

            {/* Courses List Section */}
            <div className="col-span-6">
              <div className="p-3 bg-gray-100 rounded-lg w-full min-h-[600px]">
                <h5 className="text-lg text-center font-bold mb-4">Courses</h5>
                <div className="h-[550px] overflow-y-auto rounded">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div>
                      {displayedData.map((classData, index) => (
                        <PathwayCollapsibleClassSearch
                          key={index}
                          classData={classData}
                          onAddClass={handleAddClass}
                          inPlan={false}
                        />
                      ))}
                    </div>
                  )}
                  {/* Sentinel for Infinite Scroll */}
                  <div ref={sentinelRef}></div>
                </div>
              </div>
            </div>

            {/* Class Cart Section */}
            <div className="col-span-2">
              <PathwayClassCart
                classesInCart={classesInCart}
                onRemoveClass={handleRemoveClass}
                onAddToPathway={handleAddToPathway}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PathwayClassSearchOverlay;
