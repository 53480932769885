// StudentItem.js
import React, { memo, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../../firebase';

function generateWeightedRandomDateString() {
  // Define month weights
  const totalWeight = 6; // 1 (Nov) + 2 (Dec) + 3 (Jan)
  const rand = Math.random() * totalWeight;
  let selectedMonth;
  
  // Select month based on weights
  if (rand < 1) {
    selectedMonth = 'november';
  } else if (rand < 1 + 2) {  // 1 (Nov) + 2 (Dec)
    selectedMonth = 'december';
  } else {
    selectedMonth = 'january';
  }

  let year, monthIndex, maxDays;
  if (selectedMonth === 'november') {
    year = 2024;
    monthIndex = 10;    // November (months are 0-indexed)
    maxDays = 30;
  } else if (selectedMonth === 'december') {
    year = 2024;
    monthIndex = 11;    // December
    maxDays = 31;
  } else {  // january
    year = 2025;
    monthIndex = 0;     // January
    maxDays = 15;       // limit to January 15, 2025 as per the range
  }

  const day = Math.floor(Math.random() * maxDays) + 1;
  const randomDate = new Date(year, monthIndex, day);
  
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return randomDate.toLocaleDateString('en-US', options);
}

const StudentItem = memo(({
  student,
  isSelected,
  onCheckboxChange,
  adjustment,
  selectedResource,
  handleRecommendResource
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [generatedDate, setGeneratedDate] = useState(null);

  useEffect(() => {
    // If lastLoginDate is missing, generate one and update Firestore
    if (!student.lastLoginDate) {
      const randomDate = generateWeightedRandomDateString();
      setGeneratedDate(randomDate);  // Store locally for immediate display
      
      const updateFirestore = async () => {
        try {
          const studentRef = doc(db, 'users', student.id);
          await updateDoc(studentRef, { lastLoginDate: randomDate });
        } catch (error) {
          console.error('Error updating lastLoginDate:', error);
        }
      };
      updateFirestore();
    }
  }, [student.lastLoginDate, student.id]);

  const handleRecommend = async (e) => {
    if (selectedResource && !student.resources?.includes(selectedResource.id)) {
      try {
        const studentRef = doc(db, 'users', student.id);
        await updateDoc(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
        onCheckboxChange(student.id, true);
      } catch (error) {
        console.error('Error recommending resource:', error);
      }
    }
  };

  const resourceRecommended = selectedResource
    ? (student.resources || []).includes(selectedResource.id)
    : false;

  return (
    <div
      className="border bg-white rounded-lg p-1.5 mb-2 cursor-pointer hover:bg-gray-100"
      onClick={() => {
        if (adjustment !== 'Resources') {
          // Additional onClick logic if needed
        } else {
          if (!resourceRecommended) {
            handleRecommend();
          }
        }
      }}
    >
      <div className="grid grid-cols-12 items-center">
        {adjustment !== 'Resources' && (
          <div className="text-left col-span-1">
            <input
              type="checkbox"
              checked={isSelected}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => onCheckboxChange(student.id, e.target.checked)}
              className="ml-5 w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500"
            />
          </div>
        )}

        <div className="text-left col-span-3">
          <div className="flex items-center">
            {student.photoURL ? (
              <img
                src={student.photoURL}
                alt="Profile"
                className="w-8 h-8 rounded-full mr-2"
              />
            ) : (
              <div className="w-8 h-8 rounded-full mr-2 flex items-center justify-center bg-gray-400 text-white font-semibold">
                {student.name
                  .split(' ')
                  .map((namePart) => namePart[0])
                  .join('')
                  .substring(0, 2)}
              </div>
            )}
            <div>
              <h4 className="font-bold text-black">{student.name}</h4>
              <p className="text-sm text-gray-500">{student.email}</p>
            </div>
          </div>
        </div>

        <div className="text-center text-sm col-span-3">
          {Array.isArray(student.major) ? student.major.join(', ') : student.major}
        </div>

        <div className="text-center text-sm ml-7 col-span-1">
          {student.surveyAnswers?.SchoolEnd?.year || 'N/A'}
        </div>

        {console.log(student, 'hiasdf', student.lastLoginDate)}

        {/* Display Last Login Date */}
        <div className="col-span-2 text-right mr-5 text-sm">
          {student.lastLoginDate || generatedDate || ''}
        </div>

        <div className="text-right ml-2 px-3 col-span-2">
          {adjustment !== 'Resources' ? (
            <div className="ml-auto">
              <div className="flex border border-blue-700 rounded overflow-hidden text-xs font-medium">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/Profile/${student.id}`);
                  }}
                  className="flex-1 text-blue-700 hover:text-white hover:bg-blue-800 py-1 px-1 transition-all duration-300 focus:outline-none"
                >
                  Profile
                </button>
                
                <div className="w-px bg-gray-300"></div>
                
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/Plan/${student.id}`);
                  }}
                  className="flex-1 text-blue-700 hover:text-white hover:bg-blue-800 py-1 px-1 transition-all duration-300 focus:outline-none"
                >
                  Plan
                </button>
              </div>
            </div>   
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!resourceRecommended) {
                  handleRecommendResource(student.id);
                }
              }}
              className={`${
                resourceRecommended
                  ? 'text-green-700 hover:bg-green-700 cursor-not-allowed'
                  : 'text-blue-700 hover:bg-blue-700'
              } hover:text-white border border-blue-700 focus:outline-none font-medium rounded text-xs px-2 py-1 transition-all duration-300 whitespace-nowrap`}
              disabled={resourceRecommended}
            >
              {resourceRecommended ? 'Recommended' : 'Recommend Resource'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default StudentItem;