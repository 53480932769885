// SchoolPortals.js
import React from 'react';

const SchoolPortals = () => {
    return (
        <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
            <h2 className="text-gray-700 font-semibold mb-2">School Portals</h2>
            <ul className="my-4 space-y-3">
                <li>
                    <a 
                        href={process.env.REACT_APP_GENERAL_PORTAL} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    >
                        <img 
                            src={process.env.REACT_APP_GENERAL_PORTAL_PICTURE} 
                            alt="General Profile" 
                            className="h-10 w-10 object-contain object-center" 
                        />
                        <span className="flex-1 ms-3 whitespace-nowrap">General Portal</span>
                    </a>
                </li>
                <li>
                    <a 
                        href={process.env.REACT_APP_ADVISOR_PORTAL} 
                        target="_blank"
                        rel="noopener noreferrer" 
                        className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    >
                        <img 
                            src={'https://i.postimg.cc/nhZ7d9cf/advisorportalpic.png'} 
                            alt="Advisor Profile" 
                            className="h-10 w-10 object-cover object-center" 
                        />
                        <span className="flex-1 ms-3 whitespace-nowrap">Advisor Portal</span>
                    </a>
                </li>
                <li>
                    <a 
                        href={process.env.REACT_APP_COURSE_SEARCH} 
                        target="_blank"
                        rel="noopener noreferrer" 
                        className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    >
                        <img 
                            src={'https://i.postimg.cc/MG3QKs0z/classsearchpic.png'} 
                            alt="Course Profile" 
                            className="h-10 w-10 object-cover object-center" 
                        />
                        <span className="flex-1 ms-3 whitespace-nowrap">Course Search</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default SchoolPortals;
