import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const GradeDistributionChart = ({ data }) => {
  if (!data) {
    data = [
        { grade: "A", count: 22 }, // Random between 20-24
        { grade: "A-", count: 18 }, // Random between 25-29
        { grade: "B+", count: 35 }, // Random between 35-39
        { grade: "B", count: 50 }, // Random between 50-54
        { grade: "B-", count: 40 }, // Random between 40-44
        { grade: "C+", count: 12 }, // Random between 30-34
        { grade: "C", count: 20 }, // Random between 20-24
        { grade: "C-", count: 15 }, // Random between 15-19
        { grade: "D", count: 10 }, // Random between 10-12
        { grade: "F", count: 12 }, // Random between 8-10
        { grade: "W", count: 5 }, // Random between 5-6
        { grade: "N/A", count: 3 }, // Random between 3-4
    ];
  }

  const totalGrades = data.reduce((sum, item) => sum + item.count, 0);

  const chartData = {
    labels: data.map((item) => item.grade), // X-axis labels
    datasets: [
      {
        label: "Grade Distribution Percentage",
        data: data.map((item) => ((item.count / totalGrades) * 100).toFixed(2)), // Convert to percentages
        backgroundColor: [
          "#4caf50",
          "#8bc34a",
          "#cddc39",
          "#ffeb3b",
          "#ffc107",
          "#ff9800",
          "#f44336",
          "#e91e63",
          "#9c27b0",
          "#673ab7",
          "#607d8b", // Color for "W"
          "#9e9e9e", // New color for "N/A"
        ],
        hoverBackgroundColor: [
          "#45a049",
          "#76b042",
          "#b0c338",
          "#e0d829",
          "#d6a306",
          "#e0870d",
          "#e63228",
          "#c21c57",
          "#82248e",
          "#512888",
          "#506070", // Hover color for "W"
          "#7e7e7e", // New hover color for "N/A"
        ],
        borderColor: "#ffffff",
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`; // Show percentage value
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        title: {
          display: true,
          text: "Grades", // X-axis title
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Percentage of Total Grades", // Y-axis title
        },
        ticks: {
          callback: (value) => `${value}%`, // Append '%' to Y-axis ticks
        },
      },
    },
  };

  return (
    <div className="w-full flex items-center justify-center" style={{ width: "900px", height: "350px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default GradeDistributionChart;
