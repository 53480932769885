import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

// Function to fetch and embed images or fonts
async function fetchAndEmbedAsset(pdfDoc, url, type) {
  try {
    const response = await fetch(url);

    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`Failed to fetch ${type} from ${url}: ${response.statusText}`);
    }

    const assetBytes = await response.arrayBuffer();

    if (type === 'image') {
      return pdfDoc.embedPng(assetBytes);
    } else if (type === 'font') {
      return pdfDoc.embedFont(assetBytes);
    }
  } catch (error) {
    console.error(`Error fetching and embedding ${type}:`, error);
    throw error; // Re-throw the error to be caught later
  }
}

// Function to truncate text with ellipsis if it exceeds maxWidth
const truncateWithEllipsis = (text, font, fontSize, maxWidth) => {
  const ellipsis = '...';
  const ellipsisWidth = font.widthOfTextAtSize(ellipsis, fontSize);

  let currentWidth = font.widthOfTextAtSize(text, fontSize);
  if (currentWidth <= maxWidth) {
    return text;
  }

  let truncatedText = text;
  while (truncatedText.length > 0) {
    truncatedText = truncatedText.slice(0, -1);
    currentWidth = font.widthOfTextAtSize(truncatedText, fontSize) + ellipsisWidth;
    if (currentWidth <= maxWidth) {
      break;
    }
  }

  return truncatedText + ellipsis;
};

// Function to wrap text into multiple lines based on maxWidth
const wrapText = (text, font, fontSize, maxWidth) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = '';

  words.forEach((word) => {
    const testLine = currentLine ? `${currentLine} ${word}` : word;
    const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

    if (testLineWidth > maxWidth && currentLine) {
      lines.push(currentLine);
      currentLine = word;
    } else {
      currentLine = testLine;
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  return lines;
};

// Helper function to estimate the height required for a semester
const estimateSemesterHeight = (courses, lineHeight, bodyFontSize) => {
  const numCourses = courses.length;
  const semesterHeaderHeight = lineHeight;
  const coursesHeight = numCourses * lineHeight;
  const totalCreditsHeight = lineHeight + 10; // For 'Total Credits' line
  const semesterHeight = semesterHeaderHeight + coursesHeight + totalCreditsHeight;
  return semesterHeight;
};

// Function to create the PDF document
export const createPDFDocument = async (finalizedOrganizedCourses, currentUser) => {
  try {
    const pdfDoc = await PDFDocument.create();

    // Register fontkit
    pdfDoc.registerFontkit(fontkit);

    // Load custom fonts
    const robotoFont = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf`,
      'font'
    );
    const robotoBoldFont = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/fonts/Roboto-Bold.ttf`,
      'font'
    );

    // Fetch and embed the logo image
    const logoImage = await fetchAndEmbedAsset(
      pdfDoc,
      `${process.env.PUBLIC_URL}/edviseicon.png`,
      'image'
    );

    // Fetch the numYears from the active tab
    const activeTab = currentUser?.activeTab || '';
    const numYears = currentUser?.schedules?.[activeTab]?.numYears || 4; // Default to 4 years if numYears is not defined

    // Define constants for layout and styling
    const marginTop = 20; // Added top margin for better spacing
    const marginBottom = 50; // Adjusted bottom margin
    const marginLeft = 20; // Left margin
    const marginRight = 20; // Right margin
    const lineHeight = 15;
    const columnWidth = 270;
    const headerFontSize = 18;
    const subHeaderFontSize = 14;
    const bodyFontSize = 10;
    const headerColor = rgb(0, 0, 0); // Black color
    const subHeaderColor = rgb(0, 0, 0); // Black color
    const bodyTextColor = rgb(0, 0, 0);

    let page = pdfDoc.addPage([612, 792]); // Standard US Letter size (8.5 x 11 inches)
    let { width, height } = page.getSize();
    let yPosition = height - marginTop;

    // Define fixed areas
    const leftSectionWidth = 250; // Width allocated for logo and title
    const rightSectionWidth = width - marginLeft - marginRight - leftSectionWidth;

    // Function to add a new page
    const addNewPage = () => {
      page = pdfDoc.addPage([612, 792]);
      width = page.getSize().width;
      height = page.getSize().height;
      yPosition = height - marginTop;
      drawHeader(); // Draw the header for each new page
    };

    // Function to draw header information
    const drawHeader = () => {
      // Draw header background
      page.drawRectangle({
        x: 0,
        y: height - 80,
        width: width,
        height: 80,
        color: rgb(0.95, 0.95, 0.95), // Light gray background
      });

      // Draw the logo on the top-left corner
      const logoDims = logoImage.scale(0.03); // Make logo smaller
      page.drawImage(logoImage, {
        x: marginLeft,
        y: height - 70,
        width: logoDims.width,
        height: logoDims.height,
      });

      // Draw the document title next to the logo
      page.drawText(`${numYears}-Year Plan of Study`, {
        x: marginLeft + logoDims.width + 10, // Position next to logo with some spacing
        y: height - 50,
        size: headerFontSize,
        font: robotoBoldFont,
        color: headerColor,
      });

      // Define the starting X position for student info
      const studentInfoStartX = marginLeft + leftSectionWidth;

      // Define maximum widths within the right section
      const maxTotalInfoWidth = rightSectionWidth; // Total width available for labels and values

      // Student Information Labels and Values
      const studentInfoLabels = ['Student Name:', 'Major:', 'Minor:', 'Plan Name:'];
      const studentInfoValues = [
        `${currentUser?.firstName ?? 'N/A'} ${currentUser?.lastName ?? 'N/A'}`,
        `${currentUser?.surveyAnswers?.Majors?.join(', ') ?? 'N/A'}`,
        `${currentUser?.surveyAnswers?.Minors?.join(', ') ?? 'N/A'}`,
        `${currentUser?.activeTab ?? 'N/A'}`,
      ];

      const infoStartY = height - 20;
      const infoSpacing = 16;
      const smallSpace = 2; // Minimal space between label and value

      studentInfoLabels.forEach((label, index) => {
        const currentY = infoStartY - index * infoSpacing;

        // Truncate label if necessary (optional, since labels are standard)
        const truncatedLabel = truncateWithEllipsis(label, robotoBoldFont, bodyFontSize, maxTotalInfoWidth / 2);

        // Calculate the width of the truncated label
        const labelWidth = robotoBoldFont.widthOfTextAtSize(truncatedLabel, bodyFontSize);

        // Define the X position for the value right after the label with minimal space
        const valueX = studentInfoStartX + labelWidth + smallSpace;

        // Calculate the maximum width available for the value
        const maxValueWidth = maxTotalInfoWidth - labelWidth - smallSpace;

        // Truncate value if necessary
        const truncatedValue = truncateWithEllipsis(
          studentInfoValues[index],
          robotoFont,
          bodyFontSize,
          maxValueWidth
        );

        // Draw label
        page.drawText(truncatedLabel, {
          x: studentInfoStartX,
          y: currentY,
          size: bodyFontSize,
          font: robotoBoldFont,
          color: bodyTextColor,
        });

        // Draw value
        page.drawText(truncatedValue, {
          x: valueX,
          y: currentY,
          size: bodyFontSize,
          font: robotoFont,
          color: bodyTextColor,
        });
      });

      // Update yPosition after header
      yPosition = height - 100;
    };

    // Draw the initial header
    drawHeader();

    // Dynamically create coursesByYear based on numYears
    const coursesByYear = {};
    for (let i = 1; i <= numYears; i++) {
      coursesByYear[`year${i}`] = {
        Fall: finalizedOrganizedCourses?.[`year${i}`]?.Fall || [],
        Spring: finalizedOrganizedCourses?.[`year${i}`]?.Spring || [],
      };
    }

    const startYear = Number(currentUser?.surveyAnswers?.SchoolStart?.year || new Date().getFullYear());

    // Iterate over each dynamically generated year
    for (let yearIndex = 1; yearIndex <= numYears; yearIndex++) {
      const year = `year${yearIndex}`;
      const yearTitle = `Year ${yearIndex} (${startYear + yearIndex - 1} - ${startYear + yearIndex})`;

      const fallCourses = coursesByYear[year]['Fall'] || [];
      const springCourses = coursesByYear[year]['Spring'] || [];

      // Estimate the height required for the year's content
      const fallSemesterHeight = estimateSemesterHeight(fallCourses, lineHeight, bodyFontSize);
      const springSemesterHeight = estimateSemesterHeight(springCourses, lineHeight, bodyFontSize);
      const maxSemesterHeight = Math.max(fallSemesterHeight, springSemesterHeight);

      const yearTitleHeight = lineHeight + 20; // Year title and separator line
      const yearExtraSpacing = 20;
      const totalYearHeight = yearTitleHeight + maxSemesterHeight + yearExtraSpacing;

      // Check if there's enough space on the current page
      if (yPosition - totalYearHeight < marginBottom) {
        addNewPage();
      }

      // Draw the year title
      page.drawText(yearTitle, {
        x: marginLeft,
        y: yPosition,
        size: subHeaderFontSize,
        font: robotoBoldFont,
        color: subHeaderColor,
      });
      yPosition -= lineHeight;

      // Draw a separator line after the year title
      page.drawLine({
        start: { x: marginLeft, y: yPosition },
        end: { x: width - marginRight, y: yPosition },
        thickness: 1,
        color: rgb(0.8, 0.8, 0.8),
      });
      yPosition -= 20; // Reduced space below the separator line

      // Set separate x positions for Fall and Spring columns
      const fallX = marginLeft;
      const springX = marginLeft + columnWidth + 20; // 20px gap between columns
      const semesterYStart = yPosition;

      // Function to draw semester courses with ellipsis for long text
      const drawSemester = (semesterName, courses, startX) => {
        let currentY = semesterYStart;

        // Draw semester header
        page.drawText(semesterName, {
          x: startX,
          y: currentY,
          size: subHeaderFontSize,
          font: robotoBoldFont,
          color: headerColor,
        });
        currentY -= lineHeight;

        // Draw courses
        let totalCredits = 0;
        courses.forEach((course, index) => {
          totalCredits += course.Credits;
          const courseText = `${course.courseInfo_courseNumber}: ${course.courseInfo_courseName} (${course.Credits} Credits)`;

          // Truncate course text if necessary
          const truncatedCourseText = truncateWithEllipsis(courseText, robotoFont, bodyFontSize, columnWidth);

          // Estimate the remaining height required
          const remainingCourses = courses.length - index;
          const estimatedRemainingHeight = remainingCourses * lineHeight + lineHeight + 10; // Courses + Total Credits

          // Check if there's enough space on the current page
          if (currentY - estimatedRemainingHeight < marginBottom) {
            addNewPage();
            currentY = height - marginTop - lineHeight; // Start below the header
            // Redraw semester header on the new page
            page.drawText(semesterName, {
              x: startX,
              y: currentY,
              size: subHeaderFontSize,
              font: robotoBoldFont,
              color: headerColor,
            });
            currentY -= lineHeight;
          }

          // Draw the course text
          page.drawText(truncatedCourseText, {
            x: startX, // Align with semester headers
            y: currentY,
            size: bodyFontSize,
            font: robotoFont,
            color: bodyTextColor,
          });
          currentY -= lineHeight;
        });

        // Draw total credits
        page.drawText(`Total Credits: ${totalCredits}`, {
          x: startX, // Align with semester headers
          y: currentY - 10,
          size: bodyFontSize,
          font: robotoBoldFont,
          color: bodyTextColor,
        });
        currentY -= lineHeight + 10;

        return currentY;
      };

      // Draw Fall and Spring semesters
      const fallYPosition = drawSemester('Fall Semester', fallCourses, fallX);
      const springYPosition = drawSemester('Spring Semester', springCourses, springX);

      // Update the main yPosition to the lowest point of both columns
      yPosition = Math.min(fallYPosition, springYPosition) - 20;

      // Add new page if the remaining space is insufficient
      if (yPosition < marginBottom) {
        addNewPage();
      }
    }

    // Add page numbers
    const totalPages = pdfDoc.getPageCount();
    pdfDoc.getPages().forEach((page, index) => {
      const { width } = page.getSize();
      page.drawText(`Page ${index + 1} of ${totalPages}`, {
        x: width / 2 - 30,
        y: marginBottom - 20,
        size: 8,
        font: robotoFont,
        color: rgb(0, 0, 0), // Black color for consistency
      });
    });

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
