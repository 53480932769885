/* src/components/Communications/CommunicationsPage.jsx */

import React, { useState, useEffect } from 'react';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { FaBars, FaSpinner } from 'react-icons/fa';
import { db, auth } from '../../firebase';
import CustomSidebar from '../Sidebar/Sidebar';
import DedicatedAdvisorChat from './DedicatedAdvisorChat';

/**
 * CommunicationsPage
 */
const CommunicationsPage = () => {
  const advisorId = auth.currentUser?.uid;

  // ─────────────────────────────────────────────────────────────
  // State
  // ─────────────────────────────────────────────────────────────
  const [advisorData, setAdvisorData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [filterMode, setFilterMode] = useState('assignedToMe');  // assignedToMe | allStudents | otherAdvisor
  const [selectedAdvisorId, setSelectedAdvisorId] = useState('');
  const [advisors, setAdvisors] = useState([]);

  const [displayedStudents, setDisplayedStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [studentPlans, setStudentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');

  // We'll defer unread computations and store them if needed
  const [unreadCounts, setUnreadCounts] = useState({});

  // ─────────────────────────────────────────────────────────────
  // 1) Batch fetch the current advisor & all advisors
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!advisorId) return;

    const fetchData = async () => {
      try {
        // Fetch the current advisor doc + all advisors in parallel
        const [advisorDocSnap, advisorsSnap] = await Promise.all([
          getDoc(doc(db, 'advisors', advisorId)),
          getDocs(collection(db, 'advisors'))
        ]);

        // Current advisor
        if (advisorDocSnap.exists()) {
          const data = advisorDocSnap.data();
          setAdvisorData({
            uid: advisorId,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            photoURL: data.profilePicture || '/default.jpg',
          });
        }

        // All advisors for the "otherAdvisor" dropdown
        const advArr = [];
        advisorsSnap.forEach((docSnap) => {
          const data = docSnap.data();
          advArr.push({
            uid: docSnap.id,
            firstName: data.firstName || '',
            lastName: data.lastName || ''
          });
        });
        setAdvisors(advArr);

      } catch (error) {
        console.error('Error fetching advisors or current advisor:', error);
      }
    };

    fetchData();
  }, [advisorId]);

  // ─────────────────────────────────────────────────────────────
  // 2) On filter change, fetch the relevant student UIDs,
  //    then fetch all those student docs in parallel.
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!advisorId) return;

    const fetchStudents = async () => {
      setLoading(true);
      setDisplayedStudents([]);
      setSelectedStudentId(null);
      setSelectedStudent(null);

      setStudentPlans([]);
      setSelectedPlan('');
      setUnreadCounts({}); // clear old unread data if we are re-fetching everything

      try {
        let studentUIDs = [];

        if (filterMode === 'assignedToMe') {
          // Query studentAdvisors where 'advisorIds' contains me
          const studentAdvisorsRef = collection(db, 'studentAdvisors');
          const qStudents = query(
            studentAdvisorsRef,
            where('advisorIds', 'array-contains', advisorId)
          );
          const snapStudents = await getDocs(qStudents);
          snapStudents.forEach((docSnap) => {
            studentUIDs.push(docSnap.id);
          });

        } else if (filterMode === 'allStudents') {
          // Show all users where role='student'
          const usersRef = collection(db, 'users');
          const qAll = query(usersRef, where('role', '==', 'student'));
          const snapAll = await getDocs(qAll);
          snapAll.forEach((docSnap) => {
            studentUIDs.push(docSnap.id);
          });

        } else if (filterMode === 'otherAdvisor' && selectedAdvisorId) {
          // Query studentAdvisors for that advisor
          const studentAdvisorsRef = collection(db, 'studentAdvisors');
          const qStudents = query(
            studentAdvisorsRef,
            where('advisorIds', 'array-contains', selectedAdvisorId)
          );
          const snapStudents = await getDocs(qStudents);
          snapStudents.forEach((docSnap) => {
            studentUIDs.push(docSnap.id);
          });
        }

        // Remove duplicates if any
        studentUIDs = [...new Set(studentUIDs)];

        // Now fetch all those user docs in parallel
        const studentDocPromises = studentUIDs.map((uid) =>
          getDoc(doc(db, 'users', uid))
        );
        const studentDocs = await Promise.all(studentDocPromises);

        // Build an array of student objects
        const fetched = studentDocs
          .filter((docSnap) => docSnap.exists())
          .map((docSnap) => {
            const data = docSnap.data();
            return {
              uid: docSnap.id,
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              email: data.email || '',
              photoURL: data.profilePicture || '/default.jpg'
            };
          });

        setDisplayedStudents(fetched);
        setLoading(false);

        // OPTION A) DEFER UNREAD to "onStudentSelect"
        //    - we won't compute unread for everyone upfront

        // OPTION B) STILL WISH TO DO UNREAD FOR ALL? DO IT IN BATCH
        //    You can do it here with Promise.all as well:
        // 
        // const unreadCountsObj = {};
        // await Promise.all(
        //   fetched.map(async (stu) => {
        //     const count = await computeUnreadForStudent(stu.uid);
        //     unreadCountsObj[stu.uid] = count;
        //   })
        // );
        // setUnreadCounts(unreadCountsObj);

      } catch (err) {
        console.error('Error fetching students:', err);
        setLoading(false);
      }
    };

    fetchStudents();
  }, [filterMode, selectedAdvisorId, advisorId]);

  // ─────────────────────────────────────────────────────────────
  // 3) Defer computing unread for a student until they’re selected
  //    (Alternatively, you could run it for everyone in parallel)
  // ─────────────────────────────────────────────────────────────
  const handleSelectStudent = async (uid) => {
    setSelectedStudentId(uid);

    // Clear old data
    setStudentPlans([]);
    setSelectedPlan('');

    // Identify the student object
    const foundStudent = displayedStudents.find((s) => s.uid === uid);
    setSelectedStudent(foundStudent || null);

    // Now fetch unread count for *this* student
    const newCount = await computeUnreadForStudent(uid);
    setUnreadCounts((prev) => ({
      ...prev,
      [uid]: newCount,
    }));
  };

  /**
   * Compute total unread messages across ALL plans for a single student.
   */
  const computeUnreadForStudent = async (studentUid) => {
    if (!advisorId) return 0;

    try {
      const studentRef = doc(db, 'users', studentUid);
      const snapshot = await getDoc(studentRef);

      if (!snapshot.exists()) {
        return 0; // no doc => no schedules => 0 unread
      }

      const data = snapshot.data();
      const schedulesObj = data.schedules || {};
      const planKeys = Object.keys(schedulesObj);

      // Gather all "chatId" references in parallel
      const chatIds = planKeys.map(
        (planName) => `${planName}_${studentUid}_${advisorId}`
      );

      // For each chatId, we need the count of messages with read=false & sender != advisorId
      // We’ll do this in parallel:
      const unreadCountsForPlans = await Promise.all(
        chatIds.map(async (chatId) => {
          // Query for unread messages
          const messagesRef = collection(db, 'chats', chatId, 'messages');
          const qMessages = query(
            messagesRef,
            where('read', '==', false),
            where('senderId', '!=', advisorId)
          );
          const snapMsgs = await getDocs(qMessages);
          return snapMsgs.size;
        })
      );

      // Sum them
      const totalUnread = unreadCountsForPlans.reduce((sum, val) => sum + val, 0);
      return totalUnread;
    } catch (err) {
      console.error('Error computing unread for student:', err);
      return 0;
    }
  };

  // ─────────────────────────────────────────────────────────────
  // 4) When a student is selected, fetch that student's doc for plan data
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!selectedStudentId) {
      setSelectedStudent(null);
      setStudentPlans([]);
      setSelectedPlan('');
      return;
    }

    const fetchSchedulesAsMap = async () => {
      try {
        const studentRef = doc(db, 'users', selectedStudentId);
        const snapshot = await getDoc(studentRef);
        if (snapshot.exists()) {
          const data = snapshot.data();
          const schedulesObj = data.schedules || {};
          const planNames = Object.keys(schedulesObj);

          setStudentPlans(planNames);

          // Auto-select if only one plan
          if (planNames.length === 1) {
            setSelectedPlan(planNames[0]);
          }
        } else {
          console.warn(`No user doc found for ID=${selectedStudentId}`);
          setStudentPlans([]);
          setSelectedPlan('');
        }
      } catch (err) {
        console.error('Error fetching student schedules map:', err);
        setStudentPlans([]);
        setSelectedPlan('');
      }
    };

    fetchSchedulesAsMap();
  }, [selectedStudentId]);

  // ─────────────────────────────────────────────────────────────
  // Render
  // ─────────────────────────────────────────────────────────────
  return (
    <div className="flex-1 p-4 ml-20 flex flex-col">
      <div className="flex min-h-screen bg-gray-100">
        {/* Global Sidebar */}
        <CustomSidebar />

        {/* Main content area */}
        <div className="flex-1 flex flex-col">
          {/* Top bar */}
          <div className="h-16 bg-white border-b px-4 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <button
                className="text-gray-500 md:hidden"
                onClick={() => setIsSidebarOpen((val) => !val)}
              >
                <FaBars />
              </button>
              <h2 className="text-lg font-semibold text-gray-700">
                Communications
              </h2>
            </div>
            <div className="text-sm text-gray-600">
              Advisor: {advisorId || 'Not Logged In'}
            </div>
          </div>

          {/* Filter Bar */}
          <div className="p-4 bg-white border-b flex flex-col md:flex-row md:items-center md:justify-between gap-2">
            <div className="flex items-center space-x-4">
              <label className="text-sm font-medium text-gray-700">Show Students:</label>
              <select
                value={filterMode}
                onChange={(e) => setFilterMode(e.target.value)}
                className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none"
              >
                <option value="assignedToMe">Assigned to Me</option>
                <option value="allStudents">All Students</option>
                <option value="otherAdvisor">Another Advisor</option>
              </select>
            </div>

            {filterMode === 'otherAdvisor' && (
              <div className="flex items-center space-x-2">
                <label className="text-sm text-gray-700">Select Advisor:</label>
                <select
                  value={selectedAdvisorId}
                  onChange={(e) => setSelectedAdvisorId(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none"
                >
                  <option value="">-- Choose an Advisor --</option>
                  {advisors
                    // (optional) exclude self
                    .filter((a) => a.uid !== advisorId)
                    .map((a) => (
                      <option key={a.uid} value={a.uid}>
                        {a.firstName} {a.lastName}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>

          {/* Body: [List of students] + [Plan selection & Chat] */}
          <div className="flex-1 flex overflow-hidden">
            {/* LEFT: Students List */}
            <div className="w-64 bg-white border-r border-gray-200 flex flex-col overflow-y-auto">
              {/* Loading */}
              {loading && (
                <div className="p-4 flex items-center space-x-2 text-gray-500">
                  <FaSpinner className="animate-spin" />
                  <span>Loading students...</span>
                </div>
              )}

              {/* No results */}
              {!loading && displayedStudents.length === 0 && (
                <div className="p-4 text-gray-500 text-sm">
                  No students found.
                </div>
              )}

              {/* Student Items */}
              {displayedStudents.map((student) => {
                const isSelected = student.uid === selectedStudentId;
                const unread = unreadCounts[student.uid] || 0;
                return (
                  <button
                    key={student.uid}
                    onClick={() => handleSelectStudent(student.uid)}
                    className={`flex justify-between items-center text-left px-3 py-2 border-b border-gray-100 hover:bg-blue-50 ${
                      isSelected ? 'bg-blue-100 text-blue-800' : 'text-gray-800'
                    }`}
                  >
                    <div className="flex-1">
                      <div className="font-medium text-sm">
                        {student.firstName} {student.lastName}
                      </div>
                      <div className="text-xs text-gray-500 truncate">
                        {student.email}
                      </div>
                    </div>
                    {unread > 0 && (
                      <span className="ml-2 text-xs bg-red-500 text-white rounded-full px-2 py-0.5">
                        {unread}
                      </span>
                    )}
                  </button>
                );
              })}
            </div>

            {/* RIGHT: Plan Selection & Chat */}
            <div className="flex-1 flex flex-col p-2 overflow-y-auto">
              {!selectedStudent ? (
                <div className="flex-1 flex items-center justify-center text-sm text-gray-500">
                  <p>Select a student to see their plans.</p>
                </div>
              ) : (
                <>
                  {studentPlans.length > 1 && (
                    <div className="flex items-center space-x-2 mb-2">
                      <label className="text-sm font-medium text-gray-700">
                        Select Plan:
                      </label>
                      <select
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                        className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:outline-none"
                      >
                        <option value="">-- Choose a Plan --</option>
                        {studentPlans.map((planName) => (
                          <option key={planName} value={planName}>
                            {planName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {/* Show chat only if a plan is selected or if there's exactly 1 plan */}
                  {studentPlans.length === 0 ? (
                    <div className="flex-1 flex items-center justify-center text-sm text-gray-500">
                      <p>No plans found for this student.</p>
                    </div>
                  ) : studentPlans.length === 1 && selectedPlan === studentPlans[0] ? (
                    <DedicatedAdvisorChat
                      studentData={selectedStudent}
                      advisorData={advisorData}
                      activeTab={selectedPlan}
                    />
                  ) : studentPlans.length > 1 && selectedPlan ? (
                    <DedicatedAdvisorChat
                      studentData={selectedStudent}
                      advisorData={advisorData}
                      activeTab={selectedPlan}
                    />
                  ) : (
                    <div className="flex-1 flex items-center justify-center text-sm text-gray-500">
                      <p>Please select a plan to start chatting</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationsPage;
