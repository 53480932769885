// Helper function to find the first digit in the course number
export const findFirstDigit = (courseNumber) => {
    for (let i = 0; i < courseNumber.length; i++) {
      if (/\d/.test(courseNumber.charAt(i))) {
        return parseInt(courseNumber.charAt(i));
      }
    }
    return 0; // Return 0 if no digit is found
  };
  
  // Function to calculate random enrollment based on mean and standard deviation
  export const randomizeEnrollment = (mean, stdDev) => {
    const rand = Math.max(0, Math.round(mean + (Math.random() - 0.5) * 2 * stdDev));
    return rand;
  };
  
  // Helper function to generate enrollment data for a course based on its number
  export const calculateEnrollmentTotal = (courseNumber) => {
    const firstDigit = findFirstDigit(courseNumber);
  
    let meanSpringFall = 0;
    let meanSummer = 0;
    let stdDevSpringFall = 0;
    let stdDevSummer = 0;
  
    switch (firstDigit) {
      case 1:
        meanSpringFall = 200;
        stdDevSpringFall = 15;
        meanSummer = 80;
        stdDevSummer = 5;
        break;
      case 2:
        meanSpringFall = 150;
        stdDevSpringFall = 10;
        meanSummer = 60;
        stdDevSummer = 3;
        break;
      case 3:
        meanSpringFall = 100;
        stdDevSpringFall = 10;
        meanSummer = 40;
        stdDevSummer = 2;
        break;
      case 4:
        meanSpringFall = 50;
        stdDevSpringFall = 5;
        meanSummer = 20;
        stdDevSummer = 2;
        break;
      default:
        meanSpringFall = 20;
        stdDevSpringFall = 3;
        meanSummer = 0;
        stdDevSummer = 0;
    }
  
    return [
      { term: "FA22", Total: randomizeEnrollment(meanSpringFall, stdDevSpringFall) },
      { term: "SP23", Total: randomizeEnrollment(meanSpringFall, stdDevSpringFall) },
      { term: "SU23", Total: randomizeEnrollment(meanSummer, stdDevSummer) },
      { term: "FA23", Total: randomizeEnrollment(meanSpringFall + (3 / firstDigit), stdDevSpringFall) },
      { term: "SP24", Total: randomizeEnrollment(meanSpringFall + (3 / firstDigit), stdDevSpringFall) },
      { term: "SU24", Total: randomizeEnrollment(meanSummer, stdDevSummer) },
      { term: "FA24", Total: randomizeEnrollment(meanSpringFall + (6 / firstDigit), stdDevSpringFall) },
      { term: "SP25", Total: randomizeEnrollment(meanSpringFall + (6 / firstDigit), stdDevSpringFall) },
      { term: "SU25", Total: randomizeEnrollment(meanSummer, stdDevSummer) },
      { term: "FA25", Total: randomizeEnrollment(meanSpringFall + (9 / firstDigit), stdDevSpringFall) },
      { term: "SP26", Total: randomizeEnrollment(meanSpringFall + (9 / firstDigit), stdDevSpringFall) },
      { term: "SU26", Total: randomizeEnrollment(meanSummer, stdDevSummer) },
      { term: "FA26", Total: randomizeEnrollment(meanSpringFall + (12 / firstDigit), stdDevSpringFall) },
      { term: "SP27", Total: randomizeEnrollment(meanSpringFall + (12 / firstDigit), stdDevSpringFall) },
      { term: "SU27", Total: randomizeEnrollment(meanSummer, stdDevSummer) },
    ];
  };
  