import React, { useState, useEffect, useRef } from "react";

const InsightsComponent = ({ analyticsData, activeChartIndex }) => {
  const [fade, setFade] = useState(true);
  const [visibleChartIndex, setVisibleChartIndex] = useState(activeChartIndex);

  // Keep track of the previous index in a ref
  const prevChartIndexRef = useRef(activeChartIndex);

  useEffect(() => {
    // If the new chart index is 0 and the previous index is NOT 1,
    // do NOT do the fade-out/fade-in transition; just show index 0 immediately.
    if (activeChartIndex === 0 && prevChartIndexRef.current !== 1) {
      setVisibleChartIndex(0);
      setFade(true); // remain visible, no fade
    } else {
      // Otherwise, do the normal fade-out => content swap => fade-in sequence
      setFade(false); // trigger fade-out
      const timeout = setTimeout(() => {
        setVisibleChartIndex(activeChartIndex);
        setFade(true); // fade back in
      }, 200); // match your tailwind transition-opacity duration
      return () => clearTimeout(timeout);
    }

    // Update the previous index
    prevChartIndexRef.current = activeChartIndex;
  }, [activeChartIndex]);

  //------------------------------------------------------------------
  // CASE 0: Keep exactly the same as your original snippet
  //------------------------------------------------------------------

  // Historical/future trend logic
  const fall = analyticsData?.trends?.fall?.historical ?? 0;
  const spring = analyticsData?.trends?.spring?.historical ?? 0;
  const summer = analyticsData?.trends?.summer?.historical ?? 0;
  const totalWeight = 1 + 1 + 0.2; // 2.2

  const weightedSum = fall * 1 + spring * 1 + summer * 0.2;
  const historicalAverage = weightedSum / totalWeight;

  let historicalTrend = "";
  if (historicalAverage >= 3) historicalTrend = "trending up";
  else if (historicalAverage >= 0) historicalTrend = "trending slightly up";
  else if (historicalAverage <= -3) historicalTrend = "trending down";
  else historicalTrend = "trending slightly down";

  const forecastedSemesters = [
    { key: "Fall", year: 2024 },
    { key: "Spring", year: 2025 },
    { key: "Summer", year: 2025 },
  ];

  const forecastedTrends = forecastedSemesters
    .map(({ key, year }) => {
      const forecastedValue =
        analyticsData?.trends?.[key.toLowerCase()]?.forecasted ?? null;
      if (forecastedValue === 0 || forecastedValue === null) {
        return null;
      }
      return {
        key,
        year,
        trend: forecastedValue > 0 ? "increase" : "decrease",
      };
    })
    .filter(Boolean);

  const groupedTrends = forecastedTrends.reduce((acc, { trend, key, year }) => {
    acc[trend] = acc[trend] || [];
    acc[trend].push(`${key} ${year}`);
    return acc;
  }, {});

  const increaseTrends = groupedTrends["increase"]?.join(" and ") ?? null;
  const decreaseTrends = groupedTrends["decrease"]?.join(" and ") ?? null;

  //------------------------------------------------------------------
  // Helper to color-code text
  //------------------------------------------------------------------
  function getColorClass(term = "") {
    const t = term.toLowerCase();
    if (t.includes("increase") || t.includes("higher") || t.includes("up")) {
      return "text-green-600 font-bold";
    }
    if (t.includes("decrease") || t.includes("lower") || t.includes("down")) {
      return "text-red-600 font-bold";
    }
    return "";
  }

  //------------------------------------------------------------------
  // CASE 1: Summation of enrollment for 2024-2025 vs. future years
  //------------------------------------------------------------------
  const enrollmentData = analyticsData?.enrollmentData || [];

  function sumTerms(targetTerms) {
    return enrollmentData
      .filter((item) => targetTerms.includes(item.term))
      .reduce((acc, item) => acc + (item.Total ?? 0), 0);
  }

  const sum_24_25 = sumTerms(["FA24", "SP25", "SU25"]);
  const sum_25_26 = sumTerms(["FA25", "SP26", "SU26"]);
  const sum_26_27 = sumTerms(["FA26", "SP27", "SU27"]);

  let futureSum = 0;
  let futureSchoolYear = "";
  if (sum_25_26 >= sum_26_27) {
    futureSum = sum_25_26;
    futureSchoolYear = "2025-2026";
  } else {
    futureSum = sum_26_27;
    futureSchoolYear = "2026-2027";
  }

  function getTrendAdjective(current, future) {
    const diff = future - current;
    if (diff > 100) return "increase";
    if (diff > 0) return "slightly increase";
    if (diff < -100) return "decrease";
    if (diff < 0) return "slightly decrease";
    return "remain stable";
  }
  const case1Trend = getTrendAdjective(sum_24_25, futureSum);

  //------------------------------------------------------------------
  // CASE 2: Major distribution
  //------------------------------------------------------------------
  const majorEnrollmentData = analyticsData?.majorEnrollmentData ?? [];
  const sortedMajors = [...majorEnrollmentData].sort((a, b) => b.count - a.count);

  const [m0, m1, m2, m3, m4, m5] = sortedMajors;

  let topMajorsDescription = "";
  if (m0 && m1 && (m0.count - m1.count <= 10)) {
    topMajorsDescription = `${m0.major} and ${m1.major}`;
  } else if (m0) {
    topMajorsDescription = `${m0.major}`;
  }

  //------------------------------------------------------------------
  // CASE 3: Year distribution
  //------------------------------------------------------------------
  const yearEnrollmentData = analyticsData?.yearEnrollmentData ?? [];
  const sortedYears = [...yearEnrollmentData].sort((a, b) => b.count - a.count);
  const y0 = sortedYears[0]?.grade; 
  const y1 = sortedYears[1]?.grade;
  const y2 = sortedYears[2]?.grade;

  //------------------------------------------------------------------
  // CASE 4: Grade distribution + departmental baseline
  //------------------------------------------------------------------
  const gradeData = analyticsData?.gradeData ?? [];
  const gradeScale = {
    "A": 4.0,
    "A-": 3.7,
    "B+": 3.3,
    "B": 3.0,
    "B-": 2.7,
    "C+": 2.3,
    "C": 2.0,
    "C-": 1.7,
    "D": 1.0,
    "F": 0.0,
  };

  let totalPoints = 0;
  let totalCount = 0;
  gradeData.forEach(({ grade, count }) => {
    if (gradeScale[grade] !== undefined) {
      totalPoints += gradeScale[grade] * count;
      totalCount += count;
    }
  });
  const numericAverage = totalCount > 0 ? totalPoints / totalCount : 0;

  const approximateLetter = (() => {
    if (numericAverage >= 3.85) return "A";
    if (numericAverage >= 3.5) return "A-";
    if (numericAverage >= 3.15) return "B+";
    if (numericAverage >= 2.85) return "B";
    if (numericAverage >= 2.5) return "B-";
    if (numericAverage >= 2.15) return "C+";
    if (numericAverage >= 1.85) return "C";
    if (numericAverage >= 1.5) return "C-";
    if (numericAverage >= 1.15) return "D+";
    if (numericAverage >= 0.85) return "D";
    if (numericAverage >= 0.5) return "D-";
    return "F";
  })();

  let dept = "";
  let code = "";
  if (analyticsData?.courseNumber) {
    const parts = analyticsData.courseNumber.split(" ");
    dept = parts[0] || "";
    code = parts[1] || "";
  }

  let levelDesc = "1000-level";
  switch (code.charAt(0)) {
    case "2":
      levelDesc = "2000-level";
      break;
    case "3":
      levelDesc = "3000-level";
      break;
    case "4":
      levelDesc = "4000-level";
      break;
    case "5":
      levelDesc = "5000-level";
      break;
    case "6":
      levelDesc = "6000-level";
      break;
    default:
      levelDesc = "1000-level";
      break;
  }

  const baseline =
    levelDesc === "1000-level"
      ? 2.7
      : levelDesc === "2000-level"
      ? 2.9
      : levelDesc === "3000-level"
      ? 3.1
      : 3.3; // for 4000-level and above

  const diff = numericAverage - baseline;
  let higherOrLower = "";
  if (diff >= 0.3) higherOrLower = "higher";
  else if (diff > 0) higherOrLower = "slightly higher";
  else if (diff > -0.3) higherOrLower = "slightly lower";
  else higherOrLower = "lower";

  //------------------------------------------------------------------
  // Build the final insight text depending on visibleChartIndex
  //------------------------------------------------------------------
  let chartInsight = "";
  let chartDescription = "";

  switch (visibleChartIndex) {
    // CASE 0:
    case 0:
      chartInsight = "Term enrollment:";
      chartDescription = (
        <>
          Enrollment has been{" "}
          <span
            className={`${
              historicalAverage > 0 ? "text-green-600 font-bold" : "text-red-600 font-bold"
            }`}
          >
            {historicalTrend}
          </span>{" "}
          over the past 3 years.
          {increaseTrends || decreaseTrends ? (
            <>
              {" "}
              Predicted to{" "}
              {increaseTrends && (
                <>
                  <span className="text-green-600 font-bold">increase</span> in{" "}
                  {increaseTrends}
                </>
              )}
              {increaseTrends && decreaseTrends && " but "}
              {decreaseTrends && (
                <>
                  <span className="text-red-600 font-bold">decrease</span> in{" "}
                  {decreaseTrends}
                </>
              )}
              .
            </>
          ) : (
            "."
          )}
        </>
      );
      break;

    // CASE 1:
    case 1:
      chartInsight = "Overall enrollment:";
      chartDescription = (
        <>
          Over the 2024-2025 school year,{" "}
          <strong>{sum_24_25}</strong> students have taken{" "}
          {analyticsData?.courseName} ({analyticsData?.courseNumber}). Enrollment is projected
          to{" "}
          <span className={getColorClass(case1Trend)}>
            {case1Trend}
          </span>{" "}
          to <strong>{futureSum}</strong> over the {futureSchoolYear}{" "}
          school year.
        </>
      );
      break;

    // CASE 2:
    case 2:
      chartInsight = "Major distribution:";
      const thirdMajor = m2 ? `${m2.major}` : "the 3rd most popular major";
      const sixthMajor = m5 ? `${m5.major}` : "the 6th most popular major";
      const fourthMajor = m3 ? `${m3.major}` : "the 4th most popular major";
      const fifthMajor = m4 ? `${m4.major}` : "the 5th most popular major";

      chartDescription = (
        <>
          This course is most popular among <b>{topMajorsDescription}</b> majors.{" "}
          {m2 || m5 ? (
            <>
              Enrollment for <b>{thirdMajor}</b>{" "}
              and <b>{sixthMajor}</b> {" "}
              majors is predicted to {" "}
              <span className={getColorClass("increase")}>increase</span>,
            </>
          ) : null}{" "}
          {m3 || m4 ? (
            <>
              {" "}
              while enrollment for <b>{fourthMajor}</b>{" "}
              and <b>{fifthMajor}</b> {" "}
              majors is predicted to {" "}
              <span className={getColorClass("decrease")}>decrease</span>.
            </>
          ) : null}
        </>
      );
      break;

    // CASE 3:
    case 3:
      chartInsight = "Academic Year enrollment:";

      const pluralizeYear = (year) => {
        switch (year) {
          case "freshman":
            return "freshmen";
          case "sophomore":
            return "sophomores";
          case "junior":
            return "juniors";
          case "senior":
            return "seniors";
          default:
            return year;
        }
      };
    
      const mostCommonYear = y0 ? pluralizeYear(y0) : "the most common year level";
      const thirdYear = y2 ? pluralizeYear(y2) : "the 3rd most common year level";
      const secondYear = y1 ? pluralizeYear(y1) : "the 2nd most common year level";
    
      chartDescription = (
        <>
          This course is most popular among <b>{mostCommonYear}</b>.{" "}
          {y2 || y1 ? (
            <>
              Enrollment for <b>{thirdYear}</b>{" "}
              is predicted to {" "}
              <span className={getColorClass("increase")}>increase</span>, {" "}
              while enrollment for <b>{secondYear}</b>{" "}
              is predicted to {" "}
              <span className={getColorClass("decrease")}>decrease</span>.
            </>
          ) : null}
        </>
      );
      break;

    // CASE 4:
    case 4:
      chartInsight = "Grade distribution:";
      chartDescription = (
        <>
          The average grade for this class is around a <b>{approximateLetter}</b>.{" "}
          This is{" "}
          <span className={getColorClass(higherOrLower)}>
            {higherOrLower}
          </span>{" "}
          than similar <b>{levelDesc}</b> <b>{dept}</b> courses.
        </>
      );
      break;

    default:
      chartInsight = "General insights...";
      chartDescription = "No additional data available.";
      break;
  }

  return (
    <div className="w-full p-2.5 mb-3 rounded-md border-2 border-gray-200">
      <div
        className={`transition-opacity duration-300 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        <p className="text-gray-800">
          <span className="font-semibold">{chartInsight} </span>
          {chartDescription}
        </p>
      </div>
    </div>
  );
};

export default InsightsComponent;
