import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const MajorPercentageBarChart = ({ data }) => {
  if (!data) {
    data = [
      { major: "Computer Science", count: 120 },
      { major: "Electrical Engineering", count: 80 },
      { major: "Mechanical Engineering", count: 60 },
      { major: "Civil Engineering", count: 40 },
      { major: "Chemical Engineering", count: 35 },
      { major: "Biomedical Engineering", count: 30 },
      { major: "Aerospace Engineering", count: 25 },
      { major: "Environmental Science", count: 20 },
      { major: "Mathematics", count: 15 },
      { major: "Physics", count: 10 },
      { major: "Economics", count: 5 },
    ];
  }

  const totalEnrollment = data.reduce((sum, item) => sum + item.count, 0);

  // Sort by count in descending order
  const sortedData = data.sort((a, b) => b.count - a.count);

  // Split into top 8 and "Other"
  const topMajors = sortedData.slice(0, 7);
  const otherCount = sortedData.slice(7).reduce((sum, item) => sum + item.count, 0);

  const displayData = [
    ...topMajors,
    ...(otherCount > 0 ? [{ major: "Other", count: otherCount }] : []),
  ];

  const chartData = {
    labels: displayData.map((item) => item.major), // X-axis labels
    datasets: [
      {
        label: "Enrollment Percentage",
        data: displayData.map((item) => ((item.count / totalEnrollment) * 100).toFixed(2)), // Convert to percentages
        backgroundColor: displayData.map((item, index) => {
          const colors = ["#8884d8", "#82ca9d", "#ff7300", "#f4a460", "#87cefa", "#c71585", "#dda0dd", "#98fb98"];
          return colors[index % colors.length]; // Cycle through predefined colors
        }).concat("#cccccc"), // Add a color for "Other"
        hoverBackgroundColor: displayData.map((item, index) => {
          const hoverColors = ["#6666cc", "#6fbf85", "#cc5f00", "#d98c52", "#6495ed", "#b03060", "#e6e6fa", "#90ee90"];
          return hoverColors[index % hoverColors.length];
        }).concat("#999999"), // Add hover color for "Other"
        borderColor: displayData.map(() => "#ffffff"),
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`; // Show percentage value
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        title: {
          display: true,
          text: "Majors", // X-axis title
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Enrollment Percentage", // Y-axis title
        },
        ticks: {
          callback: (value) => `${value}%`, // Append '%' to Y-axis ticks
        },
      },
    },
  };

  return (
    <div className="w-full flex items-center justify-center" style={{ width: "900px", height: "350px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default MajorPercentageBarChart;
