import { findEnrollmentTotal } from './CalculateEnrollmentTrends'; // Helper for finding term enrollment total

export const calculateGradeDistribution = (courseNumber, enrollmentData) => {
  const enrollmentTotal = findEnrollmentTotal(enrollmentData, "FA24");

  // Define base grade distributions as bell curve centroids based on the first digit of the course number
  const gradeDistributions = {
    1: {
      A: 0.05, "A-": 0.1,
      "B+": 0.1, B: 0.1, "B-": 0.1,
      "C+": 0.15, C: 0.15, "C-": 0.1,
      D: 0.05, 
      F: 0.04, W: 0.04, "N/A": 0.02, // Lower level: B-/C+ centered
    },
    2: {
      A: 0.1, "A-": 0.1,
      "B+": 0.15, B: 0.1, "B-": 0.1,
      "C+": 0.1, C: 0.1, "C-": 0.1,
      D: 0.05,
      F: 0.03, W: 0.04, "N/A": 0.02, // Slightly higher: C+/B- centered
    },
    3: {
      A: 0.15,
      "B+": 0.1, B: 0.15, "B-": 0.1,
      "C+": 0.1, C: 0.05, "C-": 0.05,
      D: 0.05,
      F: 0.02, W: 0.02, "N/A": 0.01, // Higher level: B/B+ centered
    },
    4: {
      A: 0.2,
      "B+": 0.15, B: 0.1, "B-": 0.05,
      "C+": 0.05, C: 0.05, "C-": 0.05,
      D: 0.02, 
      F: 0.01, W: 0.01, "N/A": 0.01, // Upper level: A-/B+ centered
    },
    default: {
      A: 0.1, "A-": 0.1,
      "B+": 0.15, B: 0.15, "B-": 0.1,
      "C+": 0.1, C: 0.1, "C-": 0.1,
      D: 0.05,
      F: 0.03, W: 0.04, "N/A": 0.02, // Default: B-/C+ centered
    },
  };

  // Extract the first digit from the course number
  const firstDigitMatch = courseNumber.match(/\d/);
  const firstDigit = firstDigitMatch ? parseInt(firstDigitMatch[0], 10) : null;

  // Get the base grade distribution for the specific first digit or use default
  const baseDistribution = gradeDistributions[firstDigit] || gradeDistributions.default;

  // Add randomness to the grade distribution
  const randomizedDistribution = Object.fromEntries(
    Object.entries(baseDistribution).map(([grade, weight]) => {
      const randomFactor = 0.8 + Math.random() * 0.4; // Random factor between 0.8 and 1.2
      return [grade, weight * randomFactor];
    })
  );

  // Normalize weights to sum to 1
  const totalWeight = Object.values(randomizedDistribution).reduce((sum, weight) => sum + weight, 0);
  const normalizedDistribution = Object.fromEntries(
    Object.entries(randomizedDistribution).map(([grade, weight]) => [grade, weight / totalWeight])
  );

  // Calculate counts for each grade based on weights
  const gradeDistribution = Object.entries(normalizedDistribution).map(([grade, weight]) => ({
    grade,
    count: Math.round(enrollmentTotal * weight),
  }));

  // Adjust distribution to match exact enrollmentTotal
  const totalCalculated = gradeDistribution.reduce((sum, { count }) => sum + count, 0);
  const adjustment = enrollmentTotal - totalCalculated;
  if (adjustment !== 0) {
    // Apply adjustment to the largest group
    gradeDistribution.sort((a, b) => b.count - a.count)[0].count += adjustment;
  }

  // Define the order for grades
  const gradeOrder = [
    "A", "A-", "B+", "B", "B-", "C+", "C", "C-", 
    "D", "F", "W", "N/A"
  ];

  // Sort the distribution to match the desired order
  return gradeOrder
    .map((grade) => gradeDistribution.find((item) => item.grade === grade) || { grade, count: 0 })
    .filter(({ count }) => count > 0); // Exclude grades with 0 count
};
