// GPAGraph.js
import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const GPAGraph = (inputGPAData) => {
  // Sample time stamps for the x-axis
  const allLabels = [
    "Fall 2020",
    "Spring 2021",
    "Summer 2021",
    "Fall 2021",
    "Spring 2022",
    "Summer 2022",
    "Fall 2022",
    "Spring 2023",
    "Summer 2023",
    "Fall 2023",
    "Spring 2024",
    "Summer 2024",
    "Fall 2024",
    "Spring 2025",
  ];

  const gpaData = inputGPAData?.gpaData;

  // Slice labels to match the length of gpaData
  const labels = allLabels.slice(-gpaData.length);

  // Data configuration for the Line chart
  const data = {
    labels,
    datasets: [
      {
        label: "GPA Progression",
        data: gpaData,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.1, // Smoothens the line
      },
    ],
  };

  // Chart options including axis titles and scales
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to respect container dimensions
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 4.0,
        ticks: {
          stepSize: 0.5,
        },
        title: {
          display: true,
          text: "GPA",
        },
      },
    },
  };

  return (
    // Container uses both viewport width and height for responsive sizing
    <div style={{ width: "35vw", height: "43vh" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default GPAGraph;

