import React from 'react';
import { Button } from 'flowbite-react';

const ReportTemplates = ({
  templates,
  templateName,
  setTemplateName,
  saveTemplate,
  loadTemplate,
  deleteTemplate,
  templateDataToLoad,
  selectedTemplateId,
  setSelectedTemplateId,
  showSaveTemplate, // Added prop
}) => {
  const handleTemplateSelection = (e) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);
    loadTemplate(templateId);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      {/* Load Template */}
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Load Template
        </label>
        <select
          value={selectedTemplateId || ''}
          onChange={handleTemplateSelection}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a Template</option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
      </div>

      {/* Delete Template Button */}
      {selectedTemplateId && (
        <Button
          color="red"
          onClick={() => deleteTemplate(selectedTemplateId)}
          className="mt-2"
        >
          Delete Template
        </Button>
      )}

      {/* Save Template */}
      {showSaveTemplate && (
        <div className="mt-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Save Current Configuration as Template
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Template Name"
            className="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        <Button onClick={saveTemplate} 
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Save Template
        </Button>
        </div>
      )}
    </div>
  );
};

export default ReportTemplates;
