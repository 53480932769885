import React, { useRef, useEffect } from "react";

const TransferDataModal = ({ showModal, onClose, transferClassesData }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close modal if click is outside
      }
    };

    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!showModal) return null;

  const renderTable = (data, title, headers, columns) => {
    return (
      <>
        <h3 className="text-md font-semibold mt-3 mb-2">{title}</h3>
        {(!data || data.length === 0) && (
          <div className="text-gray-600 text-sm mb-4">
            <p>You have not been assigned any {title.toLowerCase()} credits.</p>
          </div>
        )}
        {data && data.length > 0 && (
          <table className="w-full table-auto border-collapse border border-gray-200 mb-4">
            <thead className="bg-gray-100">
              <tr>
                {headers.map((header, index) => (
                  <th key={index} className="border border-gray-200 px-4 py-2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="border border-gray-200 px-4 py-2">
                      {item[col] || "-"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg w-auto max-w-6xl p-4"
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-lg font-semibold">Transfer Classes Data</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none text-3xl"
          >
            &times;
          </button>
        </div>
        <div className="overflow-y-auto max-h-[70vh]">
          {/* Transfer Classes Table */}
          {renderTable(
            transferClassesData?.transferClasses,
            "Transfer Classes",
            ["Term", "Equivalent Course", "Credits", "Institution", "Grade", "Incoming Course", "Source Institution"],
            ["term", "equivalentCourse", "credits", "institution", "grade", "incomingCourse", "sourceInstitution"]
          )}

          {/* Test Classes Table */}
          {renderTable(
            transferClassesData?.testClasses,
            "Test Scores",
            ["Term", "Equivalent Course", "Credits", "Institution", "Test ID", "Test Component", "Score"],
            ["term", "equivalentCourse", "credits", "institution", "testId", "testComponent", "score"]
          )}

          {/* Other Classes Table */}
          {renderTable(
            transferClassesData?.otherClasses,
            "Other",
            ["Term", "Equivalent Course", "Credits", "Institution", "Grade", "Description"],
            ["term", "equivalentCourse", "credits", "institution", "grade", "description"]
          )}
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferDataModal;
