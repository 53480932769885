import React from 'react';
import './CreditBreakdown.css';

const CreditBreakdown = ({ finalizedCourses }) => {
  // Define categories and their required credits
  const allowedCategories = [
    { name: 'Math', requiredCredits: 7 },
    { name: 'English', requiredCredits: 7 },
    { name: 'Social Studies', requiredCredits: 7 },
    { name: 'Science', requiredCredits: 7 },
    { name: 'Foreign Language', requiredCredits: 7 },
    { name: 'Art', requiredCredits: 7 },
    { name: 'Misc', requiredCredits: 7 },
  ];

  // Function to calculate credit breakdown
  const calculateCreditBreakdown = () => {
    const creditBreakdown = {};

    // Initialize the breakdown with 0 credits for allowed categories
    allowedCategories.forEach(category => {
      creditBreakdown[category.name] = {
        earned: 0,
        required: category.requiredCredits,
      };
    });

    if (Array.isArray(finalizedCourses)) {
      finalizedCourses.forEach(course => {
        const category = course.courseCat || 'Uncategorized';
        const credits = course.Credits || 0;

        // Only accumulate credits for allowed categories
        if (creditBreakdown.hasOwnProperty(category)) {
          creditBreakdown[category].earned += credits;
        }
      });
    }

    return creditBreakdown;
  };

  // Calculate credit breakdown
  const creditBreakdown = calculateCreditBreakdown();

  // Calculate total earned credits
  const totalEarnedCredits = Object.values(creditBreakdown).reduce((acc, { earned }) => acc + earned, 0);

  return (
    <div>
      <h3 style={{ fontSize: '1.5rem', margin: '0.5rem 5%', textAlign: 'center', padding: '0', color: 'inherit', textDecoration: 'none' }}>
        Credit Breakdown
      </h3>
      {Object.entries(creditBreakdown).map(([category, { earned, required }]) => (
        <div
          key={category}
          className={`credit-card ${earned >= required ? 'credit-card-green' : 'credit-card-blue'}`}
        >
          <p>
            <strong>{category}:</strong> {earned} / {required} credits
          </p>
        </div>
      ))}
      <div className={`credit-card ${totalEarnedCredits >= 49 ? 'credit-card-green' : 'credit-card-blue'}`}>
        <p>
          <strong>Total Credits:</strong> {totalEarnedCredits} / 49 credits
        </p>
      </div>
    </div>
  );
};

export default CreditBreakdown;