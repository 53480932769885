// CourseProgressFunctions/calculateProgressForMajor.js

import findRequirementCourses from './FindRequirementCourses';
import { findElectiveCourses } from './FindElectiveCourses';

const calculateProgressForMajor = (
  major,
  fetchedMajors,
  fetchedCourseData,
  finalizedSchedule,
  completedSemesters
) => {
  if (!major || !fetchedMajors) {
    return {
      takenCoursesCount: 0, // Total taken courses count
      planCoursesCount: 0,  // Remaining planned courses count
      totCoursesCount: 0,   // Total courses count for the major
    };
  }

  // Aggregate courses from all years and semesters safely
  let courseArray = [];
  for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
    let year = `year${i}`;
    for (let j = 1; j <= 2; j++) {
      let semester = `S${j}`;
      if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
        // Safely handle undefined or non-array courses
        const courses = Array.isArray(finalizedSchedule[year][semester].courses)
          ? finalizedSchedule[year][semester].courses
          : [];
        courseArray.push(...courses);
      }
    }
  }

  // Get the student courses based on the aggregated course IDs
  const studentCourses = fetchedCourseData
    ? fetchedCourseData.filter(course =>
        courseArray.includes(course.courseInfo_courseNumber)
      )
    : [];

  // Get all courses for the given major
  const majorCourses = Object.values(fetchedMajors[major]);
  const totalCourses = majorCourses.filter(
    majorCourse => majorCourse.courseType !== 'Milestone'
  ).length;

  // Determine the courses taken by the student in completed semesters
  const takenCoursesSet = new Set();
  Object.keys(completedSemesters).forEach(year => {
    Object.keys(completedSemesters[year]).forEach(semesterKey => {
      if (completedSemesters[year][semesterKey]) {
        const completedCourses =
          finalizedSchedule[year]?.[semesterKey]?.courses || [];
        completedCourses.forEach(course => {
          takenCoursesSet.add(course);
        });
      }
    });
  });
  const takenCoursesArray = Array.from(takenCoursesSet);
  const takenCourses = fetchedCourseData.filter(course =>
    takenCoursesArray.includes(course.courseInfo_courseNumber)
  );

  // Identify taken major required courses
  const majorRequiredSpecificCourses = majorCourses.filter(
    majorCourse => majorCourse.courseType === 'Major Course'
  );
  const takenMajorCoursesArray = takenCourses.filter(course =>
    majorRequiredSpecificCourses.some(
      majorCourse =>
        majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
    )
  );

  // Identify taken elective and requirement courses using helper functions
  const takenElectiveCoursesArray = findElectiveCourses(
    majorCourses.filter(course => course.courseType === 'Elective'),
    takenCourses
  );

  const takenRequirementCoursesArray = findRequirementCourses(
    majorCourses.filter(
      course =>
        (course.courseType === 'Major Course' && !course.Requirements) ||
        course.courseType === 'Requirement'
    ),
    takenCourses,
    takenElectiveCoursesArray
  );

  // Calculate all applicable major, elective, and requirement courses for planning
  const allMajorCoursesArray = studentCourses.filter(course =>
    majorRequiredSpecificCourses.some(
      majorCourse =>
        majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
    )
  );

  const allElectiveCoursesArray = findElectiveCourses(
    majorCourses.filter(course => course.courseType === 'Elective'),
    studentCourses
  );

  const allRequirementCoursesArray = findRequirementCourses(
    majorCourses.filter(
      course =>
        (course.courseType === 'Major Course' && !course.Requirements) ||
        course.courseType === 'Requirement'
    ),
    studentCourses,
    allElectiveCoursesArray
  );

  // Calculate counts for taken and planned courses
  let takenCoursesLengths =
    takenMajorCoursesArray.length +
    takenElectiveCoursesArray.length +
    takenRequirementCoursesArray.length;

  let allCoursesLengths =
    allMajorCoursesArray.length +
    allElectiveCoursesArray.length +
    allRequirementCoursesArray.length -
    takenCoursesLengths;

  return {
    takenCoursesCount: takenCoursesLengths, // Total taken courses count
    planCoursesCount: allCoursesLengths,    // Remaining planned courses count
    totCoursesCount: totalCourses,          // Total courses count for the major
  };
};

export default calculateProgressForMajor;
