import Talk from 'talkjs';

const APP_ID = 'tm9gzpVl';
const API_KEY = 'sk_test_HmGKjGZgU2MkanWQSSz0dwzIBMPs3PlW';

async function createSession(userId) {
  await Talk.ready;
  const user = new Talk.User({
    id: userId,
    name: 'Bob',
    email: 'bob@example.com',
    photoUrl: 'https://talkjs.com/images/avatar-5.jpg',
    role: 'default',
  });
  return new Talk.Session({
    appId: APP_ID,
    me: user,
  });
}

async function getConversations(userId) {
  if (!userId) {
    console.error('User ID is not provided');
    return [];
  }

  const fetchConversations = async (retryCount = 0) => {
    try {
      const response = await fetch(`https://api.talkjs.com/v1/${APP_ID}/users/${userId}/conversations`, {
        headers: {
          'Authorization': `Bearer ${API_KEY}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.data;
    } catch (error) {
      // console.error('Error fetching conversations:', error);
      if (retryCount < 3) {
        // console.log(`Retrying... (${retryCount + 1})`);
        return fetchConversations(retryCount + 1);
      }
      return [];
    }
  };

  return fetchConversations();
}

export { createSession, getConversations };
