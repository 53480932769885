import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFPreview = ({ pdfBytes, onClose, firstName, lastName, numYears }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    setUrl(objectUrl);

    // Cleanup the URL when the component unmounts
    return () => URL.revokeObjectURL(objectUrl);
  }, [pdfBytes]);

  // Set default values to prevent undefined in filename
  const downloadFileName = `${lastName || 'N/A'}_${firstName || 'N/A'}_${numYears || '4'}yearplan.pdf`;

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
    >
      <div className="relative w-full max-w-4xl h-full max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col h-full">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              PDF Preview
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:!bg-gray-200 hover:!text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:!bg-gray-600 dark:hover:!text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-4 space-y-4 overflow-y-auto flex-grow bg-white dark:bg-gray-700">
            <div className="w-full h-full">
              {url && (
                <Worker
                  workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}
                >
                  <Viewer fileUrl={url} />
                </Worker>
              )}
            </div>
          </div>
          {/* Modal footer */}
          <div className="flex items-center p-4 border-t border-gray-200 rounded-b dark:border-gray-600">
            <a
              href={url}
              download={downloadFileName}
              className="text-white bg-blue-700 hover:!bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:!bg-blue-700 dark:focus:ring-blue-800"
            >
              Download
            </a>
            <button
              type="button"
              className="text-gray-700 bg-white hover:!bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-300 text-sm font-medium px-5 py-2.5 hover:!text-gray-900 focus:z-10 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:!text-white dark:hover:!bg-gray-700"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFPreview;
