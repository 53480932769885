import React, { useState, useRef, useEffect } from 'react';
import NavBar from '../../../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faGraduationCap, faMapMarkerAlt, faBook, faTag, faEyeSlash, faChevronDown, faChevronUp, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, serverTimestamp, arrayUnion, setDoc, Timestamp, arrayRemove} from "firebase/firestore";
import '../../../../index.css';
import { BsFolder2Open } from "react-icons/bs";
import { db } from '../../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { BsThreeDots, BsPlus } from 'react-icons/bs'; 
import { Card, Button, Spinner } from 'flowbite-react';
import { useAuth } from '../../../../contexts/AuthContext';
import CustomSidebar from '../../Sidebar/Sidebar';
import StudentAnalytics from './StudentAnalytics';
import { toast } from 'react-toastify';
import NotesTab from './NotesTab';

const AdvisorReviewStudentProfile = () => {
  const [currentStudent, setCurrentStudent] = useState(null);
  const [persistenceCalculations, setPersistenceCalculations] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const student = useParams().studentId;
  const { currentUser, fetchedCourseData, fetchedMajors, fetchedSurveyData } = useAuth();
  const [photoURL, setPhotoURL] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finalizedSchedule, setFinalizedSchedule] = useState({});
  const [completedSemesters, setCompletedSemesters] = useState({});
  const [engagementResources, setEngagementResources] = useState([]);
  const [applicableEngagements, setApplicableEngagements] = useState([]);
  const [loadingEngagements, setLoadingEngagements] = useState(true);
  const [milestoneData, setMilestoneData] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [completedMilestones, setCompletedMilestones] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [selectedMajor, setSelectedMajor] = useState(null); 
  const [dropdownVisible, setDropdownVisible] = useState(false); 
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [advisors, setAdvisors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const [recommendedResources, setRecommendedResources] = useState([]); 
  const [resourcesLoading, setResourcesLoading] = useState(true); 
  const [resourcesError, setResourcesError] = useState(null); 
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [hoveredResourceId, setHoveredResourceId] = useState(null);

  // --- Tabs State ---
  const [activeTab, setActiveTab] = useState('profile');
  const [editingTab, setEditingTab] = useState(null);
  const [newTabName, setNewTabName] = useState('');

  // Example single tab array (if you add more, list them here)
  const tabs = [
    { name: 'profile', label: 'Profile' },
    { name: 'student analytics', label: 'Student Analytics' },
    { name: 'notes', label: 'Notes' }
    // Additional tabs can go here if needed
  ];

  // =====================================
  //  NOTES Handling
  // =====================================
  useEffect(() => {
    // Fetch notes for this student from the currently logged-in advisor
    const loadNotes = async () => {
      if (!currentUser || !currentStudent) return;

      const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);
      const notesDocSnap = await getDoc(notesDocRef);
      if (notesDocSnap.exists()) {
        const data = notesDocSnap.data();
        setNotes(data.notes || []);
      } else {
        setNotes([]);
      }
    };
    loadNotes();
  }, [currentUser, currentStudent]);

  const handleAddNote = async () => {
    if (newNote.trim() && currentUser && currentStudent) {
      const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);
      // Ensure the document exists or create it
      const notesDocSnap = await getDoc(notesDocRef);
      if (!notesDocSnap.exists()) {
        await setDoc(notesDocRef, { notes: [] });
      }

      const newNoteObj = { text: newNote.trim(), timestamp: Timestamp.now() };
      await updateDoc(notesDocRef, {
        notes: arrayUnion(newNoteObj)
      });

      setNotes(prevNotes => [...prevNotes, newNoteObj]);
      setNewNote('');
    }
  };

  const handleDeleteNote = async (index) => {
    if (currentUser && currentStudent) {
      // Remove note at index locally
      const updatedNotes = notes.filter((_, i) => i !== index);
      setNotes(updatedNotes);

      // Update Firestore
      const notesDocRef = doc(db, 'advisorNotes', `${currentStudent.uid}_${currentUser.uid}`);
      await setDoc(notesDocRef, { notes: updatedNotes }, { merge: true });
    }
  };

  const handleNewNoteChange = (value) => {
    setNewNote(value);
  };

  // =====================================
  //  STUDENT DATA (Current Student)
  // =====================================
  useEffect(() => {
    const fetchStudentData = async () => {
      if (student) {
        try {
          const studentDocRef = doc(db, 'users', student);
          const studentDocSnap = await getDoc(studentDocRef);
          if (studentDocSnap.exists()) {
            setCurrentStudent({ uid: studentDocSnap.id, ...studentDocSnap.data() });

            // Reference to the persistenceCalculations document
            const persistenceCalculationsRef = doc(
              collection(studentDocRef, 'persistence'), 
              'persistenceCalculations'
            );
            const persistenceCalculationsSnap = await getDoc(persistenceCalculationsRef);

            if (persistenceCalculationsSnap.exists()) {
              setPersistenceCalculations(persistenceCalculationsSnap.data());
            } else {
              console.error('No persistenceCalculations document found!');
            }

          } else {
            console.error('No such student!');
          }
        } catch (error) {
          console.error('Error fetching student data:', error);
        }
      }
    };
    fetchStudentData();
  }, [student]);

  // =====================================
  //  ENGAGEMENT RESOURCES
  // =====================================
  useEffect(() => {
    const fetchEngagementResources = async () => {
      try {
        const resourcesSnapshot = await getDocs(collection(db, 'engagementResources'));
        const resourcesData = resourcesSnapshot.docs.map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setEngagementResources(resourcesData);
      } catch (error) {
        console.error('Error fetching engagement resources:', error);
      }
    };
    fetchEngagementResources();
  }, []);

  useEffect(() => {
    const evaluateEngagementResources = () => {
      if (!currentStudent || engagementResources.length === 0) return;

      const applicable = engagementResources.filter(resource => {
        if (!Array.isArray(resource.conditions)) return false;

        return resource.conditions.every(cond => {
          const userValue = getNestedValue(cond.field, currentStudent);
          if (userValue === undefined || userValue === null) return false;

          // Convert to numbers if needed
          const numericUserValue = parseFloat(userValue);
          const numericCondValue = parseFloat(cond.value);

          switch (cond.operator) {
            case 'Equals':
              return numericUserValue === numericCondValue;
            case 'Less Than':
              return numericUserValue < numericCondValue;
            case 'Greater Than':
              return numericUserValue > numericCondValue;
            default:
              return false;
          }
        });
      });
      setApplicableEngagements(applicable);
      setLoadingEngagements(false);
    };
    evaluateEngagementResources();
  }, [currentStudent, engagementResources]);

  const getNestedValue = (fieldPath, obj) => {
    return fieldPath.split('.').reduce((acc, key) => acc && acc[key], obj);
  };

  // =====================================
  //  MAJOR / SURVEY
  // =====================================
  const yearMapping = {
    "First Year": "year1",
    "Second Year": "year2",
    "Third Year": "year3",
    "Fourth Year": "year4",
    "Fifth Year": "year5"
  };

  useEffect(() => {
    if (currentStudent?.surveyAnswers?.Majors?.length > 0) {
      setSelectedMajor(currentStudent.surveyAnswers.Majors[0]);
    }
  }, [currentStudent]);

  const handleMajorChange = (major) => {
    setSelectedMajor(major);
    setDropdownVisible(false); 
  };

  const handleSelectMajorButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
    const nonUsableSet = new Set(nonUsableCourses);
    const requirements = majorCourses.filter(majorCourse =>
      (majorCourse.courseType === 'Major Course' && majorCourse.Requirements) ||
      majorCourse.courseType === 'Requirement'
    );
    const studentCoursesMap = new Map();
    courses.forEach((course, index) => {
      if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
        studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
      }
    });
    const reqIndexToPossibleCourses = [];
    requirements.forEach((requirement, reqIndex) => {
      const possibleCourses = requirement.Possibilities.split(',').map(course => course.trim());
      reqIndexToPossibleCourses[reqIndex] = [];
      possibleCourses.forEach(courseNumber => {
        if (studentCoursesMap.has(courseNumber)) {
          reqIndexToPossibleCourses[reqIndex].push(studentCoursesMap.get(courseNumber).index);
        }
      });
    });
    const matchToCourse = new Array(requirements.length).fill(-1);
    const matchToRequirement = new Array(courses.length).fill(-1);

    function bpm(u, seen) {
      for (const v of reqIndexToPossibleCourses[u]) {
        if (!seen[v]) {
          seen[v] = true;
          if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
            matchToRequirement[v] = u;
            matchToCourse[u] = v;
            return true;
          }
        }
      }
      return false;
    }

    for (let u = 0; u < requirements.length; u++) {
      const seen = new Array(courses.length).fill(false);
      bpm(u, seen);
    }

    const largestValidArray = [];
    for (let u = 0; u < matchToCourse.length; u++) {
      if (matchToCourse[u] !== -1) {
        largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
      }
    }
    return largestValidArray;
  }

  function findElectiveCourses(majorCourses, courses) {
    const electiveRequirements = majorCourses.filter(
      majorCourse => majorCourse.courseType === 'Elective'
    );
    const electiveIndexToPossibleCourses = [];
    electiveRequirements.forEach((electiveReq, electiveIndex) => {
      const electiveName = electiveReq.Possibilities;
      electiveIndexToPossibleCourses[electiveIndex] = [];

      if (electiveName === "Free-NonMajor-Elective") {
        for (let i = 0; i < courses.length; i++) {
          electiveIndexToPossibleCourses[electiveIndex].push(i);
        }
      } else {
        courses.forEach((studentCourse, courseIndex) => {
          if (
            studentCourse.Elective_fulfillment &&
            studentCourse.Elective_fulfillment.includes(electiveName)
          ) {
            electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
          }
        });
      }
    });

    const matchToCourse = new Array(electiveRequirements.length).fill(-1);
    const matchToElective = new Array(courses.length).fill(-1);

    function bpm(u, seen) {
      for (const v of electiveIndexToPossibleCourses[u]) {
        if (!seen[v]) {
          seen[v] = true;
          if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
            matchToElective[v] = u;
            matchToCourse[u] = v;
            return true;
          }
        }
      }
      return false;
    }

    for (let u = 0; u < electiveRequirements.length; u++) {
      const seen = new Array(courses.length).fill(false);
      bpm(u, seen);
    }

    const largestValidArray = [];
    for (let u = 0; u < matchToCourse.length; u++) {
      if (matchToCourse[u] !== -1) {
        largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
      }
    }
    return largestValidArray;
  }

  const getStudentInfo = async () => {
    try {
      if (currentStudent) {
        setCurrentStudent(currentStudent);
        const userDocRef = doc(db, 'users', currentStudent.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const primaryTab = userData?.primaryTab;
          if (!primaryTab) {
            console.error('primaryTab is undefined');
            return;
          }
          const schedules = userData?.schedules;
          if (!schedules || !schedules[primaryTab]) {
            console.error(`No schedules found for primaryTab: ${primaryTab}`);
            return;
          }
          const retrievedFinalizedSchedule = schedules[primaryTab];
          setFinalizedSchedule(retrievedFinalizedSchedule);

          const retrievedCompletedSemesters = userData.completedSemesters || {};
          setCompletedSemesters(retrievedCompletedSemesters);

          const photoUrl = userData.photoUrl || '/default.jpg';
          setPhotoURL(photoUrl);

          let courseArray = [];
          for (let i = 1; i <= 5; i++) {
            let year = `year${i}`;
            for (let j = 1; j <= 2; j++) {
              let semester = `S${j}`;
              if (retrievedFinalizedSchedule[year] && retrievedFinalizedSchedule[year][semester]) {
                courseArray.push(...retrievedFinalizedSchedule[year][semester]['courses']);
              }
            }
          }

          const studentCourses = fetchedCourseData
            ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
            : [];

          const retrievedSurveyAnswers = userData?.surveyAnswers;
          setSurveyAnswers(retrievedSurveyAnswers);

          const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
          const mappedSeeYr = yearMapping[seeYr];

          let courseArray1 = [];
          for (let j = 1; j <= 2; j++) {
            let semester = `S${j}`;
            if (retrievedFinalizedSchedule[mappedSeeYr] && retrievedFinalizedSchedule[mappedSeeYr][semester]) {
              courseArray1.push(...retrievedFinalizedSchedule[mappedSeeYr][semester]['courses']);
            }
          }

          await fetchScheduleData(seeYr, schedules[primaryTab]);

          const currentYearCourses = studentCourses.filter(course => courseArray1.includes(course.courseInfo_courseNumber));
          const totalCreditsCalculated = currentYearCourses.reduce((acc, course) => {
            const credits = parseInt(course.Credits, 10);
            return acc + (isNaN(credits) ? 0 : credits);
          }, 0);

          setTotalCredits(totalCreditsCalculated);
          console.log("Student info fetched successfully");
        } else {
          console.error('User document does not exist');
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateProgressForMajor = (major) => {
    if (!major || !fetchedMajors) {
      return {
        takenCoursesCount: 0,
        planCoursesCount: 0,
        totCoursesCount: 0
      };
    }

    let courseArray = [];
    for (let i = 1; i <= 5; i++) {
      let year = `year${i}`;
      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
          courseArray.push(...finalizedSchedule[year][semester]['courses']);
        }
      }
    }
    const studentCourses = fetchedCourseData
      ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
      : [];

    const majorCourses = Object.values(fetchedMajors[major]);
    const totalCourses = majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length;

    // Taken logic
    const takenCoursesSet = new Set();
    Object.keys(completedSemesters).forEach(year => {
      Object.keys(completedSemesters[year]).forEach(semesterKey => {
        if (completedSemesters[year][semesterKey]) {
          const completedCourses = finalizedSchedule[year]?.[semesterKey]?.courses || [];
          completedCourses.forEach(course => {
            takenCoursesSet.add(course);
          });
        }
      });
    });
    const takenCoursesArray = Array.from(takenCoursesSet);
    const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));

    // Major-specific
    const majorRequiredSpecificCourses = majorCourses.filter((mc) => ((mc.courseType === 'Major Course') && mc.Possibilities === ""));
    const takenMajorCoursesArray = takenCourses.filter(course => {
      return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
    });

    // Elective
    const takenElectiveCoursesArray = findElectiveCourses(
      majorCourses.filter(course => course.courseType === 'Elective'),
      takenCourses
    );

    // Requirement
    const takenRequirementCoursesArray = findRequirementCourses(
      majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
      takenCourses,
      takenElectiveCoursesArray
    );

    // All major + electives + requirement courses in plan
    const allMajorCoursesArray = studentCourses.filter(course => {
      return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
    });
    const allElectiveCoursesArray = findElectiveCourses(
      majorCourses.filter(course => course.courseType === 'Elective'),
      studentCourses
    );
    const allRequirementCoursesArray = findRequirementCourses(
      majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
      studentCourses,
      allElectiveCoursesArray
    );

    let takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;
    let allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;

    return {
      takenCoursesCount: takenCoursesLengths,
      planCoursesCount: allCoursesLengths,
      totCoursesCount: totalCourses
    };
  };

  const { takenCoursesCount, planCoursesCount, totCoursesCount } = calculateProgressForMajor(selectedMajor);

  const fetchScheduleData = (year, finalizedSchedule) => {
    if (year && finalizedSchedule) {
      const mappedYear = yearMapping[year];
      if (mappedYear) {
        const fallCourses = [];
        const springCourses = [];
        if (finalizedSchedule[mappedYear]?.S1) {
          fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
        }
        if (finalizedSchedule[mappedYear]?.S2) {
          springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
        }
        setSchedule({
          Fall: fallCourses,
          Spring: springCourses,
        });
      }
    }
  };

  useEffect(() => {
    const fetchMilestoneData = async () => {
      if (currentStudent) {
        const userDocRef = doc(db, 'users', currentStudent.uid);
        console.log('dref: ', userDocRef);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          setMilestoneData(data);
          if (data.finalizedMilestones) {
            const primaryTab = data.primaryTab || 'defaultTab';
            const milestonesInPrimaryTab = data.finalizedMilestones[primaryTab] || {};

            const milestoneList = Object.values(milestonesInPrimaryTab).reduce((acc, yearData) => {
              Object.values(yearData).forEach(semData => {
                if (semData.milestones) {
                  acc = acc.concat(semData.milestones);
                }
              });
              return acc;
            }, []);

            setMilestones(milestoneList);
            const completed = milestoneList.filter(m => m.completed).length;
            setCompletedMilestones(completed);
          }
        }
      }
    };
    fetchMilestoneData();
  }, [currentStudent]);

  useEffect(() => {
    if (currentStudent && fetchedCourseData) {
      getStudentInfo();
    }
  }, [currentStudent, fetchedCourseData, fetchedSurveyData]);

  const handleNameClick = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 4) {
        navigate('/roles');
      }
      return newCount;
    });
  };

  const userFullName = () => {
    if (currentStudent && currentStudent.firstName && currentStudent.lastName) {
      return `${currentStudent.firstName} ${currentStudent.lastName}`;
    }
    return 'User';
  };

  useEffect(() => {
    const fetchAdvisor = async () => {
      try {
        if (!currentStudent) {
          setLoading(true);
          return;
        }
        const studentId = currentStudent.uid;
        // Using the older syntax: 
        //   const mappingDoc = await db.collection('studentAdvisors').doc(studentId).get();
        //   ...
        //   const { advisorIds } = mappingDoc.data();

        // If you have migrated to the new modular syntax, you'd do:
        //   const docRef = doc(db, 'studentAdvisors', studentId);
        //   const docSnap = await getDoc(docRef);
        const mappingDocSnap = await db.collection('studentAdvisors').doc(studentId).get();
        
        if (!mappingDocSnap.exists) {
          setIsLoading(false);
          return;
        }
        const { advisorIds } = mappingDocSnap.data();
        if (!advisorIds) {
          setIsLoading(false);
          return;
        }
        const advisorPromises = advisorIds.map(id => db.collection('advisors').doc(id).get());
        const advisorDocs = await Promise.all(advisorPromises);

        const existingAdvisors = advisorDocs
          .filter(doc => doc.exists)
          .map(doc => ({
            id: doc.id,
            advisorId: doc.id,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            meetingLink: doc.data().meetingLink || '',
            profilePicture: doc.data().profilePicture || '/default.jpg',
          }));

        const missingAdvisors = advisorDocs.filter(doc => !doc.exists).map(doc => doc.id);
        if (missingAdvisors.length > 0) {
          toast.error(`Assigned advisor data not found for IDs: ${missingAdvisors.join(', ')}`);
        }
        setAdvisors(existingAdvisors);
      } catch (error) {
        console.error('Error fetching advisor data:', error);
        toast.error('Failed to fetch advisor data.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdvisor();
  }, [currentStudent]);

  useEffect(() => {
    const fetchScheduledMeetings = async () => {
      try {
        const meetingsSnapshot = await db
          .collection('meetings')
          .where('studentId', '==', currentStudent.uid)
          .orderBy('datetime', 'asc')
          .limit(5)
          .get();

        const meetingsData = meetingsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setScheduledMeetings(meetingsData);
      } catch (error) {
        console.error('Error fetching scheduled meetings:', error);
      }
    };
    if (currentStudent) {
      fetchScheduledMeetings();
    }
  }, [currentStudent]);

  const fetchRecommendedResources = async () => {
    try {
      if (currentStudent) {
        const userDocRef = doc(db, 'users', currentStudent.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const resourceIds = userData.Resources || [];
          if (resourceIds.length > 0) {
            const resourcesData = [];
            const batchSize = 10;
            const batches = [];
            for (let i = 0; i < resourceIds.length; i += batchSize) {
              const batchIds = resourceIds.slice(i, i + batchSize);
              const resourcesCollection = collection(db, 'resources');
              const resourcesQuery = query(resourcesCollection, where('__name__', 'in', batchIds));
              batches.push(getDocs(resourcesQuery));
            }
            const snapshots = await Promise.all(batches);
            snapshots.forEach(snapshot => {
              snapshot.docs.forEach(docSnap => {
                resourcesData.push({
                  id: docSnap.id,
                  ...docSnap.data(),
                });
              });
            });
            setRecommendedResources(resourcesData);
          } else {
            setRecommendedResources([]);
          }
        } else {
          setRecommendedResources([]);
        }
      }
    } catch (error) {
      console.error('Error fetching recommended resources:', error);
      setResourcesError('Failed to load recommended resources.');
    } finally {
      setResourcesLoading(false);
    }
  };

  useEffect(() => {
    if (currentStudent) {
      fetchRecommendedResources();
    }
  }, [currentStudent]);

  // Add this helper function somewhere in your component file (e.g., below your imports)
  // const updateApplicableEngagementsInFirestore = async (studentUid, applicableEngagements) => {
  //   try {
  //     const docRef = doc(db, 'Persistence', studentUid);
  //     // We use setDoc with { merge: true } to avoid overwriting the entire document
  //     await setDoc(docRef, { applicableEngagements }, { merge: true });
  //     console.log('applicableEngagements successfully written to Firestore!');
  //   } catch (error) {
  //     console.error('Error writing applicableEngagements to Firestore:', error);
  //   }
  // };

  // useEffect(() => {
  //   // Only save to Firestore if we have a valid currentStudent and some engagements
  //   if (currentStudent?.uid && applicableEngagements.length > 0) {
  //     updateApplicableEngagementsInFirestore(currentStudent.uid, applicableEngagements);
  //   }
  // }, [currentStudent, applicableEngagements]);
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Ref for the dropdown to detect outside clicks
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleViewStudentPlan = () => {
    if (currentStudent && currentStudent.uid) {
      navigate(`/plan/${currentStudent.uid}`);
    } else {
      alert('Student information is unavailable.');
    }
    setIsDropdownOpen(false);
  };

  const handleRecommendResource = () => {
    if (currentStudent && currentStudent.uid) {
      navigate(`/resources/${currentStudent.uid}`);
    } else {
      alert('Student information is unavailable.');
    }
    setIsDropdownOpen(false);
  };

  if (loading) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 
            22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 
            50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 
            91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 
            50.5908C90.9186 27.9921 72.5987 9.67226 50 
            9.67226C27.4013 9.67226 9.08144 27.9921 
            9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 
            39.0409C96.393 38.4038 97.8624 35.9116 97.0079 
            33.5539C95.2932 28.8227 92.871 24.3692 89.8167 
            20.348C85.8452 15.1192 80.8826 10.7238 75.2124 
            7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 
            1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 
            1.27873C39.2613 1.69328 37.813 4.19778 38.4501 
            6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 
            10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 
            10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 
            15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 
            25.841C84.9175 28.9121 86.7997 32.2913 88.1811 
            35.8758C89.083 38.2158 91.5421 39.6781 93.9676 
            39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const totalMilestones = milestones.length;
  const milestoneProgress = totalMilestones > 0
    ? Math.round((completedMilestones / totalMilestones) * 100)
    : 0;
  const progressTaken = totCoursesCount > 0
    ? Math.round((planCoursesCount / totCoursesCount) * 100)
    : 0;
  const progressDone = totCoursesCount > 0
    ? Math.round((takenCoursesCount / totCoursesCount) * 100)
    : 0;
  const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

  const TagList = ({ tags }) => {
    const [showAll, setShowAll] = useState(false);

    if (!Array.isArray(tags) || tags.length === 0) {
      return (
        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          No Tags Selected
        </span>
      );
    }

    const handleToggle = () => setShowAll(!showAll);

    return (
      <div className="relative">
        <div
          className={`flex flex-wrap items-center ${
            showAll ? 'max-h-none' : 'max-h-12 overflow-hidden'
          } transition-all`}
        >
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`flex items-center mr-2 mb-1 ${
                !showAll && index >= 3 ? 'hidden' : ''
              }`}
              style={{ lineHeight: '1.2' }}
            >
              <FontAwesomeIcon
                icon={faTag}
                className="mr-1"
                style={{ verticalAlign: 'middle', lineHeight: 'inherit' }}
              />
              <span className="text-sm leading-none">{tag}</span>
            </div>
          ))}
          {showAll && (
            <button
              onClick={handleToggle}
              className="rounded bg-gray-100 px-1 py-1 flex items-center justify-center hover:text-blue-500 hover:underline"
              aria-label="Hide"
            >
              <FontAwesomeIcon icon={faEyeSlash} size="sm" />
            </button>
          )}
        </div>
        {tags.length > 3 && !showAll && (
          <button
            onClick={handleToggle}
            className="absolute top-0 rounded right-0 bg-gray-100 px-1 py-.5 flex items-center justify-center hover:text-blue-500 hover:underline"
            aria-label="Toggle"
            style={{ right: '-18px' }}
          >
            <BsThreeDots size={15} />
          </button>
        )}
      </div>
    );
  };

  const calculateTotalCredits = (schedule) => {
    const allClasses = Object.values(schedule).flat();
    const filteredCourses = fetchedCourseData.filter(course =>
      allClasses.includes(course.courseInfo_courseNumber)
    );
    const totalCredits = filteredCourses.reduce((sum, course) => {
      return sum + parseInt(course.Credits, 10);
    }, 0);
    return totalCredits;
  };

  const removeResourceFromStudent = async (resourceId) => {
    if (!currentStudent) {
      return toast.error("No current student found.");
    }
  
    try {
      // 1) Remove from Firestore
      const studentRef = doc(db, "users", currentStudent.uid);
      await updateDoc(studentRef, {
        Resources: arrayRemove(resourceId),
      });
  
      // 2) Optimistic update: filter out from local state
      setRecommendedResources((prev) => 
        prev.filter((resource) => resource.id !== resourceId)
      );
  
      toast.success("Resource removed from student successfully.");
    } catch (error) {
      console.error("Error removing resource:", error);
      toast.error("Failed to remove resource from the student.");
    }
  };

  return (
    <div>
      <div className="">
        <CustomSidebar adjustment="Student Profile" />
      </div>

      <Card className="ml-20 mb-2 bg-white-100 shadow-md relative mt-10 w-full mx-auto max-w-[85vw] min-w-[85vw]">
        {/* Dropdown container (top-right corner of Card) */}
        <div
          className="absolute top-4 right-4 inline-block text-left"
          ref={dropdownRef}
        >
          {/* Flowbite-inspired button */}
          <button
            id="dropdownHelperButton"
            data-dropdown-toggle="dropdownHelper"
            className="
              text-white bg-blue-700 hover:bg-blue-800
              focus:ring-4 focus:outline-none focus:ring-blue-300
              font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            "
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            Student Actions
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div
              id="dropdownHelper"
              className="
                z-10 mt-2 bg-white divide-y divide-gray-100
                rounded-lg shadow w-60 dark:bg-gray-700
                dark:divide-gray-600 absolute right-0
              "
            >
              <ul
                className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHelperButton"
              >
                {/* --- View Student Plan --- */}
                <li>
                  <button
                    onClick={handleViewStudentPlan}
                    className="
                      flex flex-col p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600
                      w-full text-left focus:outline-none
                    "
                  >
                    {/* Action Label */}
                    <span className="font-medium text-gray-900 dark:text-gray-300">
                      View Student Plan
                    </span>
                    {/* Helper Text */}
                    <span className="text-xs font-normal text-gray-500 dark:text-gray-300">
                      Navigate to the student’s academic plan page, where you can review or modify course selections.
                    </span>
                  </button>
                </li>

                {/* --- Recommend Resource --- */}
                <li>
                  <button
                    onClick={handleRecommendResource}
                    className="
                      flex flex-col p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600
                      w-full text-left focus:outline-none
                    "
                  >
                    {/* Action Label */}
                    <span className="font-medium text-gray-900 dark:text-gray-300">
                      Recommend Resource
                    </span>
                    {/* Helper Text */}
                    <span className="text-xs font-normal text-gray-500 dark:text-gray-300">
                      Suggest a specific tool, website, or university service that may help the student’s academic journey.
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Top Section */}
        <div className="flex justify-between">
          <div className="flex items-center mb-1">
            <img
              className="w-[160px] h-[160px] mb-4 mr-5 rounded-full"
              src={photoURL || '/default.jpg'}
              alt="Profile Picture"
            />
            <div className="max-w-[calc(100%-100px)]">
              <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>
                {userFullName()}
              </h1>
              {currentStudent?.email && (
                <p className="text-sm mb-1">{currentStudent.email}</p>
              )}
              {(currentStudent?.surveyAnswers?.Year || 'N/A') &&
                (currentStudent?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                  <p className="text-sm mb-1">
                    {currentStudent?.surveyAnswers?.Year || 'N/A'} -{' '}
                    {currentStudent?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                  </p>
              )}
              {currentStudent?.surveyAnswers?.Majors?.length > 0 && (
                <p className="text-sm">
                  <FontAwesomeIcon icon={faBook} className="mr-1" />
                  {currentStudent?.surveyAnswers?.Majors?.length === 1
                    ? 'Major in'
                    : 'Majors in'}{' '}
                  {currentStudent?.surveyAnswers?.Majors?.join(', ')}
                </p>
              )}
              {currentStudent?.surveyAnswers?.Minors?.length > 0 && (
                <p className="text-sm mb-1">
                  <FontAwesomeIcon icon={faBook} className="mr-1" />
                  {currentStudent.surveyAnswers.Minors.length === 1
                    ? 'Minor in'
                    : 'Minors in'}{' '}
                  {currentStudent.surveyAnswers.Minors.join(', ')}
                </p>
              )}
              {currentStudent?.surveyAnswers?.Campus && (
                <p className="text-sm mb-1">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                  {currentStudent.surveyAnswers.Campus}
                </p>
              )}
              {(() => {
                const startMonth = currentStudent?.surveyAnswers?.SchoolStart?.month;
                const startYear = currentStudent?.surveyAnswers?.SchoolStart?.year;
                const endMonth = currentStudent?.surveyAnswers?.SchoolEnd?.month;
                const endYear = currentStudent?.surveyAnswers?.SchoolEnd?.year;
                const startDate = startMonth && startYear
                  ? `${startMonth} ${startYear}`
                  : 'N/A';
                const endDate = endMonth && endYear
                  ? `${endMonth} ${endYear}`
                  : 'N/A';

                if (startDate !== 'N/A' || endDate !== 'N/A') {
                  return (
                    <p className="text-sm mb-1">
                      <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                    </p>
                  );
                }
                return null;
              })()}
              {currentStudent && (
                <div
                  className="flex items-center text-sm cursor-pointer hover:text-purple-600 mt-1"
                  title="Click to view GPA progression"
                >
                  <FontAwesomeIcon icon={faChartLine} className="mr-1" />
                  GPA: {currentStudent.surveyAnswers.GPA}
                </div>
              )}
              {(currentStudent?.surveyAnswers?.Pronouns ||
                (Array.isArray(currentStudent?.surveyAnswers?.Tags) &&
                 currentStudent.surveyAnswers.Tags.length > 0)) && (
                <div className="flex items-center text-sm">
                  <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                  {currentStudent?.surveyAnswers?.Pronouns && (
                    <span className="mr-2 mb-1">
                      Pronouns: {currentStudent.surveyAnswers.Pronouns}
                    </span>
                  )}
                  <TagList tags={currentStudent?.surveyAnswers?.Tags} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Progress Bars Section */}
        <div className="flex justify-between ">
          <div className="mb-0">
            <div className="flex items-center mb-2">
              {surveyAnswers.Majors?.length > 1 ? (
                <>
                  <h4>Progress Towards</h4>
                  <div className="relative">
                    <button
                      className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                      onClick={handleSelectMajorButtonClick}
                    >
                      <span>{selectedMajor || 'N/A'}</span>
                    </button>
                    <span className="ml-1">Pathway</span>
                    {dropdownVisible && (
                      <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                        <ul className="py-1">
                          {surveyAnswers.Majors.map((major, index) => (
                            <li key={index}>
                              <button
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                onClick={() => handleMajorChange(major)}
                              >
                                {major}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <h4>
                  Progress Towards {surveyAnswers.Majors?.[0] || 'N/A'} Pathway
                </h4>
              )}
            </div>
            <div className="relative w-80 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
                className="absolute bg-green-500 h-2.5"
                style={{
                  width: `${progressDone}%`,
                  left: '0',
                  top: 0,
                  borderTopLeftRadius: '9999px',
                  borderBottomLeftRadius: '9999px',
                  borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                  borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                }}
              ></div>
              <div
                className="absolute bg-yellow-300 h-2.5"
                style={{
                  width: `${progressTaken}%`,
                  left: `${progressDone}%`,
                  top: 0,
                  borderTopRightRadius: '9999px',
                  borderBottomRightRadius: '9999px',
                  borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                  borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                }}
              ></div>
            </div>
            <div className="flex justify-between mt-2 space-x-10 w-full items-center">
              <div className="rounded bg-green-100 px-2 py-0.3">
                <span className="text-green-600 font-semibold">
                  {takenCoursesCount} Taken
                </span>
              </div>
              <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                <span className="text-yellow-500 font-semibold">
                  {planCoursesCount || 0} Planned
                </span>
              </div>
              <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                <span className="text-gray-600 font-semibold">
                  {remainingCourses || 0} Unplanned
                </span>
              </div>
            </div>
          </div>
          <div className="milestone-progress">
            <h4 className="mb-2">Milestone Progress</h4>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-500 h-2.5 rounded-full"
                style={{ width: `${milestoneProgress || 0}%` }}
              ></div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="rounded bg-blue-100 px-2 py-0.3">
                <span className="text-blue-600 font-semibold">
                  {completedMilestones || 0} Completed
                </span>
              </div>
              <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                <span className="text-gray-500 font-semibold">
                  {(totalMilestones || 0) - (completedMilestones || 0)} Remaining
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ======== TABS NAVIGATION (below progress bars) ======== */}
        <ul className="flex flex-nowrap mt-2 border-b border-gray-300">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className="relative mr-2 flex items-center flex-shrink-0"
              role="presentation"
            >
                <button
                  onClick={() => setActiveTab(tab.name)}
                  className={`inline-block py-2 px-3 rounded-t-lg focus:outline-none ${
                    activeTab === tab.name
                      ? 'border-b-2 border-blue-500'
                      : 'border-b-2 border-transparent'
                  }`}
                >
                  {tab.label}
                </button>
            </li>
          ))}
        </ul>

        {/* ======== BOTTOM-HALF CONTENT: visible when activeTab === 'profile' ======== */}
        {activeTab === 'profile' && (
          <>
            {/* Engagement, Traits, Advising (three columns) */}
            <div className="grid grid-cols-3 gap-2">
              {/* Engagement Opportunities */}
              <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                <h2 className="text-gray-700 font-semibold mb-2">
                  Engagement Opportunities
                </h2>
                {loadingEngagements ? (
                  <Spinner aria-label="Loading engagement opportunities" />
                ) : applicableEngagements.length > 0 ? (
                  <ul>
                    {applicableEngagements.map(resource => (
                      <li key={resource.id}>
                        <div className="bg-white mb-2 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md p-2 flex flex-col justify-between relative">
                          <h5 className="text-xs font-medium text-gray-600 dark:text-gray-400 truncate py-1">
                            {resource.name}
                          </h5>
                          {/* Alert Icon */}
                          <span className="absolute bottom-2 right-2 text-red-500 text-sm">
                            ⚠️
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm text-gray-500">
                    No engagement opportunities applicable.
                  </p>
                )}
              </div>

              {/* Traits, Skills, Interests */}
              <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                <h2 className="text-gray-700 font-semibold mb-2">
                  Traits, Skills, and Interests
                </h2>
                <div className="overflow-y-auto max-h-60">
                  <div className="flex flex-wrap">
                    {/* Personality Traits */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
                      Personality Traits
                    </h3>
                    {Array.isArray(currentStudent?.surveyAnswers?.RecPersonality) &&
                     currentStudent.surveyAnswers.RecPersonality.length > 0 ? (
                      currentStudent.surveyAnswers.RecPersonality.map((trait, index) => (
                        <span
                          key={index}
                          className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 mb-2"
                        >
                          {trait}
                        </span>
                      ))
                    ) : (
                      <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
                        No Personality Traits
                      </span>
                    )}

                    {/* Skills */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
                      Skills
                    </h3>
                    {Array.isArray(currentStudent?.surveyAnswers?.RecSkills) &&
                     currentStudent.surveyAnswers.RecSkills.length > 0 ? (
                      currentStudent.surveyAnswers.RecSkills.map((skill, index) => (
                        <span
                          key={index}
                          className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400 mb-2"
                        >
                          {skill}
                        </span>
                      ))
                    ) : (
                      <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
                        No Skills
                      </span>
                    )}

                    {/* Interests */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
                      Interests
                    </h3>
                    {Array.isArray(currentStudent?.surveyAnswers?.RecInterests) &&
                     currentStudent.surveyAnswers.RecInterests.length > 0 ? (
                      currentStudent.surveyAnswers.RecInterests.map((interest, index) => (
                        <span
                          key={index}
                          className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2"
                        >
                          {interest}
                        </span>
                      ))
                    ) : (
                      <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
                        No Interests
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* Advising */}
              <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200 flex flex-col">
                <h2 className="text-gray-700 font-semibold mb-2">Advising Team</h2>
                <div className="flex items-center space-x-4 mb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
                  {(advisors || []).slice(0, 3).map((advisor, index) => (
                    <div key={index} className="flex flex-col items-center">
                      <img
                        className="w-12 h-12 rounded-full object-cover mb-1"
                        src={advisor.profilePicture || '/default.jpg'}
                        alt={`${advisor.firstName} ${advisor.lastName}`}
                      />
                      <span className="text-sm font-medium text-gray-800 dark:text-white">
                        {`${advisor.firstName} ${advisor.lastName}`}
                      </span>
                    </div>
                  ))}
                  {(advisors || []).length > 3 && (
                    <span className="text-sm text-blue-600 dark:text-blue-400">
                      +{(advisors || []).length - 3} more
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <h4 className="text-sm font-semibold text-gray-700 dark:text-gray-100 mb-1">
                    Upcoming Meetings
                  </h4>
                  {scheduledMeetings.length > 0 ? (
                    <ul className="space-y-1 max-h-24 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                      {scheduledMeetings.slice(0, 5).map((meeting, index) => (
                        <li
                          key={index}
                          className="text-sm text-gray-600 dark:text-gray-300"
                        >
                          {new Date(meeting.datetime.seconds * 1000).toLocaleString(
                            'en-US',
                            {
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            }
                          )}{' '}
                          with {meeting.advisorName}
                        </li>
                      ))}
                      {scheduledMeetings.length > 5 && (
                        <li className="text-sm text-blue-600 dark:text-blue-400">
                          +{scheduledMeetings.length - 5} more...
                        </li>
                      )}
                    </ul>
                  ) : (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      No upcoming meetings scheduled.
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Current Schedule */}
            <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
              <h2 className="text-gray-700 font-semibold">
                Current Schedule ({currentStudent?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {calculateTotalCredits(schedule)}
              </h2>
              {Object.keys(schedule).length > 0 ? (
                <div className="flex justify-between">
                  <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}>
                    <h3 className="text-lg text-gray-500 font-semibold mt-2">Fall Semester</h3>
                    <ul>
                      {schedule.Fall.length > 0 ? (
                        schedule.Fall.map((course, index) => (
                          <li
                            key={index}
                            className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                          >
                            {course}
                          </li>
                        ))
                      ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                          No Planned Courses for Fall Semester
                        </span>
                      )}
                    </ul>
                  </div>
                  <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}>
                    <h3 className="text-lg text-gray-500 font-semibold mt-2">Spring Semester</h3>
                    <ul>
                      {schedule.Spring.length > 0 ? (
                        schedule.Spring.map((course, index) => (
                          <li
                            key={index}
                            className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                          >
                            {course}
                          </li>
                        ))
                      ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                          No Planned Courses for Spring Semester
                        </span>
                      )}
                    </ul>
                  </div>
                </div>
              ) : (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  No Planned Courses for Current Year
                </span>
              )}
            </div>

            {/* Recommended Resources */}
            <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
              <h2 className="text-gray-700 font-semibold mb-4">Recommended Resources</h2>
              {resourcesLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner aria-label="Loading recommended resources" />
                </div>
              ) : resourcesError ? (
                <div className="text-red-500">{resourcesError}</div>
              ) : recommendedResources.length > 0 ? (
                <div className="flex flex-col space-y-4">
                  {recommendedResources.map((resource) => (
                    <div
                      key={resource.id}
                      className="relative flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
                      onMouseEnter={() => setHoveredResourceId(resource.id)}
                      onMouseLeave={() => setHoveredResourceId(null)}
                    >
                      <img
                        className="w-48 h-48 sm:w-56 sm:h-56 flex-shrink-0 rounded-t-lg sm:rounded-l-lg object-cover"
                        src={resource.image || 'https://via.placeholder.com/150'}
                        alt={resource.name}
                      />
                      <div className="p-4 flex flex-col justify-between w-full">
                        <div>
                          <h5 className="text-lg font-bold text-gray-900 dark:text-white">
                            {resource.name}
                          </h5>
                          <p className="text-sm text-gray-700 dark:text-gray-400 mt-1">
                            {resource.description}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                            Department: {resource.department}
                          </p>
                        </div>
                        {resource.link ? (
                          <Button
                            color="gray"
                            size="xs"
                            className="mt-2 self-start"
                            onClick={() => window.open(resource.link, '_blank')}
                          >
                            Learn More
                          </Button>
                        ) : (
                          <span className="text-xs text-gray-500 mt-2">
                            No link available
                          </span>
                        )}
                      </div>

                      {/* "Remove from Student" button, shown only on hover */}
                      {hoveredResourceId === resource.id && (
                        <button
                          className="
                            absolute top-2 right-2
                            bg-red-600 text-white text-sm font-semibold 
                            px-3 py-1 rounded
                            hover:bg-red-700
                          "
                          onClick={() => removeResourceFromStudent(resource.id)}
                        >
                          Remove from Student
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-gray-500">No recommended resources available.</div>
              )}
            </div>
          </>
        )}
        {activeTab === 'student analytics' && (
          <StudentAnalytics className='w-full' currentStudent={currentStudent} persistenceCalculations={persistenceCalculations}  />
        )}

        {activeTab === 'notes' && (
          <NotesTab className='w-full'
            notes={notes}
            newNote={newNote}
            onAddNote={handleAddNote}
            onDeleteNote={handleDeleteNote}
            onNewNoteChange={handleNewNoteChange}
          />
        )}

      </Card>
    </div>
  );
};

export default AdvisorReviewStudentProfile;
