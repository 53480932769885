import React, { useState, useEffect } from 'react';
import NavBar from '../../NavBar';
import { db, auth } from '../../firebase';
import CollapsibleExtracurricular from './CollapsibleExtracurricular';
import ExtracurricularCart from './ExtracurricularCart';

const fetchDataFromBackend = async () => {
  const apiUrl = 'http://localhost:4000/extracurricular';

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      console.error(`Error fetching data. Status: ${response.status}`);
      throw new Error(`Error fetching data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data || {};
  } catch (error) {
    console.error('Network error:', error);
    return {};
  }
};

const ExtracurricularPage = () => {
  const [activities, setActivities] = useState({});
  const [filteredActivities, setFilteredActivities] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [activityTypeFilter, setActivityTypeFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [activityTypes, setActivityTypes] = useState([]);
  const [finalizedExtracurriculars, setFinalizedExtracurriculars] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isRecommendationMode, setIsRecommendationMode] = useState(false);
  const [originalCardTitle, setOriginalCardTitle] = useState("Extracurricular Activities");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const fetchedActivities = await fetchDataFromBackend();

        setActivities(fetchedActivities);
        setFilteredActivities(fetchedActivities);

        const types = [...new Set(Object.values(fetchedActivities).map(activity => activity.ActivityType))];
        setActivityTypes(types);
      } catch (error) {
        console.error('Error fetching extracurricular activities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    const filtered = Object.entries(activities).filter(
      ([activityName, activity]) =>
        activityName.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (activityTypeFilter ? activity.ActivityType === activityTypeFilter : true)
    );

    const filteredActivitiesObject = Object.fromEntries(filtered);
    setFilteredActivities(filteredActivitiesObject);
  }, [searchQuery, activityTypeFilter, activities]);

  useEffect(() => {
    const fetchFinalizedExtracurriculars = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(currentUser.uid);
          const doc = await userDocRef.get();

          if (doc.exists) {
            const userData = doc.data();
            if (userData.finalizedExtracurriculars) {
              setFinalizedExtracurriculars(userData.finalizedExtracurriculars);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching finalized extracurriculars:', error);
      }
    };

    fetchFinalizedExtracurriculars();
  }, [currentUser]);

  const addToFinalizedExtracurriculars = async (userId, extracurricularName) => {
    try {
      if (userId) {
        const userDocRef = db.collection('users').doc(userId);
        await userDocRef.update({
          finalizedExtracurriculars: [...finalizedExtracurriculars, extracurricularName]
        });
        setFinalizedExtracurriculars(prevFinalizedExtracurriculars => [...prevFinalizedExtracurriculars, extracurricularName]);
      }
    } catch (error) {
      console.error('Error adding extracurricular to finalized extracurriculars:', error);
    }
  };

  const handleAddToFinalizedExtracurriculars = activityName => {
    addToFinalizedExtracurriculars(currentUser.uid, activityName);
  };

  const removeFromFinalizedExtracurriculars = async (userId, extracurricularName) => {
    try {
      if (userId) {
        const userDocRef = db.collection('users').doc(userId);
        await userDocRef.update({
          finalizedExtracurriculars: finalizedExtracurriculars.filter(item => item !== extracurricularName)
        });
        setFinalizedExtracurriculars(prevFinalizedExtracurriculars => prevFinalizedExtracurriculars.filter(item => item !== extracurricularName));
      }
    } catch (error) {
      console.error('Error removing extracurricular from finalized extracurriculars:', error);
    }
  };

  const handleRemoveFromFinalizedExtracurriculars = async extracurricularName => {
    removeFromFinalizedExtracurriculars(currentUser.uid, extracurricularName);
  };

  const handleResetFilters = async () => {
    setSearchQuery('');
    setActivityTypeFilter('');
    setIsRecommendationMode(false);
    setOriginalCardTitle("Extracurricular Activities");

    try {
      const fetchedActivities = await fetchDataFromBackend();
      setActivities(fetchedActivities);
      setFilteredActivities(fetchedActivities);

      const types = [...new Set(Object.values(fetchedActivities).map(activity => activity.ActivityType))];
      setActivityTypes(types);
    } catch (error) {
      console.error('Error fetching extracurricular activities:', error);
    }
  };

  const handleRecommendExtracurriculars = async () => {
    try {
      const apiUrl = `http://localhost:4000/api/recommended-extracurriculars/${currentUser.uid}`;
      const response = await fetch(apiUrl);

      if (!response.ok) {
        console.error(`Error fetching recommended extracurriculars. Status: ${response.status}`);
        throw new Error(`Error fetching recommended extracurriculars. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Recommended Extracurriculars:', data);
      setFilteredActivities(data || []); // Update to set the recommended extracurriculars
      setIsRecommendationMode(true);
      setOriginalCardTitle("Recommended Extracurriculars");
    } catch (error) {
      console.error('Error fetching recommended extracurriculars:', error);
    }
  };

  return (
    <div>
      <NavBar />
      <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ flex: '0 0 20%', marginBottom: '20px' }}>
          <div className="card" style={{ padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4' }}>
            <h5 className="card-title">Filters</h5>
            <div>
              <label htmlFor="searchQuery">Search:</label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control mb-2"
              />
  
              <label htmlFor="activityTypeFilter">Activity Type:</label>
              <select
                id="activityTypeFilter"
                value={activityTypeFilter}
                onChange={(e) => setActivityTypeFilter(e.target.value)}
                className="form-control mb-2"
              >
                <option value="">Select Activity Type</option>
                {activityTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <button className="btn btn-secondary" onClick={handleResetFilters} style={{ marginRight: '10px'}}>
                Reset Filters
              </button>
              <button className="btn btn-primary" onClick={handleRecommendExtracurriculars} style={{ marginTop: '10px' }}>
                Recommend Extracurriculars
              </button>
            </div>
          </div>
        </div>
  
        <div style={{ flex: '20 0 0%', width: '1000px', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
          <div className="card" style={{ width: '100%', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4', minHeight: '200px' }}>
          <h5 className="card-title">{isRecommendationMode ? "Recommended Extracurriculars" : originalCardTitle}</h5>
            <div style={{ height: '500px', overflowY: 'auto', marginBottom: '0px' }}>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div>
                  {Object.entries(filteredActivities).map(([activityName, activity]) => (
                    <div key={activityName} style= {{background: '#b3d7ed'}}>
                      <CollapsibleExtracurricular
                        activity={activity}
                        onAddToFinalizedExtracurriculars={handleAddToFinalizedExtracurriculars}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
  
        <div style={{ flex: '1', minWidth: '300px'}}>
          <div className="class-cart card" style={{ width: '100%', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#f4f4f4', minHeight: '200px', marginBottom: '20px' }}>
            <h5 className="card-title">My Extracurriculars</h5>
            <div className="class-cart-items-container">
              {finalizedExtracurriculars.length === 0 ? (
                <p>No finalized extracurriculars added</p>
              ) : (
                <div>
                  <ExtracurricularCart
                    Extracurriculars={finalizedExtracurriculars}
                    onRemoveExtracurricular={handleRemoveFromFinalizedExtracurriculars}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default ExtracurricularPage;