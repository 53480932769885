import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../../firebase';
import StudentItem from './StudentItem'
import SearchComponent from './SearchComponent';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { FaTimes, FaPlus } from 'react-icons/fa';
import CampaignModal from './CampaignModal';
import TemplateModal from './TemplateModal';


const StudentListing = ({ students, adjustment, selectedResource, onClose, advisors }) => {

  const [filteredStudents, setFilteredStudents] = useState(students);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const location = useLocation();
  const { filterType, filterValue } = location.state || {};

  const { currentUser } = useAuth();

  // State for Campaign Modal
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);

  // Exclude certain fields
  const excludeFields = ['id', 'fromFirestore', 'surveyAnswers', 'name', 'email'];

  // Memoize fieldTypes to prevent unnecessary re-renders
  const fieldTypes = useMemo(() => {
    const types = {};
    if (students.length > 0) {
      const student = students[0];
      Object.keys(student).forEach((field) => {
        if (!excludeFields.includes(field)) {
          const value = student[field];
          if (Array.isArray(value)) {
            // For arrays, determine the type of the first element
            if (value.length > 0) {
              types[field] = `array-${typeof value[0]}`;
            } else {
              types[field] = 'array';
            }
          } else {
            types[field] = typeof value;
          }
        }
      });
    }
    console.log('CFT Types: ', types);
    return types;
  }, [students]);

  // Fetch selectedStudents from Firestore
  useEffect(() => {
    const fetchSelectedStudents = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSelectedStudents(data.selectedStudents || []);
        }
      }
    };
    fetchSelectedStudents();
  }, [currentUser]);

  const handleCheckboxChange = useCallback(
    async (studentId, isChecked) => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);

        if (isChecked) {
          await updateDoc(userRef, {
            selectedStudents: arrayUnion(studentId),
          });
          setSelectedStudents((prev) => [...prev, studentId]);
        } else {
          await updateDoc(userRef, {
            selectedStudents: arrayRemove(studentId),
          });
          setSelectedStudents((prev) => prev.filter((id) => id !== studentId));
        }
      } catch (error) {
        console.error('Error updating selectedStudents array:', error);
        toast.error('Failed to update selection. Please try again.');
      }
    },
    [currentUser]
  );

  const handleSelectAll = useCallback(async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
  
      if (filteredStudents.every(student => selectedStudents.includes(student.id))) {
        // Unselect filtered students
        const updatedSelectedStudents = selectedStudents.filter(
          id => !filteredStudents.some(student => student.id === id)
        );

        await updateDoc(userRef, {
            selectedStudents: updatedSelectedStudents,
        });
        setSelectedStudents(updatedSelectedStudents);
      } else {
        // Select all students
        const studentIdsToSelect = filteredStudents.map((student) => student.id);
  
        await updateDoc(userRef, {
          selectedStudents: studentIdsToSelect,
        });
        setSelectedStudents(studentIdsToSelect);
      }
    } catch (error) {
      console.error('Error selecting all students:', error);
      toast.error('Failed to select all students. Please try again.');
    }
  }, [currentUser, filteredStudents, selectedStudents]);
  

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  const handleFilter = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const toggleTemplateModalVisibility = () => {
    if (selectedStudents.length === 0) {
      toast.warning('Please select student(s) to add a template to.');
    } else {
      setIsTemplateModalVisible(!isTemplateModalVisible);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsTemplateModalVisible(false);
      }
    };
    if (isTemplateModalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTemplateModalVisible]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsCampaignModalOpen(false);
      }
    };
    if (isCampaignModalOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCampaignModalOpen]);

  useEffect(() => {
    const fetchAdvisorStudents = async () => {
      if (currentUser && adjustment === 'Dashboard') {
        try {
          const studentAdvisorsRef = collection(db, 'studentAdvisors');
          const q = query(
            studentAdvisorsRef,
            where('advisorIds', 'array-contains', currentUser.uid)
          );
          const querySnapshot = await getDocs(q);

          const advisorStudentIds = querySnapshot.docs.map((doc) => doc.id);
          setFilteredStudents((prevStudents) =>
            prevStudents.filter((student) => advisorStudentIds.includes(student.id))
          );
        } catch (error) {
          console.error('Error fetching advisor students:', error);
          toast.error('Failed to fetch students. Please try again.');
        }
      }
    };

    fetchAdvisorStudents();
  }, [currentUser, adjustment]);


  const handleRecommendResource = useCallback(
    async (studentId) => {
      try {
        const studentRef = doc(db, 'users', studentId);
        await updateDoc(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
        // Update local state to reflect the change
        setFilteredStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === studentId
              ? {
                  ...student,
                  resources: [...(student.resources || []), selectedResource.id],
                }
              : student
          )
        );
      } catch (error) {
        console.error('Error recommending resource:', error);
        toast.error('Failed to recommend resource. Please try again.');
      }
    },
    [selectedResource]
  );

  // Apply filters and search term
  useEffect(() => {
    let filtered = students;

    // Apply search term
    if (searchTerm) {
      filtered = filtered.filter((student) =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply other filters
    if (filters.gradYear && filters.gradYear.length > 0) {
      filtered = filtered.filter((student) =>
        filters.gradYear.includes(student.surveyAnswers?.SchoolEnd?.year)
      );
    }

    if (filters.program && filters.program.length > 0) {
      filtered = filtered.filter((student) =>
        Array.isArray(student.major)
          ? student.major.some((program) => filters.program.includes(program))
          : filters.program.includes(student.major)
      );
    }

    if (filters.advisors && filters.advisors.length > 0) {
      filtered = filtered.filter((student) =>
        filters.advisors.includes(student.advisor)
      );
    }

    if (filters.performance && filters.performance.length > 0) {
      filtered = filtered.filter((student) => {
        // Convert student's GPA string to a number for comparison
        const gpa = parseFloat(student.GPA);
    
        // Check if the student's GPA matches any of the selected performance ranges
        return filters.performance.some((range) => {
          switch (range) {
            case "> 4.0":
              return gpa > 4.0;
            case "3.5 - 4.0":
              return gpa >= 3.5 && gpa <= 4.0;
            case "3.0 - 3.5":
              return gpa >= 3.0 && gpa < 3.5;
            case "2.5 - 3.0":
              return gpa >= 2.5 && gpa < 3.0;
            case "2.0 - 2.5":
              return gpa >= 2.0 && gpa < 2.5;
            case "< 2.0":
              return gpa < 2.0;
            default:
              return false;
          }
        });
      });
    }


    // Apply custom filter
    if (
      filters.customFilter &&
      filters.customFilter.field &&
      filters.customFilter.operator &&
      filters.customFilter.value
    ) {

      const { field, operator, value } = filters.customFilter;
      const fieldType = fieldTypes[field];
      console.log('FTType: ', fieldType);

      filtered = filtered.filter((student) => {
        const studentValue = student[field];
        if (studentValue === undefined || studentValue === null) {
          return false;
        }

        if (operator === 'equals') {
          if (Array.isArray(studentValue)) {
            // Handle array values
            return studentValue.some(
              (val) =>
                String(val).trim().toLowerCase() === value.trim().toLowerCase()
            );
          } else {
            return (
              String(studentValue).trim().toLowerCase() ===
              value.trim().toLowerCase()
            );
          }
        } else if (operator === 'greater than') {
          if (fieldType === 'number') {
            return parseFloat(studentValue) > parseFloat(value);
          } else if (fieldType.startsWith('array-')) {
            // Handle arrays of numbers
            return studentValue.some(
              (val) => parseFloat(val) > parseFloat(value)
            );
          } else {
            return false; // Not a number, cannot compare
          }
        } else if (operator === 'less than') {
          if (fieldType === 'number') {
            return parseFloat(studentValue) < parseFloat(value);
          } else if (fieldType.startsWith('array-')) {
            // Handle arrays of numbers
            return studentValue.some(
              (val) => parseFloat(val) < parseFloat(value)
            );
          } else {
            return false; // Not a number, cannot compare
          }
        }
        return true; // If operator is unknown, include the student
      });
    }

    // Apply sorting
    if (sortOrder) {
      filtered.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
        if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredStudents(filtered);
  }, [students, searchTerm, filters, sortOrder, fieldTypes]);

  const everythingSelected = filteredStudents.every(student => selectedStudents.includes(student.id));

  // Get selected students data
  const selectedStudentsData = useMemo(() => {
    return students.filter((student) => selectedStudents.includes(student.id));
  }, [students, selectedStudents]);

  // Handle Campaign button click
  const handleCampaignClick = () => {
    if (selectedStudentsData.length === 0) {
      toast.warning('Please select at least one student to send a campaign.');
      return;
    }
    setIsCampaignModalOpen(true);
  };

  return (
    <div className="flex w-full">
      {/* Filters Sidebar */}
      {adjustment !== 'Resources' && (
        <div className="w-1/3">
          <SearchComponent
            students={students}
            fieldTypes={fieldTypes}
            onSearch={handleSearch}
            onFilter={handleFilter}
            selectedStudents={selectedStudents}
            onSelectAll={handleSelectAll}
            filteredStudentsCount={filteredStudents.length}
            everythingSelected={everythingSelected}
            advisors={advisors}
            dashboardFilterType={filterType}
            dashboardFilterValue={filterValue}
          />
        </div>
      )}
  
      {/* Students Display */}
      <div
        className={`w-full ml-3`}
      >
        <div
          className={`p-4 ${
            adjustment === 'Dashboard' ? 'bg-gray-50' : 'bg-gray-50'
          } shadow-md border rounded-lg h-auto min-h-[60vh] md:min-h-[70vh] lg:min-h-[80vh]`}
        >
          {/* Header with Centered Title */}
          <div className="items-center justify-center mb-4 relative">
            {adjustment === 'Resources' ? (
              <>
                <h5 className="text-lg font-bold text-center">Select a Student</h5>
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  onClick={onClose}
                >
                  <FaTimes size={20} />
                </button>
              </>
            ) : (
              <>
                <h5 className="text-lg font-bold text-center">Students</h5>
                <button
                  className={`absolute top-0 right-2 ${
                    selectedStudents.length === 0
                      ? 'bg-blue-300'
                      : 'bg-blue-500 hover:bg-blue-700'
                  } text-white text-sm py-2 px-3 rounded`}
                  onClick={handleCampaignClick}
                >
                  Send Campaign
                </button>
                <button
                  onClick={toggleTemplateModalVisibility}
                  className={`absolute top-0 right-40 px-3 py-2 rounded text-sm flex items-center space-x-2 justify-center ${
                    selectedStudents.length === 0
                      ? 'bg-blue-300'
                      : 'bg-blue-500 hover:bg-blue-700'
                  } text-white`}
                >
                  <FaPlus size={14} className="text-sm" />
                  <span className="text-sm">Templates</span>
                </button>
              </>
            )}
          </div>
  
          {/* Conditionally Render Headers Based on 'adjustment' */}
          <div className="grid grid-cols-7 font-semibold text-sm text-gray-500 mb-2">
            {adjustment !== 'Resources' && (
              <div className="col-span-1 ml-3 text-left">
                Select{' '}
                <FontAwesomeIcon
                  icon={faSort}
                  className="mb-0.5 ml-0.5"
                  style={{ fontSize: '10px' }}
                />
              </div>
            )}
  
            {/* Checkbox Header
            {adjustment !== 'Resources' && (
              <div className="text-center col-span-1"></div>
            )} */}
  
            {/* Name */}
            <div
              className={`${
                adjustment !== 'Resources' ? 'col-span-1' : 'col-span-4'
              } text-center mr-10`}
            >
              Name{' '}
              <FontAwesomeIcon
                icon={faSort}
                className="mb-0.5 ml-0.5"
                style={{ fontSize: '10px' }}
              />
            </div>
  
            {/* Major/Program */}
            <div className="text-right mr-20 col-span-2">
              Major/Program{' '}
              <FontAwesomeIcon
                icon={faSort}
                className="mb-0.5 ml-0.5"
                style={{ fontSize: '10px' }}
              />
            </div>
  
            {/* Graduation Year */}
            <div className="text-left ml-5 col-span-1">
              Grad Year{' '}
              <FontAwesomeIcon
                icon={faSort}
                className="mb-0.5 ml-0.5"
                style={{ fontSize: '10px' }}
              />
            </div>

            {/* Last Login */}
            <div className="text-left col-span-1 ml-2">
              Last Login{' '}
              <FontAwesomeIcon
                icon={faSort}
                className="mb-0.5 ml-0.5"
                style={{ fontSize: '10px' }}
              />
            </div>
  
            {/* Action */}
            <div className="text-center col-span-1 mr-5">
              Action{' '}
              <FontAwesomeIcon
                icon={faSort}
                className="mb-0.5 ml-0.5"
                style={{ fontSize: '10px' }}
              />
            </div>
          </div>
  
          {/* Students List */}
          <div
            className="overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 300px)' }}
          >
            {filteredStudents.map((student) => (
              <StudentItem
                key={student.id}
                student={student}
                isSelected={selectedStudents.includes(student.id)}
                onCheckboxChange={handleCheckboxChange}
                adjustment={adjustment}
                selectedResource={selectedResource}
                handleRecommendResource={handleRecommendResource}
              />
            ))}
          </div>
        </div>
      </div>
      {isCampaignModalOpen && (
        <CampaignModal
          onClose={() => setIsCampaignModalOpen(false)}
          selectedStudents={selectedStudentsData}
        />
      )}
      <TemplateModal
        isTemplateModalVisible={isTemplateModalVisible}
        setIsTemplateModalVisible={setIsTemplateModalVisible}
        selectedStudents={selectedStudents}
      />
    </div>
  );
};

export default StudentListing;