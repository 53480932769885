// InterestsList.js
import React from 'react';

const InterestsList = ({ authUser }) => {
    return (
        <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
            <h2 className="text-gray-700 font-semibold mb-2">Traits, Skills, and Interests</h2>
            <div className="overflow-y-auto max-h-[17rem]">
                <div className="flex flex-wrap">
                    {/* Personality Traits Section */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Personality Traits</h3>
                    {Array.isArray(authUser?.surveyAnswers?.RecPersonality) && authUser.surveyAnswers.RecPersonality.length > 0 ? (
                        authUser.surveyAnswers.RecPersonality.map((trait, index) => (
                            <span key={index} className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400 mb-2">{trait}</span>
                        ))
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Personality Traits</span>
                    )}

                    {/* Skills Section */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Skills</h3>
                    {Array.isArray(authUser?.surveyAnswers?.RecSkills) && authUser.surveyAnswers.RecSkills.length > 0 ? (
                        authUser.surveyAnswers.RecSkills.map((skill, index) => (
                            <span key={index} className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400 mb-2">{skill}</span>
                        ))
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Skills</span>
                    )}

                    {/* Interests Section */}
                    <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Interests</h3>
                    {Array.isArray(authUser?.surveyAnswers?.RecInterests) && authUser.surveyAnswers.RecInterests.length > 0 ? (
                        authUser.surveyAnswers.RecInterests.map((interest, index) => (
                            <span key={index} className="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2">{interest}</span>
                        ))
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Interests</span>
                    )}

                </div>
            </div>
        </div>
    );
};

export default InterestsList;
